import { UserOutlined } from "@ant-design/icons";
import { Dropdown, Layout, Menu } from 'antd';
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { logout } from '../redux/actions/auth';

const { Header, Content } = Layout;




const App = (props) => {
	const history = useHistory();
	const [current, setCurrent] = useState('mail')
	const [menuList, setMenuList] = useState([]);
	const [currentBranch, setCurrentBranch] = useState(null);

	const logoutUser = () => {
		props.dispatch(logout());
	}

	useEffect(() => {
		if (props.token != null && props.token != undefined && props.token != '') {
			var decoded = jwt_decode(props.token);
			decoded?.branches?.map(item => {
				if (item.id == decoded.branch) {
					setCurrentBranch(item);
				}
			});
		}

	}, [props.token]);

	useEffect(() => {
		let data = [];
		let reports =
		{
			title: "Reports", children: [
				{
					title: "Boards", path: "/reports", key: 'Reports-Board', children: [
						{ title: "Boards", path: "/reports", key: 'Boards-Report' },
						{ title: "Item Timeline", path: "/item/timeline", key: 'Item Timeline' },
						{ title: "Task Timeline", path: "/timeline", key: 'Task Timeline' },
						{ title: "Cadence report", path: "/item/cadence/report", key: 'Cadence report' },
						{ title: "Archived List Report", path: "/archived/list/report", key: 'Archived List Report' }
					]
				},
				{ title: "Jobs", path: "/reports/jobs", key: 'Reports-Jobs' }
			]
		};

		if (props.user?.permissions) {
			if (props.user.is_superuser) {
				data.push({ title: "SPC", path: "/spc", key: 'SPC' });
				data.push({ title: "Jobs", path: "/jobs", key: 'Jobs' });
				data.push({ title: "Forms", path: "/forms", key: 'Forms' });
				data.push({ title: "Boards", path: "/boards", key: 'Boards_v1' });
				// data.push({ title: "Boards", path: "/boards-v2", key: 'Boards V2' });
				// data.push({ title: "Reports", path: "/reports", key: 'Reports' });
				data.push(reports);
				// data.push({ title: "Item Timeline", path: "/item/timeline", key: 'Item Timeline' });
				// data.push({ title: "Task Timeline", path: "/timeline", key: 'Task Timeline' });
				// data.push({ title: "Cadence report", path: "/item/cadence/report", key: 'Cadence report' });
				// data.push({ title: "Archived List Report", path: "/archived/list/report", key: 'Archived List Report' });
				// data.push({ title: "Activity Report", path: "/item/activity/summary", key: 'Activity Report' });
			}
			else {
				if (props.user?.permissions.can_access_spc) {
					data.push({ title: "SPC", path: "/spc", key: 'SPC' });
				}
				if (props.user?.permissions.can_access_jobs) {
					data.push({ title: "Jobs", path: "/jobs", key: 'Jobs' });
				}
				if (props.user?.permissions?.can_access_forms) {
					data.push({ title: "Forms", path: "/forms", key: 'Forms' });
				}
				if (props.user?.permissions.can_access_boards) {
					data.push({ title: "Boards", path: "/boards", key: 'Boards_v1' });
					// data.push({ title: "Boards", path: "/boards-v2", key: 'Boards V2' });
					// data.push({ title: "Reports", path: "/reports", key: 'Reports' });
					data.push(reports);
					// data.push({ title: "Item Timeline", path: "/item/timeline", key: 'Item Timeline' });
					// data.push({ title: "Task Timeline", path: "/timeline", key: 'Task Timeline' });
					// data.push({ title: "Cadence report", path: "/item/cadence/report", key: 'Cadence report' });
					// data.push({ title: "Archived List Report", path: "/archived/list/report", key: 'Archived List Report' });
				}
			}
		}
		setMenuList(data);
	}, [props.user]);

	const handleClick = (e) => {
		if (e.key === 'user_profile_more')
			return;
		setCurrent(e.key);
		history.push(e.key);
	};

	return (
		<>
			{props.token !== null ?
				<Layout style={{ height: 'calc(100% - 10px)' }}>
					<Header style={{ position: 'fixed', zIndex: 1, width: '100%', height: 50, paddingLeft: 0, paddingRight: 0 }} className="header-normal">
						<div className="logo" style={{ float: 'left', backgroundColor: '#fff', marginRight: 50 }}>
							<img src={props?.user?.company?.company_logo} style={{ height: 30 }} />
						</div>
						<Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" theme="dark" style={{ lineHeight: '50px' }}>
							{
								menuList.map((menu, index) => {

									return <>
										{
											menu?.children != null && menu?.children != undefined ? (
												<Menu.SubMenu key={menu.path} title={menu.title}>
													{
														menu?.children?.map((child) => {
															return child.children == null ? (
																<Menu.Item key={child.path} >
																	{child.title}
																</Menu.Item>
															) : (
																<Menu.SubMenu key={menu.path} title={child.title}>
																	{
																		child.children.map((child1) => {
																			return <Menu.Item key={child1.path} >
																				{child1.title}
																			</Menu.Item>
																		})
																	}
																</Menu.SubMenu>


															)
														})
													}
												</Menu.SubMenu>

											) : (

												<Menu.Item key={menu.path}>
													{menu.title}
												</Menu.Item>
											)
										}
									</>

								})
							}
							<Menu.Item style={{ position: 'absolute', top: 0, right: 0 }} key={'user_profile_more'}>
								<Dropdown overlay={
									<>
										<Menu theme="dark">
											<Menu.Item onClick={() => {
												history.push('/change-branch');
											}}>Change Branch</Menu.Item>
											<Menu.Item onClick={() => {
												history.push('/change-password');
											}}>Change Password</Menu.Item>
											<Menu.Item onClick={logoutUser}>Logout</Menu.Item>
										</Menu>
									</>


								}>
									<span style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
										<strong>
											<span>{currentBranch?.name}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<UserOutlined />&nbsp;{props.user.first_name} {props.user.last_name}
										</strong>


									</span>
								</Dropdown>
							</Menu.Item>
						</Menu>

					</Header>
					<Content className="site-layout">
						{props.children}
					</Content>
				</Layout>
				:
				<Redirect to="/" />
			}
		</>
	);
};

function mapStateToProps(state) {
	const { token, user } = state.auth;
	return {
		token, user
	};
}

export default connect(mapStateToProps)(withRouter(App));

