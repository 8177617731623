import React, { useEffect, useState } from "react";
import { Form, Button, Select, DatePicker, Drawer, TreeSelect, Cascader } from 'antd';
import { connect } from 'react-redux';
import { FilterOutlined } from '@ant-design/icons';
import BoardService from '../../redux/services/boards';
import { FILTERS_APPLIED } from '../../redux/types';


const { Option } = Select;
const { RangePicker } = DatePicker;

const ReportFilter = (props) => {
    const [visible, setVisible] = useState(false);
    const [boards, setBoards] = useState([]);
    const [lists, setLists] = useState([]);
    const [items, setItems] = useState([]);
    const [checklists, setChecklists] = useState([]);
    const [cascadeOptions, setCascadeOptions] = useState([]);
    const [cascaderValues, setCascaderValues] = useState([]);

    useEffect(() => {
        const options = [];
        if (props.filters_applied?.cascader_options == null || props.filters_applied?.cascader_options == undefined) {
            BoardService.getFilters({ 'type': 'board' }).then(res => {
                res.map(board => {
                    options.push({ 'value': board.id, 'label': board.title, 'isLeaf': false, 'type': 'board' });
                });
                setCascadeOptions(options);
            });
        }
        else {
            setCascadeOptions(props.filters_applied?.cascader_options);
        }
    }, []);

    useEffect(() => {
        form.setFieldsValue({ cascaders: props.filters_applied?.cascader_values });
        form.setFieldsValue({ members: props.filters_applied?.members });
        form.setFieldsValue({ responsible_person: props.filters_applied?.responsible_person });
        form.setFieldsValue({ flags: props.filters_applied?.flags });
        form.setFieldsValue({ board_owners: props.filters_applied?.board_owners });
        form.setFieldsValue({ priorities: props.filters_applied?.priorities });
        form.setFieldsValue({ co_owners: props.filters_applied?.co_owners });
        form.setFieldsValue({ dates: props.filters_applied?.dates });
    }, []);

    const [form] = Form.useForm();

    const clearFilter = () => {
        form.resetFields();
        props.dispatch({
            type: FILTERS_APPLIED,
            payload: null
        });
        props.onFilterChange(null);
        setVisible(!visible);
    }
    const onApplyFilter = (values) => {

        setVisible(false);
        let filter_data = {};

        if (values.dates != null && values.dates != undefined) {
            filter_data["from_date"] = values.dates[0].format("YYYY-MM-DD");
            filter_data["to_date"] = values.dates[1].format("YYYY-MM-DD");
        }
        filter_data["boards"] = boards;
        filter_data["lists"] = lists;
        filter_data["items"] = items;
        filter_data["checklists"] = checklists;
        filter_data["flags"] = values.flags;
        filter_data["members"] = values.members;
        filter_data["responsible_person"] = values.responsible_person;

        if (props?.priorities)
            filter_data["priorities"] = values.priorities;

        filter_data["board_owners"] = values.board_owners;
        filter_data["co_owners"] = values.co_owners;
        filter_data["dates"] = values.dates;
        filter_data["cascader_options"] = cascadeOptions;
        filter_data["cascader_values"] = cascaderValues;

        props.dispatch({
            type: FILTERS_APPLIED,
            payload: filter_data
        });
        //     if (payload?.filters["cascader_options"])
        //     delete payload.filters["cascader_options"];
        //   if (payload?.filters["cascader_values"])
        //     delete payload.filters["cascader_values"];

        setTimeout(() => {
            delete filter_data["cascader_options"];
            delete filter_data["cascader_values"];
            delete filter_data["dates"];

            props.onFilterChange(filter_data);
        }, 500);
    }

    const loadData = (selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];

        if (targetOption?.children != null && targetOption?.children != undefined && targetOption?.children?.length > 0) {
            return;
        }

        targetOption.loading = true;


        let payload = {};

        if (targetOption.type == 'board') {
            payload['type'] = 'list';
            payload['board'] = targetOption.value;
        }
        else if (targetOption.type == 'list') {
            payload['type'] = 'item';
            payload['list'] = targetOption.value;
        }
        else if (targetOption.type == 'item') {
            payload['type'] = 'checklist';
            payload['item'] = targetOption.value;
        }

        BoardService.getFilters(payload).then(res => {
            const children = [];
            res.map(data => {
                if (targetOption.type == 'board') {
                    children.push({ 'value': data.id, 'label': data.title, 'isLeaf': false, 'type': 'list' });
                }
                else if (targetOption.type == 'list') {
                    children.push({ 'value': data.id, 'label': data.title, 'isLeaf': false, 'type': 'item' });
                }
                else if (targetOption.type == 'item') {
                    children.push({ 'value': data.id, 'label': data.title, 'isLeaf': true, 'type': 'checklist' });
                }
            });
            targetOption.children = children;
            setCascadeOptions([...cascadeOptions]);
        });

        // setTimeout(() => {

        // }, 1000);
    }

    const onOptionChange = (value, selectedOptions) => {

        let newBoards = [];
        let newLists = [];
        let newItems = [];
        let newChecklists = [];
        selectedOptions?.map(options => {
            options?.map(v => {
                if (v.type == 'item') {
                    newItems.push(v.value);
                }
                else if (v.type == 'list') {
                    newLists.push(v.value);
                }
                else if (v.type == 'board') {
                    newBoards.push(v.value);
                }
                else if (v.type == 'checklist') {
                    newChecklists.push(v.value);
                }
            });
        });
        setBoards(newBoards);
        setLists(newLists);
        setItems(newItems);
        setChecklists(newChecklists);
        setCascaderValues(value);
    }

    const content = (
        <Form form={form} layout="vertical" autoComplete="off" onFinish={onApplyFilter}>
            <Form.Item label="Boards" name="cascaders">
                <Cascader options={cascadeOptions} placeholder="Please select" loadData={loadData} onChange={onOptionChange} changeOnSelect multiple allowClear={true} notFoundContent={"Data not available"} />
            </Form.Item>

            <Form.Item label="Members" name="members">
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                >
                    {
                        props?.participients && props?.participients.map(member => (
                            <Option key={`member_${member.id}`} value={member.id} >{`${member.first_name} ${member.last_name}`}</Option>
                        ))
                    }
                </Select>
            </Form.Item>

            <Form.Item label="Responsible Person" name="responsible_person">
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                >
                    {
                        props?.responsible_persons && props?.responsible_persons.map(member => (
                            <Option key={`owner_${member.id}`} value={member.id}>{`${member.first_name} ${member.last_name}`}</Option>
                        ))
                    }
                </Select>
            </Form.Item>
            {
                props?.showPriorities == true && <Form.Item label="Priorities" name="priorities">
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                    >
                        {
                            props.priorities.map(priority => (
                                <Option key={`priority_${priority.id}`} value={priority.id}>{priority.title}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            }
            {
                props.showFlags == true && <Form.Item label="Flags" name="flags">
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                    >
                        {
                            props?.flags.map(flag => (
                                <Option key={`flag_${flag.id}`} value={flag.id}>{flag.title}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            }

            <Form.Item label="Board Owners" name="board_owners">
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                >
                    {
                        props?.board_owners && props?.board_owners.map(co_owner => (
                            <Option key={`board_owner_${co_owner.id}`} value={co_owner.id}>{`${co_owner.first_name} ${co_owner.last_name}`}</Option>
                        ))
                    }
                </Select>
            </Form.Item>

            <Form.Item label="Co-Owners" name="co_owners">
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                >
                    {
                        props?.board_co_owners && props?.board_co_owners.map(co_owner => (
                            <Option key={`board_co_owner_${co_owner.id}`} value={co_owner.id}>{`${co_owner.first_name} ${co_owner.last_name}`}</Option>
                        ))
                    }
                </Select>
            </Form.Item>

            <Form.Item label="Dates" name="dates">
                <RangePicker style={{ width: '100%' }} />
            </Form.Item>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type="default" onClick={clearFilter}>
                    Clear Filter
                </Button>
                <Button type="primary" htmlType="submit">
                    Apply Filter
                </Button>
            </div>
        </Form>
    );


    const showDrawer = () => {
        setVisible(!visible);
    };

    return (
        <div>
            <Button type="primary" icon={<FilterOutlined />} onClick={showDrawer}>
                Filter
            </Button>

            <Drawer
                title="Filters"
                bodyStyle={{ paddingTop: 5 }}
                placement={'right'}
                closable={false}
                onClose={showDrawer}
                visible={visible}>
                {
                    content
                }
            </Drawer>

        </div>
    );
};

function mapStateToProps(state) {
    const { flags, priorities } = state.boards;
    const { filters_applied, participients, responsible_persons, board_owners, board_co_owners } = state.reports;
    return {
        participients,
        responsible_persons,
        flags,
        priorities,
        board_owners,
        board_co_owners,
        filters_applied
    }
}

export default connect(mapStateToProps)(ReportFilter);
