
import { useEffect, useState } from "react";
import AddCommand from "../AddCommand";

const commandColor = '#a29bfe';

// Has params: props.id, props.comps, props.setComps, props.indices, props.setIsScriptRendered, props.childrenArray
export default function SetFlagToChildCommand (props) {

   const [ evaluatedErrors, setEvaluatedErrors ] = useState(false);
   const [ color, setColor ] = useState(commandColor);

   const [ isAddingCommand, setIsAddingCommand ] = useState(false);

   let flag = "";
   let value = "";
   let comment = -1;

   let thisObj = props.comps[props.id].script[props.indices[0]];
   for (let k = 0; k < props.indices.length - 1; ++k) {
       thisObj = thisObj.children[props.indices[k + 1]];
   }
   
   try {
       flag = thisObj.flag;
       value = thisObj.value;
       comment = thisObj.comment;
   } catch (e) {

   }

   let flags_to_options = [<option value="">None</option>];
   for (let k = 0; k < props.externals.flags.length; ++k) {
       flags_to_options.push(<option value={props.externals.flags[k].name}>
           {props.externals.flags[k].name}
       </option>);
   }

   if (!evaluatedErrors) {
    if (flag !== "") {
        setColor(commandColor);
        setEvaluatedErrors(true);
    } else {
        setColor('#FC427B');
        setEvaluatedErrors(true);
    }
}

useEffect(() => {
    if (flag !== "") {
    setColor(commandColor);
    setEvaluatedErrors(true);
    } else {
    setColor('#FC427B');
    setEvaluatedErrors(true);
    }
});

   return <>
       <div style={{
           marginBottom: '2vmax',
           overflow: 'auto',
           display: 'inline-block',
           marginRight: '4vmax',
           verticalAlign: 'top'
       }}>
           <div style={{
               padding: '1vmax',
               backgroundColor: color,
               color: '#000',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontFamily: "Work Sans, Lato, sans-serif",
               fontWeight: '500'
           }}>
               SET FLAG
           </div>
           <a className='panel-button' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontFamily: "Work Sans, Lato, sans-serif",
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax'
           }} onClick={(e) => {
               e.preventDefault();
               let new_comps = [...props.comps];
               let thisObj = new_comps[props.id].script[props.indices[0]];
               for (let k = 0; k < props.indices.length - 2; ++k) {
                   thisObj = thisObj.children[props.indices[k + 1]];
               } thisObj.children.splice(props.indices[props.indices.length - 1], 1);
               props.setComps(new_comps);
               props.setIsScriptRendered(false);
           }}>
               Delete
           </a>
           <i className='fa-solid fa-comment-dots pointy comp' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax',
               backgroundColor: comment === -1 ? "#000" : "#faf3dd",
               color: comment === -1 ? "#faf3dd" : "#000"
           }} onClick={(e) => {
               e.preventDefault();
               let new_comps = [...props.comps];
               let thisObj = new_comps[props.id].script[props.indices[0]];
               for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
               if (thisObj.comment !== -1) {
                    thisObj.comment = -1;
               } else {
                    thisObj.comment = "Comments";
               }
               props.setComps(new_comps);
               props.setIsScriptRendered(false);
           }}></i>
           {/* Copy Command Icon */}
           <i className='fa-solid fa-copy pointy comp panel-button' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax'
           }} onClick={(e) => {
               e.preventDefault();
               let thisObj = props.comps[props.id].script[props.indices[0]];
               for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
               navigator.clipboard.writeText(JSON.stringify(thisObj));
           }}></i>
           <div style={{
               padding: '2vmax 0',
               borderLeft: `3pt solid ${color}`,
               borderBottom: `1pt dashed ${color}`,
               paddingLeft: '2vmax',
               fontFamily: "Work Sans, Lato, sans-serif",
               fontWeight: '500',
               paddingRight: '2vmax'
           }} className='code-block'>
               <div style={{
                display: 'inline-block',
                fontSize: '1.15vmax',
                marginBottom: props.childrenArray[0].length === 0 ? '0' : '1.5vmax'
               }}>SET <select className='panel-dropdown' onChange={(e) => {
                e.preventDefault();
                let new_comps = [...props.comps];
                let thisObj = new_comps[props.id].script[props.indices[0]];
                for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                } thisObj.flag = e.target.value;
                props.setComps(new_comps);
                props.setIsScriptRendered(false);
            }} value={flag} style={{
                marginLeft: '0.5vmax',
                marginRight: '0.5vmax',
                minWidth: '5vmax'
            }}>
                { flags_to_options }
            </select> TO</div><br />
                {props.childrenArray[0].length === 0 ? <i className='fa-solid fa-plus panel-icon' style={{
                    fontSize: '1vmax',
                    padding: '0.5vmax',
                    borderRadius: '100px',
                    marginTop: '1.5vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setIsAddingCommand(true);
                }} /> : props.childrenArray[0]}
                {
                    comment === -1 ? <></> : <>
                        <br />
                        <input className='panel-input-reverse' style={{
                            width: '100%',
                            height: '2vmax',
                            marginTop: '1vmax'
                        }} value={comment} onChange={(e) => {
                            let new_comps = [...props.comps];
                            let thisObj = new_comps[props.id].script[props.indices[0]];
                            for (let k = 0; k < props.indices.length - 1; ++k) {
                                thisObj = thisObj.children[props.indices[k + 1]];
                            }
                            thisObj.comment = e.target.value;
                            props.setComps(new_comps);
                            props.setIsScriptRendered(false);
                        }}></input>
                    </>
                }
           </div>
       </div>
       {
            isAddingCommand === true ? <AddCommand id={props.id} comps={props.comps}
                                            setComps={props.setComps} indices={props.indices}
                                            setIsScriptRendered={props.setIsScriptRendered}
                                            setIsAddingCommand={setIsAddingCommand}
                                            availableCommands={[
                                                "variable/get",
                                                "expression/number",
                                                "expression/string",
                                                "expression/boolean",
                                                "expression/color",
                                                "operator/logical/and",
                                                "operator/logical/or",
                                                "operator/logical/not",
                                                "operator/ordinal/g",
                                                "operator/ordinal/geq",
                                                "operator/ordinal/l",
                                                "operator/ordinal/leq",
                                                "operator/ordinal/eq",
                                                "operator/type/isNum",
                                                "operator/type/isStr",
                                                "operator/type/isEmail",
                                                "operator/type/isDate",
                                                "operator/type/isBlank",
                                                "operator/array/includes",
                                                "operator/array/isEmpty",
                                                "operator/quant/add",
                                                "operator/quant/subtract",
                                                "operator/quant/multiply",
                                                "operator/quant/divide",
                                                "operator/quant/modulus",
                                                "operator/quant/floor",
                                                "operator/quant/ceiling",
                                                "operator/quant/round",
                                                'operator/quant/abs',
                                                "operator/qual/digits",
                                                "operator/quant/max",
                                                "operator/quant/min",
                                                "operator/qual/words",
                                                "operator/qual/day",
                                                "operator/qual/month",
                                                "operator/qual/year",
                                                "quant/roundiv",
                                                "quant/between",
                                                "operator/type/isTime",
                                                "operator/type/isUndecorated",
                                                "self/value",
                                                "flags/get"
                                            ]} /> : <></>
        }
   </>;

}