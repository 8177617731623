
import { Rnd } from 'react-rnd';

import process_vars from '../../intermediate/process_vars';

const boxShadowRadius = 15;

export default function List({ id, comps, setter, vw, vars, setVars, editable, externals, setExternals }) {

    let boxShadow = "0px 0px 0px #ff4f42";

    if (comps[id].error) {
        boxShadow = `0px 0px ${boxShadowRadius}px #ff4f42`;
    }
    else if (comps[id].hasOwnProperty('warning')) {
        if (comps[id].warning) {
            boxShadow = `0px 0px ${boxShadowRadius}px #d35400`;
        }
    }
    else if (comps[id].disable) {
        boxShadow = `0px 0px ${boxShadowRadius}px #4e6df5`;
    }

    let innerContent = [];
    for (let k = 0; k < comps[id].props.content.length; ++k) {
        innerContent.push(
            <li style={{
                paddingLeft: '0.5vmax',
                marginTop: '0',
                marginBottom: '0.5vmax'
            }}>{process_vars(comps[id].props.content[k], vars)}</li>
        );
    }

    return (
        <div>
            <Rnd className='comp' style={{
                color: process_vars(comps[id].props.color, vars),
                backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
                overflow: 'hidden',
                fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
                fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
                fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
                textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                    `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                position: 'absolute',
                boxShadow: editable ? boxShadow : 'none',
                transition: 'box-shadow 0.5s'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} enableResizing={false} disableDragging={true}>
                {comps[id].props.style === 'dotted' ?
                    <ul style={{
                        textAlign: process_vars(comps[id].props.alignment, vars)
                    }}>{innerContent}</ul> : <></>}
                {comps[id].props.style === 'numbered' ?
                    <ol style={{
                        textAlign: process_vars(comps[id].props.alignment, vars)
                    }}>{innerContent}</ol> : <></>}
            </Rnd>
        </div>
    );

}