import { Pie } from '@ant-design/plots';
import { Button, Card } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import ReportService from "../../redux/services/reports";

const PieChartReport = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const payload = {
      'filters': props.filters
    };

    ReportService.personalPerformance(payload).then(res => {
      let x = [
        {
          type: 'In-Progress On-Time',
          value: res.data.pending,
        },
        {
          type: 'In-Progress Delayed',
          value: res.data.pending_delayed,
        },
        {
          type: 'Completed On-Time',
          value: res.data.completed,
        },
        {
          type: 'Completed Delayed',
          value: res.data.completed_delayed,
        }
      ];
      setData(x);
    });
  }, [props.filters])

  const exportReport = () => {
    ReportService.personalPerformanceExport().then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'personal_performance_report.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }


  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}\n{percentage}',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  }

  return (
    <Card title={
      <div>
        Personal Performance
        <Button type="primary" onClick={exportReport} size="small" style={{ float: 'right' }}>
          Export as CSV
        </Button>
      </div>
    }>
      <Pie {...config} />
    </Card>
  );
};

function mapStateToProps(state) {
  const { checklist_items } = state.reports;
  return {
    checklist_items
  }
}

export default connect(mapStateToProps)(PieChartReport);
