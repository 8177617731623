
import {
    useState
} from 'react';
import { Rnd } from 'react-rnd';

import '../component.css';
import process_vars from './process_vars';

import ScriptView from '../script/ScriptView';

export default function Snapshot({ id, comps, setter, vw, gridSize, sel, setSel, hov, setHov, vars, setVars, contextMenu, setContextMenu, magneticGrid, setMagneticGrid, magneticGridPartitions }) {

    let background_color = '';
    if (hov === id) {
        background_color = '#FAF3DD';
    } else {
        background_color = process_vars(comps[id].props.backgroundColor, vars);
    } if (sel === id) {
        background_color = process_vars(comps[id].props.backgroundColor, vars);
    }

    let innerContent = <></>;

    return (
        <div>
            <Rnd className='comp' style={{
                backgroundColor: background_color,
                textAlign: 'center',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: 'hidden',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                    `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                transition: 'transform 0.05s ease'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} onDragStop={(e, d) => {
                let new_comps = [...comps];
                
                let dx = 0;
                let dy = 0;
                if (magneticGrid) {
                    dx = Math.round(d.x / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                    dy = Math.round(d.y / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                } else {
                    dx = d.x;
                    dy = d.y;
                }

                new_comps[id].position = [
                    dx / vw,
                    dy / vw
                ];

                setter(new_comps);
            }} onResize={(e, direction, ref, delta, position) => {
                let new_comps = [...comps];

                let px = 0;
                let py = 0;
                if (magneticGrid) {
                    px = Math.round(position.x / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                    py = Math.round(position.y / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                } else {
                    px = position.x;
                    py = position.y;
                }

                let ow = 0;
                let oh = 0;
                if (magneticGrid) {
                    ow = Math.round(ref.offsetWidth / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                    oh = Math.round(ref.offsetHeight / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                } else {
                    ow = ref.offsetWidth;
                    oh = ref.offsetHeight;
                }

                new_comps[id].position = [
                    px / vw,
                    py / vw
                ];
                new_comps[id].size = [
                    ow / vw,
                    oh / vw
                ];
                setter(new_comps);
            }} minWidth={window.innerWidth * 0.05} minHeight={window.innerWidth * 0.025} bounds='.form-view'
                aspectRatio={comps[id].aspectRatio[0] / comps[id].aspectRatio[1]}
                lockAspectRatio={true}
                dragGrid={[
                    gridSize,
                    gridSize
                ]} resizeGrid={[
                    gridSize,
                    gridSize
                ]} onMouseEnter={() => {
                    setHov(id);
                }} onMouseLeave={() => {
                    setHov(-1);
                }} onDoubleClick={() => {
                    setSel(id);
                }} onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setContextMenu([true, id, [e.clientX, e.clientY]]);
                }} onClick={(e) => {
                    e.stopPropagation();
                }}>
                {/* <img src={comps[id].data} draggable="false" style={{
                    width: '100%',
                    height: '100%'
                }} onClick={(e) => {
                    e.preventDefault();
                }}></img> */}
                <p style={{
                    width: '90%',
                    textAlign: 'center',
                    fontWeight: '300',
                    color: process_vars(comps[id].props.color, vars)
                }}>
                    Snapshot Region
                </p>
            </Rnd>
        </div>
    );

}

export function SnapshotProperties({ id, comps, setter, setSel, vars, setVars, externals, setExternals }) {

    const [isShowingScript, setIsShowingScript] = useState(false);

    return (<>
        <br></br>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setIsShowingScript(true);
        }}>Edit Script</a>
        <br /><br />

        <p className='panel-label'>Position: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].position[0] = e.target.value;
            setter(new_comps);
        }} value={comps[id].position[0]} style={{ width: '15%' }} placeholder='X' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].position[1] = e.target.value;
            setter(new_comps);
        }} value={comps[id].position[1]} style={{
            width: '15%',
            marginLeft: '0.5vmax'
        }} placeholder='Y' />
        <br /> <br />

        <p className='panel-label'>Aspect Ratio: </p>
        <input type='number' className='panel-input' onChange={(e) => {
            let new_comps = [...comps];

            // update aspectRatio[0]
            new_comps[id].aspectRatio[0] = e.target.value;

            // update dimensions
            new_comps[id].size[1] = (new_comps[id].aspectRatio[1] / new_comps[id].aspectRatio[0]) * new_comps[id].size[0];

            setter(new_comps);
        }} value={comps[id].aspectRatio[0]} style={{ width: '15%' }} placeholder='Hor' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];

            // update aspectRatio[0]
            new_comps[id].aspectRatio[1] = e.target.value;

            // update dimensions
            new_comps[id].size[1] = (new_comps[id].aspectRatio[1] / new_comps[id].aspectRatio[0]) * new_comps[id].size[0];

            setter(new_comps);
        }} value={comps[id].aspectRatio[1]} style={{
            width: '15%',
            marginLeft: '0.5vmax'
        }} placeholder='Ver' />
        <br /> <br />

        <p className='panel-label'>Dimensions: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].size[0] = e.target.value; // update width
            new_comps[id].size[1] = (new_comps[id].aspectRatio[1] / new_comps[id].aspectRatio[0]) * new_comps[id].size[0];
            setter(new_comps);
        }} value={comps[id].size[0]} style={{ width: '15%' }} placeholder='Width' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].size[1] = e.target.value; // update height
            new_comps[id].size[0] = (new_comps[id].aspectRatio[0] / new_comps[id].aspectRatio[1]) * new_comps[id].size[1];
            setter(new_comps);
        }} value={comps[id].size[1]} style={{
            width: '15%',
            marginLeft: '0.5vmax'
        }} placeholder='Height' />
        <br /> <br />

        <p className='panel-label'>Background Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.backgroundColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.backgroundColor} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.backgroundColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.backgroundColor} />
        <br /> <br />

        <p className='panel-label'>Border Enabled: </p>
        <input type="checkbox" checked={comps[id].props.borderEnabled} onClick={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderEnabled = !new_comps[id].props.borderEnabled;
            setter(new_comps);
        }}></input>
        <br /><br />

        <p className='panel-label'>Border Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.borderColor} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.borderColor} />
        <br /><br />

        <p className='panel-label'>Border Width:</p>
        <input type='range' min='1' max='10' value={comps[id].props.borderWidth} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderWidth = parseInt(e.target.value);
            setter(new_comps);
        }}></input>
        <br /><br />

        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setSel(-1);
        }}>Ok</a>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            let new_comps = [...comps];
            new_comps.splice(id, 1);
            setSel(-1);
            setter(new_comps);
        }} style={{
            marginLeft: '1vmax'
        }}>Delete</a>
        <br /> <br />

        {isShowingScript ? <ScriptView id={id} comps={comps} setComps={setter}
            setIsShowingScript={setIsShowingScript}
            vars={vars} setVars={setVars}  externals={externals} setExternals={setExternals} /> : <></>}
    </>);

}

export function SnapshotAdder({ comps, setComps, setView, page, setSelectedEl }) {

    const [hovered, setHovered] = useState(false);

    return (
        <div className="toolbar-element" style={{
            // border: '1px #FAF3DD solid',
            // display: 'inline-block',
            // padding: '1vmax',
            // color: hovered ? 'black' : '#FAF3DD',
            // backgroundColor: hovered ? '#FAF3DD' : 'black',
            // transition: 'background-color 0.1s, color 0.1s',
            // marginLeft: '1vmax',
            // marginTop: '1vmax',
        }} onMouseEnter={() => {
            setHovered(true);
        }} onMouseLeave={() => {
            setHovered(false);
        }} onClick={(e) => {
            e.preventDefault();
            let new_comps = [...comps];
            new_comps.push({
                page: page,
                type: 'intermediate/Snapshot',
                name: 'Snapshot',
                position: [0.475, 0.15 + document.getElementById("wrapper").scrollTop / document.getElementById("wrapper").offsetWidth],
                size: [0.10, 0.05],
                data: "",
                aspectRatio: [4, 3], // 4/3 aspect ratio initially
                props: {
                    backgroundColor: '#ffffff',
                    borderEnabled: true,
                    borderColor: '#000000',
                    borderWidth: 1
                }, script: [
                    {
                        type: "event/start",
                        children: []
                    },
                    {
                        type: "event/change",
                        children: []
                    },
                    {
                        type: "event/save",
                        children: []
                    }
                ],
                error: "",
                warning: "",
                disable: false
            });
            setComps(new_comps);
            setView('');
            setSelectedEl(comps.length);
        }}>
            <i className="fa-solid fa-camera" style={{
                color: hovered ? 'black' : '#FAF3DD'
            }}></i>
            <p className='panel-label' style={{
                color: hovered ? 'black' : '#FAF3DD',
                marginLeft: '1vmax',
                fontSize: '1.15vmax'
            }}>Snapshot</p>
        </div>
    );

}