import { Column } from '@ant-design/plots';
import { Card } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import ReportService from "../../../redux/services/reports";

const MissingItemSpentByCategory = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const payload = {
            'filters': props.filters
        };
        ReportService.missingItemSpentByCategory(payload).then(res => {
            setData(res);
        });
    }, [props.filters])

    const config = {
        data,
        isGroup: true,
        xField: 'job_setup__category__title',
        yField: 'total'
    };


    return (
        <Card title="Spent on missing devices by category">
            <Column {...config} />
        </Card>
    );
}


function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(MissingItemSpentByCategory);

