import { useState, useEffect, useRef } from "react";

import { Button, Modal, List, Tag, Avatar, Checkbox, Form, Dropdown } from 'antd';
import { DeleteOutlined, ShareAltOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons';
import FormService from '../../../redux/services/forms';
import DropdownButton from "antd/lib/dropdown/dropdown-button";

import { Radio, Select, Space } from 'antd';

function partitionToDivision(partition) {
    switch (partition) {
        case 12.5:
            return '0';
        case 25:
            return '1';
        case 50:
            return '2';
        case 100:
            return '3';
        case 200:
            return '4';
        case 400:
            return '5';
        case 800:
            return '6';
        default:
            return '3';
    }
}

function divisionToPartition(division) {
    switch (division) {
        case '0':
            return 12.5;
        case '1':
            return 25;
        case '2':
            return 50;
        case '3':
            return 100;
        case '4':
            return 200;
        case '5':
            return 400;
        case '6':
            return 800;
        default:
            return 100;
    }
}

export default function SharePage({
    user,
    formId,
    page,
    onFormSave,
    magneticGrid,
    setMagneticGrid,
    magneticGridPartitions,
    setMagneticGridPartitions
}) {

    const [loading, setLoading] = useState(false);

    const [openShareModal, setOpenShareModal] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canSelfEdit, setCanSelfEdit] = useState(false);

    const [canView, setCanView] = useState(false);

    const [members, setMembers] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const formRef = useRef(null);



    const loadMembers = () => {
        if (formId != "" && formId != null && formId != undefined) {
            setLoading(true);
            FormService.getMembers({ formId: formId, pageId: page?.page_id }).then(res => {
                setMembers(res.results);
                setLoading(false);
            }).catch(res => {
                setLoading(false);
                setMembers([]);
            });
        }
    }

    useEffect(() => {
        FormService.getUsers({ formId: formId }).then(res => {
            const _users = res.results;
            // _users.splice(0, 0, { 'id': -1, 'first_name': '--Select--', 'last_name': '' });
            setUsers(_users);
        });
    }, []);

    const [errorMessage, setErrorMessage] = useState('');
    const isNumeric = (str) => {
        str = str + "";
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }
    const onUserAdd = (e) => {
        setErrorMessage('');
        if (canEdit == false && canView == false) {
            setErrorMessage('Please select atleast on permission');
            return;
        }
        if (selectedUser == null || selectedUser == "" || selectedUser == undefined) {
            setErrorMessage('Please select user');
            return;
        }
        if (parseInt(selectedUser) < 0) {
            setErrorMessage('Please select user');
            return;
        }

        if (page?.page_id == null || page?.page_id == undefined || isNumeric(page?.page_id) == false) {
            alert('Please save the page first');
            return;
        }

        setLoading(true);
        const payload = {
            "form": formId,
            "page": page?.page_id,
            "user": selectedUser,
            "can_edit": canEdit,
            "can_self_edit": canSelfEdit,
            "can_view": canView
        };
        FormService.addMember(payload).then(res => {
            formRef.current.resetFields();
            setSelectedUser(null);
            setCanEdit(false);
            setCanSelfEdit(false);
            setCanView(false);
            loadMembers();
            setLoading(false);
        }).catch(res => {
            setLoading(false);
        });
    }

    const deleteMember = (id) => {
        const payload = {
            "id": id,
            "form": formId,
            "page": 0,
            "user": selectedUser,
            "can_edit": canEdit,
            "can_self_edit": canSelfEdit,
            "can_view": canView
        };
        setLoading(true);
        FormService.deleteMember(payload).then(res => {
            loadMembers();
            setLoading(false);
        }).catch(res => {
            setLoading(false);
        });
    }

    return (
        <div style={{
            paddingBottom: '3vmax'
        }}>
            <Button type="primary" style={{
                display: 'inline'
            }} onClick={(e) => {
                // onFormSave();
                loadMembers();
                setOpenShareModal(true);
            }}>Share</Button>
            
            <DropdownButton menu={{items: [
            {
                label: 'Super Coarse',
                key: '0'
            },
            {
                label: 'Very Coarse',
                key: '1',
            },
            {
                label: 'Coarse',
                key: '2'
            },
            {
                label: 'Normal',
                key: '3'
            },
            {
                label: 'Fine',
                key: '4'
            },
            {
                label: 'Very Fine',
                key: '5'
            },
            {
                label: 'Super Fine',
                key: '6'
            }
        ], selectable: true, selectedKeys: [partitionToDivision(magneticGridPartitions)], onClick: (e) => {
            setMagneticGridPartitions(divisionToPartition(e.key));
        }}} type={magneticGrid ? "primary" : "secondary"} style={{
                marginLeft: '1vmax',
                display: 'inline',
            }} onClick={(e) => {
                if (magneticGrid) {
                    setMagneticGrid(false);
                } else {
                    setMagneticGrid(true);
                }
            }}>Magnetic Grid is {magneticGrid ? "On" : "Off"}</DropdownButton>

            {

                <Modal width="60%" title="Share Current Page" destroyOnClose={true} visible={openShareModal} onOk={() => {
                    setOpenShareModal(false);
                }} onCancel={() => {
                    setOpenShareModal(false);
                }} footer={null}>
                    <div>
                        {
                            loading ? (
                                <div className="blockUI">
                                    <div className="loading-icon-container">
                                        <LoadingOutlined />
                                        <br />
                                        <br />
                                        <strong>Loading...</strong>
                                    </div>
                                </div>
                            ) : null
                        }
                        <Form ref={formRef} onFinish={(e) => {
                            e.preventDefault();
                        }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Form.Item name="a">
                                                <Select
                                                    showSearch
                                                    placeholder="Select User"
                                                    allowClear={true}
                                                    onChange={(value) => {
                                                        setSelectedUser(value);
                                                    }}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    options={users.map((item) => ({
                                                        value: item.id,
                                                        label: `${item.first_name} ${item.last_name}`,
                                                    }))}
                                                />
                                            </Form.Item>
                                        </td>
                                        <td style={{ width: 110, textAlign: 'center' }}>
                                            <Form.Item name="b">
                                                <Checkbox checked={canView} disabled={canEdit || canSelfEdit} onChange={(e) => {
                                                    setCanView(e.target.checked);
                                                }}>Can View</Checkbox>
                                            </Form.Item>
                                        </td>
                                        <td style={{ width: 100, textAlign: 'center' }}>
                                            <Form.Item name="c">
                                                <Checkbox disabled={canSelfEdit} onChange={(e) => {
                                                    setCanEdit(e.target.checked);
                                                    if (e.target.checked) {
                                                        setCanView(true);
                                                        canSelfEdit(false);
                                                    }
                                                }}>Can Edit</Checkbox>
                                            </Form.Item>
                                        </td>
                                        <td style={{ width: 100, textAlign: 'center' }}>
                                            <Form.Item name="c">
                                                <Checkbox disabled={canEdit} onChange={(e) => {
                                                    setCanSelfEdit(e.target.checked);
                                                    if (e.target.checked) {
                                                        setCanView(true);
                                                        setCanEdit(false);
                                                    }
                                                }}>Can Self Edit</Checkbox>
                                            </Form.Item>
                                        </td>
                                        <td style={{ width: 80, textAlign: 'center' }}>
                                            <Button onClick={onUserAdd} type="primary">Submit</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <span style={{ color: 'red' }}>{errorMessage}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Form>

                        <List itemLayout="horizontal" dataSource={members} renderItem={item => {
                            return (
                                <List.Item>
                                    <List.Item.Meta avatar={<Avatar children={`${item.user.first_name[0].toUpperCase()}${item.user.last_name[0].toUpperCase()}`} />} title={`${item.user.first_name} ${item.user.last_name}`} description={item.user.username} />
                                    {
                                        item.can_view ? (
                                            <Tag color="#87d068" style={{ textTransform: 'capitalize' }}>Can View</Tag>
                                        ) : (
                                            <Tag color="#f50" style={{ textTransform: 'capitalize' }}>Can't View</Tag>
                                        )
                                    }

                                    {
                                        item.can_edit ? (
                                            <Tag color="#87d068" style={{ textTransform: 'capitalize' }}>Can Edit</Tag>
                                        ) : (
                                            <Tag color="#f50" style={{ textTransform: 'capitalize' }}>Can't Edit</Tag>
                                        )
                                    }

                                    {
                                        item.can_self_edit ? (
                                            <Tag color="#87d068" style={{ textTransform: 'capitalize' }}>Can Self Edit</Tag>
                                        ) : (
                                            <Tag color="#f50" style={{ textTransform: 'capitalize' }}>Can't Self Edit</Tag>
                                        )
                                    }

                                    {/* <Tag color="orange" style={{ textTransform: 'capitalize' }}>{item.can_view ? "Can View" : "Can't View"}</Tag>
                                    <Tag color="orange" style={{ textTransform: 'capitalize' }}>{item.can_edit ? "Can Edit" : "Can't Edit"}</Tag> */}
                                    {
                                        item.user.id != user.id ?
                                            <Button icon={<DeleteOutlined />} onClick={() => {
                                                deleteMember(item.id)
                                            }} danger></Button> : null
                                    }
                                </List.Item>
                            )
                        }}>
                        </List>
                    </div>
                </Modal>

            }
        </div>
    )
}