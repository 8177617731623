import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDMHj_feesMU5Uy3EoaHF6ps8-UkhjqyNw",
    authDomain: "stpc-2156c.firebaseapp.com",
    databaseURL: "https://stpc-2156c-default-rtdb.firebaseio.com",
    projectId: "stpc-2156c",
    storageBucket: "stpc-2156c.appspot.com",
    messagingSenderId: "584980168091",
    appId: "1:584980168091:web:16cab7b455224e90ba353e",
    measurementId: "G-ZFQSDX9FPT"
};
firebase.initializeApp(firebaseConfig);
const database = firebase.database();
export default database;