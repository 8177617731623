import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
    Button,
    Card, CardBody,
    CardText,
    CardTitle,
    Table
} from 'reactstrap';
import { AllSPC } from "../../redux/actions/spc";
import { appUrl } from '../../redux/services/base';


let timer = null;

const SPCList = (props) => {
    const history = useHistory();
    useEffect(() => {
        props.dispatch(AllSPC("", null))
    }, [])

    const previous = () => {
        props.dispatch(AllSPC(props?.spc_list.previous, null));
    };

    const next = () => {
        props.dispatch(AllSPC(props?.spc_list.next, null));
    };

    const search = (event) => {
        if (timer != null) {
            window.clearTimeout(timer);
        }
        timer = window.setTimeout(function () {
            let search = `${event.target.value}`;
            if (search != "") {
                props.dispatch(AllSPC("", search));
            }
            else {
                props.dispatch(AllSPC("", "clear"));
            }
        }, 500);
    };

    const changeRoute = (e, id) => {
        e.preventDefault();
        history.push(`/graph/spc/${id}`)
    }

    return (
        <div>
            <Card>
                <CardBody>
                    <CardTitle tag={"div"}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h4>SPC List</h4>
                            <input type="text" placeholder="Search..." onKeyUp={search} />
                        </div>
                    </CardTitle>
                    <CardText tag={"div"}>
                        <Table responsive striped hover className="tbl-small">
                            <thead>
                                <tr>
                                    <th>Cell No.</th>
                                    <th>Machine No.</th>
                                    <th>Part No.</th>
                                    <th>Operation No.</th>
                                    <th>Operation Name</th>
                                    <th>Characteristic</th>
                                    <th>Last Record Added At</th>
                                    <th style={{ minWidth: 120 }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props?.spc_list?.results?.map((spc, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{spc.cell_number}</td>
                                                <td>{spc.machine}</td>
                                                <td>{spc.part_number}</td>
                                                <td>{spc.operation_number}</td>
                                                <td>{spc.operation_name}</td>
                                                <td>{spc.characteristic}</td>
                                                <td>{spc.last_record_added_at && (new Date(spc.last_record_added_at).toLocaleString([], { hour12: true }))}</td>
                                                <td>
                                                    <a href="#" onClick={(e) => changeRoute(e, spc.id)} >View Graph</a>
                                                    <br />
                                                    <a href={`${appUrl}/api/v1/spc/${spc.id}/export/data?token=${props.token}`} target="_blank">Export</a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </CardText>
                    <div className="text-right">
                        <Button disabled={props?.spc_list.previous == null} onClick={previous}>Prev</Button>
                        &nbsp;&nbsp;
                        <Button disabled={props?.spc_list.next == null} onClick={next}>Next</Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

function mapStateToProps(state) {
    const { spc_list } = state.spcList;
    const { auth } = state;
    const { token } = auth;
    return {
        spc_list,
        token
    }
}

export default connect(mapStateToProps)(SPCList);