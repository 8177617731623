import { Column } from '@ant-design/plots';
import { Card } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import ReportService from "../../../redux/services/reports";

const CompletetionAdherence = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const payload = {
            'filters': props.filters
        };
        ReportService.completetionAdherence(payload).then(res => {
            console.log('res.data > ', res.stats);

            res.stats.map(item => {
                item["count"] = parseInt((item["count"] / res.total) * 100)
                if (item["on_time"]) {
                    item["on_time"] = "On Time"
                }
                else {
                    item["on_time"] = "Delayed"
                }
            })
            setData(res.stats);
        });
    }, [props.filters])

    const config = {
        data,
        isGroup: true,
        xField: 'job_status',
        yField: 'count',
        seriesField: 'on_time',
        // color: ["#1B5E20", "#FF9800"],
        // colorField: 'color',


        label: {
            position: 'middle',
            visible: true,
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
    };

    return (
        <Card title="Completetion Adherence">
            <Column {...config} />
        </Card>
    );
}


function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(CompletetionAdherence);

