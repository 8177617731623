import { Card } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import ReportService from "../../../redux/services/reports";

const PersonalPerformanceReport = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const payload = {
            'filters': props.filters
        };
        ReportService.personalPerformance(payload).then(res => {
            setData(res);
        });
    }, [props.filters])




    return (
        <Card title="Personal Performance ">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Name of the person who did the Job</th>
                        <th>Total hours spent</th>
                        <th>Number of Jobs on time</th>
                        <th>Number of Jobs delayed</th>
                        <th>Picked on time</th>
                        <th>Picked delayed</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item) => {
                            return <tr>

                                <td>{item.responsible_person_username}</td>
                                <td>{item.total_hours_spent}</td>
                                {/* <td>{item.total_jobs}</td> */}
                                <td>{item.jobs_on_time}</td>
                                <td>{item.jobs_delayed}</td>
                                <td>{item.picked_on_time}</td>
                                <td>{item.picked_delayed}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </Card>
    );
}


function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(PersonalPerformanceReport);

