import { CheckSquareOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FileAddOutlined, MoreOutlined } from "@ant-design/icons";
import { Col, Collapse, Menu, Modal, Popover, Progress, Row, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { DeleteChecklist, UpdateCheckist } from "../../redux/actions/boards";
import CommonModal from "../common-modal";
import ChecklistItems from "./checklist_items";
import ChecklistCreate from "./create_checklist";
import CreateCheckListItem from './create_checklist_item';
const { confirm } = Modal;
const { Panel } = Collapse;
const key = 'updatable';

const CheckList = (props) => {
    const [checklists, setChecklists] = useState(props?.checklists)
    const [checkListForm, setCheckListForm] = useState(false)
    const [checklistId, setChecklistId] = useState(-1)
    const [hideCompleted, setHideCompleted] = useState(false)
    const [modalForm, setModalForm] = useState(false)
    const [checklistTitle, setChecklistTitle] = useState("")
    const [checklist, setChecklist] = useState();

    useEffect(() => {
        setChecklists(props.checklists)
    }, [props.checklists])

    const showDeleteConfirm = (id, delete_for) => {
        confirm({
            title: `Are you sure delete this ${delete_for}?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                message.loading({ content: 'Loading...', key });
                props.dispatch(DeleteChecklist(id))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const checklistPopover = (checklist) => {
        return (
            <Menu className="item_menu" key={`checklist_context_menu_${checklist.id}`}>
                <Menu.Item
                    icon={<FileAddOutlined />}
                    key="checklist_context_menu_item_add"
                    onClick={(e) => {
                        e.domEvent.stopPropagation();
                        setCheckListForm(true);
                        setChecklist(checklist);
                        setChecklistId(checklist.id);
                    }}>
                    Add Item
                </Menu.Item>
                <Menu.Item
                    icon={<EditOutlined />}
                    key="checklist_context_menu_item_edit"
                    onClick={(e) => {
                        e.domEvent.stopPropagation();
                        setModalForm(true)
                        setChecklistId(checklist.id)
                        setChecklistTitle(checklist.title)
                    }}>
                    Edit CheckList
                </Menu.Item>

                <Menu.Item icon={<DeleteOutlined />} danger
                    key="checklist_context_menu_item_delete"
                    onClick={(e) => {
                        e.domEvent.stopPropagation();
                        showDeleteConfirm(checklist.id, "Checklist")
                    }}>
                    Delete
                </Menu.Item>
            </Menu>
        )
    }

    const handleCheckListEdit = (data) => {
        let update_data = {
            title: data.title
        }
        props.dispatch(UpdateCheckist(checklistId, update_data)).then(
            setModalForm(false)
        )
    }

    const onChange = (checked) => {
        setHideCompleted(checked);
    };

    return (
        <>
            <CreateCheckListItem
                checkListForm={checkListForm}
                toggle={() => setCheckListForm(!checkListForm)}
                checklistId={checklistId}
                checklistItemId={-1}
                board={props.board}
                list={props.list}
                items={checklist?.items}
            />

            <Row>
                <Col span={24}>
                    <div className="item_detail_title">
                        <CheckSquareOutlined />
                        <span style={{ fontWeight: 'bold', fontSize: 16, marginRight: 10, marginLeft: 20 }}>Checklists</span> {"   "}
                        {
                            // Either Item Creator or Board Owner or Board Co-Owner
                            (props?.item?.created_by?.id == props.user.id || props.user.groups.indexOf('Branch Admin') > -1 || props?.board?.is_co_owner || props?.board?.owner == props.user.id) ? <ChecklistCreate /> : null
                        }

                        <span style={{ position: 'absolute', right: 0 }}>
                            <Switch checkedChildren={"Show Completed "} unCheckedChildren={"Hide Completed"} onChange={onChange} defaultChecked={false} />
                        </span>
                    </div>
                </Col>
            </Row>

            {checklists?.map((checklist, index) => {
                let total_completed = checklist.items?.filter(item => item.completed == true)?.length;
                let task_percentage = (total_completed * 100) / checklist.items.length
                let items = checklist?.items;
                if (hideCompleted) {
                    items = checklist.items?.filter(item => item.completed == false);
                }

                return (
                    <div key={`checklist_container_${index}`} style={{ position: 'relative' }} className={"checklist"}>
                        <Row>
                            <Col span={23} offset={1}>
                                <div className={"chk_progress"}>
                                    <span>{task_percentage > 0 ? `${parseInt(task_percentage)}%` : ''} </span> <Progress percent={task_percentage} key={`checklist_progress_${index}`} showInfo={false} />
                                </div>
                                <Collapse expandIconPosition="right" key={`checklist_${index}`}>
                                    <Panel showArrow={true} header={
                                        <div style={{ fontWeight: 'bold', width: "100%" }}>
                                            <CheckSquareOutlined /> {checklist.title}
                                        </div>
                                    }
                                        key={`checklist_${index}`}
                                        extra={
                                            (props.user.id == checklist.created_by || props.user.groups.indexOf('Branch Admin') > -1 || props?.board?.is_co_owner || props?.board?.owner == props.user.id) ?
                                                <Popover content={checklistPopover(checklist)}>
                                                    <MoreOutlined style={{ fontSize: '20px', fontWeight: 'bold', color: '#important', float: 'right' }} />
                                                </Popover> : null
                                        }
                                    >
                                        {items.map(item => (
                                            <ChecklistItems chkItems={items} checklistItem={item} key={`checklist_item_${item.id}`} checklist={checklist} board={props.board} />
                                        ))}
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                )
            })}
            {modalForm ?
                <CommonModal
                    id={checklistId}
                    open={modalForm}
                    toggle={() => setModalForm(!modalForm)}
                    what_for="checklist"
                    title={checklistTitle}
                    onOk={handleCheckListEdit}
                />
                :
                null
            }
        </>

    );
}

function mapStateToProps(state) {
    const { checklists, members } = state.boards;
    const { user } = state.auth;
    return {
        checklists,
        members,
        user
    }
}

export default connect(mapStateToProps)(CheckList);