import axios from "axios";
import authHeader from "./auth-header";
import { baseUrl } from "./base";

class AuthService {
  login(username, password) {
    return axios
      .post(`${baseUrl}/token/`, { username, password })
      .then((response) => {
        return response.data;
      });
  }

  changeBranch(token, branch) {
    return axios
      .post(`${baseUrl}/change-branch/`, { branch }, { headers: { Authorization: 'Bearer ' + token } },)
      .then((response) => {
        return response.data;
      });
  }

  getUserDetail() {
    return axios
      .get(`${baseUrl}/me/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
}

export default new AuthService();