import axios from "axios";
import { cyclicJobUrl } from "./base";
import authHeader from "./auth-header";

class JobService {
  queryStringToJSON(queryString) {
    if (queryString.indexOf('?') > -1) {
      queryString = queryString.split('?')[1];
    }
    var pairs = queryString.split('&');
    var result = {};
    pairs.forEach(function (pair) {
      pair = pair.split('=');
      if (pair[0] != null && pair[0] != "" && pair[0] != undefined) {
        result[pair[0]] = decodeURIComponent(pair[1] || '');
      }
    });
    return result;
  };

  getAllJobSetups(url, query) {
    let data = {};

    if (url == "" || url == null || url == undefined) {
      url = `${cyclicJobUrl}/job-setup`;
    }
    else {
      data = this.queryStringToJSON(url);
      let x = data.search;
      if (x != null && x != undefined) {
        x = x.replace(/\+/g, " ")
        data.search = x;
      }
      delete data[""];
    }
    if (query == "clear") {
      data["search"] = "";
    }
    else if (query != null && query != "clear") {
      data["search"] = query;
    }
    if (url.indexOf('?') > 0) {
      url = url.substr(0, url.indexOf('?'));
    }
    url = url + "?" + new URLSearchParams(data).toString();
    return axios.get(url, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }


  submitJob(payload) {
    return axios
      .post(`${cyclicJobUrl}/job/submit/`, payload,
        { headers: authHeader(), 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        return response.data;
      });
  }

  getAllJobs(payload, url) {
    let _url = `${cyclicJobUrl}/jobs`;
    if (url != '' && url != undefined && url != null) {
      _url = url;
    }
    return axios
      .get(_url,
        { params: payload, headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

}

export default new JobService();