import React, {useState} from "react";
import { connect } from "react-redux";
import GanttELSChart from '../../components/reports/gantt_chart_els.js';
import ReportFilter from '../../components/filters';


const GanttChartPage = (props) => {	
	const [filters, setFilters] = useState(props.filters_applied);
	const handleOnFilterChange = (_filters)=>{
		setFilters(_filters);
	}

	return (
		<div>
			<div className="filter-container">
				<ReportFilter onFilterChange={handleOnFilterChange} showPriorities={true} showFlags={true} />
			</div>
			<GanttELSChart filters={filters} itemView={false}/>
		</div>
	);
};

function mapStateToProps(state) {
    const { filters_applied } = state.reports;
    return {
        filters_applied
    }
}

export default connect(mapStateToProps)(GanttChartPage);

