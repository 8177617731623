import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ItemActivity from '../../components/reports/item_activity';
import BoardService from '../../redux/services/boards';


const ActivityReport = (props) => {
    const [boards, setBoards] = useState([]);


    useEffect(() => {
        BoardService.getFilters({ 'type': 'board' }).then(res => {
            setBoards(res);
        });
    }, [])

    return (
        <div>
            <ItemActivity boards={boards} />
        </div>
    );
};

function mapStateToProps(state) {
    const { filters_applied } = state.reports;
    return {
        filters_applied
    }
}

export default connect(mapStateToProps)(ActivityReport);

