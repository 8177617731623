import { FilterOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from "react";
// import FormService from '../../redux/services/forms';
// import { FILTERS_APPLIED } from '../../redux/types';
import { useParams } from "react-router-dom";
import FormService from '../../../redux/services/forms';



const { Option } = Select;
const { RangePicker } = DatePicker;


const RecordsFilter = (props) => {
    const { formId } = useParams();
    const [flags, setFlags] = useState([]);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const showDrawer = () => {
        setVisible(!visible);
    };

    const clearFilter = () => {
        form.resetFields();
        props.onFilterChange(null);
        setVisible(!visible);
    }

    const onApplyFilter = (values) => {
        setVisible(false);
        let filter_data = {};

        if (values.dates != null && values.dates != undefined) {
            filter_data["created_at__gte"] = values.dates[0].format("YYYY-MM-DDT00:00:00");
            filter_data["created_at__lte"] = values.dates[1].format("YYYY-MM-DDT23:59:59");
        }
        filter_data["name"] = values.name;
        filter_data["flag"] = values.flag;

        filter_data["created_by__username__icontains"] = values.user_name;
        // filter_data["created_by__first_name__icontains"] = values.user_name;
        // filter_data["created_by__last_name__icontains"] = values.user_name;


        filter_data["updated_by__username__icontains"] = values.user_name2;
        // filter_data["updated_by__first_name__icontains"] = values.user_name2;
        // filter_data["updated_by__last_name__icontains"] = values.user_name2;

        props.onFilterChange(filter_data);
    }

    useEffect(() => {
        FormService.getFlags(formId).then(res => {
            setFlags(res?.flags);
        })
    }, []);


    const content = (
        <Form form={form} layout="vertical" autoComplete="off" onFinish={onApplyFilter}>

            <Form.Item label="By Record Name" name="name">
                <Input style={{ width: '100%' }} placeholder={"Search by record name"} />
            </Form.Item>

            <Form.Item label="By Record Created By User Name" name="user_name">
                <Input style={{ width: '100%' }} placeholder={"Search by first name, last name and username"} />
            </Form.Item>

            <Form.Item label="By Record Modified By User Name" name="user_name2">
                <Input style={{ width: '100%' }} placeholder={"Search by first name, last name and username"} />
            </Form.Item>

            <Form.Item label="By Dates" name="dates">
                <RangePicker style={{ width: '100%' }} />
            </Form.Item>


            <Form.Item label="Flags" name="flag">
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                >
                    {
                        flags && flags.map(flag => (
                            <Option key={`flag_${flag}`} value={flag} >{flag}</Option>
                        ))
                    }
                </Select>
            </Form.Item>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type="default" onClick={clearFilter}>
                    Clear Filter
                </Button>
                <Button type="primary" htmlType="submit">
                    Apply Filter
                </Button>
            </div>
        </Form>
    );


    return (
        <div>
            <Button type="primary" icon={<FilterOutlined />} onClick={showDrawer}>
                Filter
            </Button>

            <Drawer
                title="Filters"
                bodyStyle={{ paddingTop: 5 }}
                placement={'right'}
                closable={false}
                onClose={showDrawer}
                visible={visible}>
                {content}
            </Drawer>

        </div>
    );

}


export default RecordsFilter;