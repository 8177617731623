export const MESSAGES = {
    request_processing: 'Processing your request...',
    boards_loading: "Loading boards...",
    board_loading: "Loading board...",
    board_created: "Board created successfully.",
    board_updated: "Board updated successfully.",
    board_deleted: "Board deleted successfully.",
    list_loading: "Loading list...",
    list_created: "List created successfully.",
    list_updated: "List updated successfully.",
    list_deleted: "List deleted successfully.",
    list_item_archived: "All cards in this list archived.",
    item_loading: "Loading item detail...",
    item_created: "Item created successfully.",
    item_updated: "Item updated successfully.",
    item_deleted: "Item deleted successfully.",
    checklist_created: "Checkliist created successfully.",
    checklist_updated: "Checkliist updated successfully.",
    checklist_deleted: "Checkliist deleted successfully.",
    checklist_item_created: "Checkliist Item created successfully.",
    checklist_item_updated: "Checkliist Item updated successfully.",
    checklist_item_deleted: "Checkliist Item deleted successfully.",
    attachement_created: "Attachement Item created successfully.",
    attachement_updated: "Attachement Item updated successfully.",
    attachement_deleted: "Attachement Item deleted successfully.",
    comment_created: "Comment Item created successfully.",
    comment_updated: "Comment Item updated successfully.",
    comment_deleted: "Comment Item deleted successfully.",
    member_assigned: "Member assigned successfully.",
    member_removed: "Member removed successfully.",
    copy_card: "Copy card successfully. ",
    move_card: "Move card successfully. ",
    link_created: "Link created successfully.",
    link_deleted: "Link deleted successfully.",
}
