import {
    REPORTS,
    OVERALL_REPORTS,
    SET_MESSAGE, 
    PARTICIPIENTS,
    RESPONSIBLE_PERSON,
    BOARD_OWNERS,
    BOARD_CO_OWNERS
  } from "../types";
  import ReportService from "../services/reports";

  export const AllMembers = (m_type) => (dispatch) => {
    return ReportService.allMembers(m_type).then(
      (data) => {
        if(m_type=='participients'){
          dispatch({
            type: PARTICIPIENTS,
            payload: data.results
          });
        }
        else if(m_type=='responsible_person'){
          dispatch({
            type: RESPONSIBLE_PERSON,
            payload: data.results
          });
        }
        else if(m_type=='board_owners'){
          dispatch({
            type: BOARD_OWNERS,
            payload: data.results
          });
        }
        else if(m_type=='board_co_owners'){
          dispatch({
            type: BOARD_CO_OWNERS,
            payload: data.results
          });
        }
        
        return Promise.resolve()
      }).catch(
      (error) => {
        
        return Promise.reject();
      }
    );
  };


  export const AllChecklistItems = () => (dispatch) => {
    return ReportService.personalPerformance().then(
      (data) => {
        dispatch({
          type: REPORTS,
          payload: data
        });
        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: SET_MESSAGE,
          payload:  {message: message, status: error.response.status},
        });
  
        return Promise.reject();
      }
    );
  };

  export const OverallHealthReport = (payload) => (dispatch) => {
    return ReportService.overallHealthReport(payload).then(
      (data) => {
        dispatch({
          type: OVERALL_REPORTS,
          payload: data
        });
        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: SET_MESSAGE,
          payload:  {message: message, status: error.response.status},
        });
  
        return Promise.reject();
      }
    );
  };