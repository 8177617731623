import { PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Checkbox, Col, Input, List, Popover, Row, Typography } from 'antd';
import Form from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { UpdateItem } from '../../../redux/actions/boards';


const Members = (props) => {
    const [showMember, setShowMember] = useState(false);
    const [members, setMembers] = useState(props?.members);

    const assignMember = (event, assign_id) => {
        event.preventDefault();
        let update_data = {
            title: props.item.title,
            assigned_to: assign_id,
            item: props.item.id
        }
        props.dispatch(UpdateItem(0, props.item.id, update_data));
    }

    const searchMember = (value) => {
        let filter_ = props.members.filter(member => {
            return member.username.toLowerCase().includes(value.toLowerCase())
        })
        setMembers(filter_)
    }

    const MemberList = () => {
        return (
            <List className='members' style={{ minWidth: 300 }}>
                {
                    members?.map(item => {
                        let itemIndex = props?.item?.assigned_to?.findIndex(member => member.id == item.id);
                        return (
                            itemIndex > -1 ?
                                <List.Item key={`member_list_${item.id}`} onClick={(event) => assignMember(event, item.username)}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col span={24}>
                                            <Typography.Text strong>{item.first_name} {item.last_name}</Typography.Text>
                                        </Col>
                                        <Col span={24}>
                                            <Typography.Text>{item.username}</Typography.Text>
                                        </Col>
                                    </Row>
                                    <Form>
                                        <Checkbox checked={itemIndex > -1} />
                                    </Form>
                                </List.Item> : null
                        )
                    })
                }

                {members?.map(item => {
                    let itemIndex = props?.item?.assigned_to?.findIndex(member => member.id == item.id);
                    return (
                        itemIndex < 0 ?
                            <List.Item key={`member_list_${item.id}`} onClick={(event) => assignMember(event, item.username)}>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col span={24}>
                                        <Typography.Text strong>{item.first_name} {item.last_name}</Typography.Text>
                                    </Col>
                                    <Col span={24}>
                                        <Typography.Text>{item.username}</Typography.Text>
                                    </Col>
                                </Row>
                                <Form>
                                    <Checkbox checked={itemIndex > -1} />
                                </Form>
                            </List.Item> : null
                    )
                })}
            </List>
        )
    }

    const handleMemberAssign = () => {
        setShowMember(!showMember);
    }

    return (
        <Popover
            content={props.members.length > 0 && MemberList}
            title={
                <div>
                    <strong>Members</strong>
                    <Input size="small" onChange={(e) => searchMember(e.target.value)} placeholder="Type here to search..." suffix={<SearchOutlined />} />
                </div>
            }
            trigger="click"
            visible={showMember}
            onVisibleChange={handleMemberAssign}
            placement={"rightTop"}
        >
            {
                // Either Item Creator or Board Owner or Board Co-Owner
                (props?.item?.created_by?.id == props.user.id || props.user.groups.indexOf('Branch Admin') > -1 || props?.board?.is_co_owner || props?.board?.owner == props.user.id) ? <PlusCircleFilled /> : null
            }

        </Popover>
    );
};
function mapStateToProps(state) {
    const { members, item, board } = state.boards;
    const { user } = state.auth;
    return {
        members, item, user, board
    }
}
export default connect(mapStateToProps)(Members);