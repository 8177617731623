import { Button, Input, Modal, Radio } from 'antd';
import React, { useEffect, useState } from "react";
import {
    useParams
} from 'react-router-dom';

import { Card, CardBody, CardTitle, Container } from 'reactstrap';
import { default as FormService, default as service } from '../../redux/services/forms';



const FormEmailNotification = (props) => {
    const { form_id } = useParams();
    const [modalVisible, setModalVisible] = useState(false);
    const [alertName, setAlertName] = useState(false);
    const [selectedFlags, setSelectedFlags] = useState({});

    const [alerts, setAlerts] = useState([]);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [form, setForm] = useState(null);


    const loadAlerts = () => {
        service.getEmailNotification(form_id).then(res => {
            setAlerts(res.data.data);
        });
    }
    useEffect(() => {
        FormService.getFormDetail(form_id).then(res => {
            setForm(res);
            let flags = {};
            res.externals.flags.map(item => {
                flags[item.name] = item.name + '#' + item.values[item.value];
            });
            setSelectedFlags(flags);
            console.log('Default Flags > ', flags);
        });
        loadAlerts();
    }, [form_id]);

    const onCreate = async (e) => {
        e.preventDefault();
        let flags = [];
        Object.keys(selectedFlags).map(elm => {
            flags.push(selectedFlags[elm]);
        });
        if (alertName == '') {
            return;
        }
        if (flags.length <= 0) {
            return;
        }

        let payload = {
            'form': form_id,
            'alert_name': alertName,
            'flags': flags.join(',')
        };

        var resp = null;
        if (selectedAlert == null) {
            resp = await service.createEmailNotification(payload);
        }
        else {
            payload['id'] = selectedAlert.id;
            resp = await service.updateEmailNotification(payload);
        }
        console.log('resp > ', resp);

        setModalVisible(false);
        setSelectedFlags({});
        setSelectedAlert(null);
        loadAlerts();
    };
    const onFlagChange = (e, flag) => {
        console.log('flag > ', flag);
        let flags = selectedFlags || {};
        flags[flag.name] = e.target.value;
        setSelectedFlags(flags);
        // if (selectedFlags.indexOf(e.target.value) > -1) {
        //     setSelectedFlags(prevValues =>
        //         prevValues.filter(item => item !== e.target.value)
        //     );
        // }
        // else {
        //     setSelectedFlags(prevValues => [...prevValues, e.target.value]);
        // }
        // if (e.target.checked) {
        //     setSelectedFlags(prevValues => [...prevValues, e.target.value]);
        // }
        // else {
        //     setSelectedFlags(prevValues =>
        //         prevValues.filter(item => item !== e.target.value)
        //     );
        // }
        console.log('selectedFlags > ', selectedFlags);

        // let flags = selectedFlags || [];
        // if (flags.indexOf(item.name + '#' + flag) > -1) {
        //     flags.splice(flags.indexOf(item.name + '#' + flag), 1);
        // }
        // else {
        //     flags.push(item.name + '#' + flag);
        // }
        // setSelectedFlags(flags);
    }

    const isChecked = (flag) => {
        let flags = [];
        Object.keys(selectedFlags).map(elm => {
            flags.push(selectedFlags[elm]);
        });

        console.log('flags > ', flags, flag);
        if (flags.indexOf(flag) > -1) {
            return true;
        }
        return false;
    }

    const onDelete = (data) => {
        if (window.confirm('Are you sure want to delete this notification?')) {
            service.deleteEmailNotification(data.id).then(res => {
                loadAlerts();
            });
        }
    }

    const onEdit = (element) => {
        setSelectedFlags(element.flags.split(','))
        setSelectedAlert(element);
        setModalVisible(true);
    }

    return (
        <Container fluid={false} style={{ marginTop: 50 }}>

            <Modal title={"Create Notification"} visible={modalVisible} destroyOnClose={true} onCancel={(e) => {
                setModalVisible(false);
                setSelectedFlags({});
                setSelectedAlert(null);
            }} onOk={onCreate}>
                <div>
                    <b>Alert Name</b>
                    <Input placeholder="Alert Name" value={selectedAlert?.alert_name} onChange={(e) => setAlertName(e.target.value)} />
                    <br />
                    <br />
                    <b>Select flags</b>

                    <ul style={{ listStyle: 'none' }}>
                        {
                            form?.externals?.flags?.map((item, li_indx) => {
                                return (
                                    <li key={`li_indx_${li_indx}`} style={{
                                        border: '1px solid #ccc',
                                        padding: 5,
                                        marginBottom: 10
                                    }}>
                                        <span style={{ minWidth: '100px', display: 'inline-block' }}>{item.name}</span>
                                        <Radio.Group onChange={(e) => {
                                            onFlagChange(e, item);
                                        }} defaultValue={selectedFlags[item.name]} style={{ display: 'inline-grid', marginLeft: 10 }} >

                                            {
                                                item?.values?.map((flag, index) => {
                                                    return (<Radio key={`chk_indx_${index}`} value={item.name + '#' + flag}>{flag}</Radio>)
                                                    {/* <Checkbox checked={selectedFlags.indexOf(item.name + '#' + flag) > -1} value={item.name + '#' + flag} onChange={onFlagChange}>{flag}</Checkbox> */ }

                                                })
                                            }

                                        </Radio.Group>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </Modal>

            <Card style={{ padding: 10 }}>
                <CardTitle>
                    <h5>Email Notifications</h5>
                    <Button type="primary" style={{ position: 'absolute', top: 10, right: 10 }} onClick={() => { setModalVisible(true); }} >Add New</Button>
                </CardTitle>
                <CardBody>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Form</th>
                                <th>Name</th>
                                <th>Flags</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                alerts.map((element, index) => {
                                    return (
                                        <tr key={`tbl_indx_${index}`}>
                                            <td>{form?.name}</td>
                                            <td>{element?.alert_name}</td>
                                            <td>
                                                {
                                                    element?.flags.split(',')?.map(elm => {
                                                        return (<span style={{ backgroundColor: 'orange', margin: 5, padding: '2px 5px', borderRadius: 5, color: '#fff' }}>{elm.split('#')[1]}</span>)
                                                    })
                                                }
                                            </td>
                                            <td>
                                                <Button danger type='primary' onClick={() => {
                                                    onDelete(element);
                                                }}>Delete</Button>
                                                {/* &nbsp;&nbsp;&nbsp;
                                                <Button type='primary' onClick={() => {
                                                    onEdit(element);
                                                }}>Edit</Button> */}
                                            </td>
                                        </tr>

                                    )
                                }
                                )
                            }
                        </tbody>
                    </table>
                </CardBody>
            </Card>
        </Container>
    );

}


export default FormEmailNotification;
