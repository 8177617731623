import { Pie } from '@ant-design/plots';
import { Card } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import ReportService from "../../../redux/services/reports";

const IMTEReport = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const payload = {
            'filters': props.filters
        };
        ReportService.IMTEReportData(payload).then(res => {
            // Rename pi chart of report to: Previously active/New active/ Inactivated/ Currently active 

            // total_active_jobs
            // total_inactive_jobs
            // other_inactive_jobs
            // new_active_jobs

            var x = {
                "Previously active": 0,
                "New active": 0,
                "Inactivated": res.total_inactive_jobs,
                "Currently active": 0
            };
            setData(res);
        });
    }, [props.filters])

    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'name',
        radius: 0.75,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}',
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    }


    return (
        <Card title="IMTE Report ">
            <Pie {...config} />
        </Card>
    );
}


function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(IMTEReport);

