import { JOB_SETUP_LIST, JOB_LIST } from "../types";

const initialState = {
  job_setup_list: null,
  jobs: []
}

function jobs(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case JOB_SETUP_LIST:
      return {
        ...state,
        job_setup_list: payload
      }
    case JOB_LIST:
      return {
        ...state,
        jobs: payload
      }
    default:
      return state;
  }
}


export default jobs;