import React from "react";

import CreateRecord from '../../components/forms/records/views/CreateRecord';

const CreateRecordPage = (props) => {
    return (
        <CreateRecord />
    );
}


export default CreateRecordPage;