import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { AllBoards, AllCategories, DeleteBoard } from "../../redux/actions/boards";
import CreateBoard from './create-board';

import Icon, { AppstoreAddOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row } from 'antd';

import card_bg from "../../assets/images/board_card_bg.jpg";
import create_board_bg from "../../assets/images/create_board_bg.png";
import { AdminPanelIcon, Engineering, SupervisedUserCircle } from "../icons/index";



const { confirm } = Modal;
const { Meta } = Card;


const Boards = (props) => {
    const history = useHistory();
    const [modalOpen, setModalOpen] = useState(false)
    // const [modalId, setModalId] = useState(null)

    const [board, setBoard] = useState(null)

    useEffect(() => {
        props.dispatch(AllCategories());
        props.dispatch(AllBoards());
    }, [])

    const changeRoute = (e, id) => {
        e.stopPropagation();
        history.push(`/board/${id}`)
    }

    const EditModal = (e, board) => {
        e.stopPropagation();
        if (board != null) {
            setBoard(board);
        }
        else {
            const x = { title: "", description: "", co_owners: [], status: "create_new" };
            setBoard(x);
        }
        setTimeout(() => setModalOpen(true), 200);
        // setModalId(id)
    }

    const handleBoardDelete = (e, board) => {
        e.preventDefault();
        e.stopPropagation();
        showDeleteConfirm(board);
    }

    const showDeleteConfirm = (board) => {
        confirm({
            title: `Are you sure delete this board?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                props.dispatch(DeleteBoard(board))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const renderBoards = (category) => {

        props?.boards?.filter(element => element.board_type != 'template')?.map((board, index) => {
            let card_actions = [];
            if (board.owner == props.user.id) {
                card_actions.push(
                    <Button type="text" danger onClick={(e) => { handleBoardDelete(e, board) }}>
                        <DeleteOutlined />
                    </Button>
                );
                card_actions.push(
                    <Button type="text" key={"edit_" + board.id}>
                        <EditOutlined title="Edit Board" onClick={(e) => EditModal(e, board)} />
                    </Button>
                );
            }
            card_actions.push(
                <Button type="text" key={"view_" + board.id} onClick={(e) => changeRoute(e, board.id)}>
                    <EyeOutlined />
                </Button>
            );

            return (
                <Col xs={24} sm={12} md={6} lg={6} xl={4} xxl={3} className="board_tile" key={"board_card" + index} style={{ marginBottom: 20 }}>
                    <Card size="small"
                        cover={
                            <img alt={board.title} style={{ width: '100%', maxHeight: 125 }} src={board.image ? board.image : card_bg} />
                        }
                        key={board.id}
                        onClick={(e) => changeRoute(e, board.id)}
                        hoverable={true}
                        actions={card_actions}
                    >

                        {/* <Tooltip title={board.title}> */}
                        <Meta
                            title={board.title}
                            avatar={
                                props.user.id == board.owner ? <Icon component={AdminPanelIcon} style={{ color: '#4CAF50', fontSize: 35 }} /> :
                                    board.is_co_owner ? <Icon component={SupervisedUserCircle} style={{ color: '#FFC107', fontSize: 35 }} /> :
                                        <Icon component={Engineering} style={{ color: '#9C27B0', fontSize: 35 }} />

                            }
                            description={
                                board.board_type != 'template' ?
                                    <div className="task-stats">
                                        <span className="completed">{board.stats.completed}</span>
                                        <span className="pending">{board.stats.pending}</span>
                                        <span className="due">{board.stats.due}</span>
                                    </div> : <div className="task-stats"></div>
                            }
                        />
                        {/* </Tooltip> */}
                    </Card>
                </Col>
            )
        })

    }

    return (
        <div>
            <CreateBoard
                modalOpen={modalOpen}
                setModalOpen={(value) => setModalOpen(value)}
                // modalId={modalId}
                board={board}
            />

            {
                props?.categories?.map(category => (

                    props?.boards?.filter(element => element.board_type != 'template' && element.category == category.name).length > 0 ?
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <h3 className="heading">{category.name}</h3>
                            </Col>
                            {
                                props?.boards?.filter(element => element.board_type != 'template' && element.category == category.name)?.map((board, index) => {
                                    let card_actions = [];
                                    if (board.owner == props.user.id || props.user.groups.indexOf('Branch Admin') > -1) {
                                        card_actions.push(
                                            <Button type="text" danger onClick={(e) => { handleBoardDelete(e, board) }}>
                                                <DeleteOutlined />
                                            </Button>
                                        );
                                        card_actions.push(
                                            <Button type="text" key={"edit_" + board.id}>
                                                <EditOutlined title="Edit Board" onClick={(e) => EditModal(e, board)} />
                                            </Button>
                                        );
                                    }
                                    card_actions.push(
                                        <Button type="text" key={"view_" + board.id} onClick={(e) => changeRoute(e, board.id)}>
                                            <EyeOutlined />
                                        </Button>
                                    );

                                    return (
                                        <Col xs={24} sm={12} md={6} lg={6} xl={4} xxl={3} className="board_tile" key={"board_card" + index} style={{ marginBottom: 20 }}>
                                            <Card size="small"
                                                cover={
                                                    <img alt={board.title} style={{ width: '100%', maxHeight: 125 }} src={board.image ? board.image : card_bg} />
                                                }
                                                key={board.id}
                                                onClick={(e) => changeRoute(e, board.id)}
                                                hoverable={true}
                                                actions={card_actions}
                                            >

                                                {/* <Tooltip title={board.title}> */}
                                                <Meta
                                                    title={board.title}
                                                    avatar={
                                                        props.user.id == board.owner ? <Icon component={AdminPanelIcon} style={{ color: '#4CAF50', fontSize: 35 }} /> :
                                                            board.is_co_owner ? <Icon component={SupervisedUserCircle} style={{ color: '#FFC107', fontSize: 35 }} /> :
                                                                <Icon component={Engineering} style={{ color: '#9C27B0', fontSize: 35 }} />

                                                    }
                                                    description={
                                                        board.board_type != 'template' ?
                                                            <div className="task-stats">
                                                                <span className="completed">{board.stats.completed}</span>
                                                                <span className="pending">{board.stats.pending}</span>
                                                                <span className="due">{board.stats.due}</span>
                                                            </div> : <div className="task-stats"></div>
                                                    }
                                                />
                                                {/* </Tooltip> */}
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row> : null

                ))
            }

            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <h3 className="heading">Unknown</h3>
                </Col>
                {
                    props?.boards?.filter(element => element.board_type != 'template' && (element.category == '' || element.category == null))?.map((board, index) => {
                        let card_actions = [];
                        if (board.owner == props.user.id) {
                            card_actions.push(
                                <Button type="text" danger onClick={(e) => { handleBoardDelete(e, board) }}>
                                    <DeleteOutlined />
                                </Button>
                            );
                            card_actions.push(
                                <Button type="text" key={"edit_" + board.id}>
                                    <EditOutlined title="Edit Board" onClick={(e) => EditModal(e, board)} />
                                </Button>
                            );
                        }
                        card_actions.push(
                            <Button type="text" key={"view_" + board.id} onClick={(e) => changeRoute(e, board.id)}>
                                <EyeOutlined />
                            </Button>
                        );

                        return (
                            <Col xs={24} sm={12} md={6} lg={6} xl={4} xxl={3} className="board_tile" key={"board_card" + index} style={{ marginBottom: 20 }}>
                                <Card size="small"
                                    cover={
                                        <img alt={board.title} style={{ width: '100%', maxHeight: 125 }} src={board.image ? board.image : card_bg} />
                                    }
                                    key={board.id}
                                    onClick={(e) => changeRoute(e, board.id)}
                                    hoverable={true}
                                    actions={card_actions}
                                >
                                    <Meta
                                        title={board.title}
                                        avatar={
                                            props.user.id == board.owner ? <Icon component={AdminPanelIcon} style={{ color: '#4CAF50', fontSize: 35 }} /> :
                                                board.is_co_owner ? <Icon component={SupervisedUserCircle} style={{ color: '#FFC107', fontSize: 35 }} /> :
                                                    <Icon component={Engineering} style={{ color: '#9C27B0', fontSize: 35 }} />

                                        }
                                        description={
                                            board.board_type != 'template' ?
                                                <div className="task-stats">
                                                    <span className="completed">{board.stats.completed}</span>
                                                    <span className="pending">{board.stats.pending}</span>
                                                    <span className="due">{board.stats.due}</span>
                                                </div> : <div className="task-stats"></div>
                                        }
                                    />
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <h3 className="heading">Templates</h3>
                </Col>

                {props?.boards?.filter(element => element.board_type == 'template')?.map((board, index) => {
                    let card_actions = [];
                    if (board.owner == props.user.id) {
                        card_actions.push(
                            <Button type="text" danger onClick={(e) => { handleBoardDelete(e, board) }}>
                                <DeleteOutlined />
                            </Button>
                        );
                        card_actions.push(
                            <Button type="text" key={"edit_" + board.id}>
                                <EditOutlined title="Edit Board" onClick={(e) => EditModal(e, board)} />
                            </Button>
                        );
                    }
                    card_actions.push(
                        <Button type="text" key={"view_" + board.id} onClick={(e) => changeRoute(e, board.id)}>
                            <EyeOutlined />
                        </Button>
                    );

                    return (
                        <Col xs={24} sm={12} md={6} lg={6} xl={4} xxl={3} className="board_tile" key={"board_card" + index} style={{ marginBottom: 20 }}>
                            <Card size="small"
                                cover={
                                    <img alt={board.title} style={{ width: '100%', maxHeight: 125 }} src={board.image ? board.image : card_bg} />
                                }
                                style={{ minHeight: 265 }}
                                key={board.id}
                                onClick={(e) => changeRoute(e, board.id)}
                                hoverable={true}
                                actions={card_actions}
                            >

                                {/* <Tooltip title={board.title}> */}
                                <Meta
                                    title={board.title}
                                    avatar={
                                        props.user.id == board.owner ? <Icon component={AdminPanelIcon} style={{ color: '#4CAF50', fontSize: 35 }} /> :
                                            board.is_co_owner ? <Icon component={SupervisedUserCircle} style={{ color: '#FFC107', fontSize: 35 }} /> :
                                                <Icon component={Engineering} style={{ color: '#9C27B0', fontSize: 35 }} />

                                    }
                                    description={
                                        board.board_type != 'template' ?
                                            <div className="task-stats">
                                                <span className="completed">{board.stats.completed}</span>
                                                <span className="pending">{board.stats.pending}</span>
                                                <span className="due">{board.stats.due}</span>
                                            </div> : <div className="task-stats"></div>
                                    }
                                />
                                {/* </Tooltip> */}
                            </Card>
                        </Col>
                    )
                })}

                {
                    props.user.groups.indexOf('Branch Admin') > -1 ? (
                        <Col span={4} className="board_tile1">
                            <Card size="small" style={{ width: 240, height: 235 }} onClick={(e) => EditModal(e, null)}
                                cover={
                                    <img alt={"Create New Board"} style={{ width: '100%', maxHeight: 125 }} src={create_board_bg} />
                                }
                                actions={[
                                    <Button type="text" block>
                                        <AppstoreAddOutlined />
                                    </Button>
                                ]}
                            >
                                <Meta title={"Add New Board"} description={'-'} />
                            </Card>
                        </Col>
                    ) : null
                }
            </Row>
        </div>
    )
};

function mapStateToProps(state) {
    const { categories, boards } = state.boards;
    const { user } = state.auth;
    // console.log('user > ', user);
    return {
        boards,
        user,
        categories
    }
}

export default connect(mapStateToProps)(Boards);
