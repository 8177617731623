import { message } from "antd";
import { logout } from './actions/auth';

const key = "updatable";

export const MESSAGE_ERROR = (error, dispatch) => {
    // const history = useHistory();

    let message_resp =
        (error?.response &&
            error?.response.data &&
            error?.response?.data?.message) ||
        error?.message ||
        error?.toString();
    if (error?.response?.status === 403) {
        message_resp = error.response.data.detail;
    }
    else if (error?.response?.status === 405) {
        message_resp = error.response.data.error;
    }
    else if (error?.response?.status === 401) {
        dispatch(logout());
        window.location = '/';
        // history.push(`/boards`);
    }
    message.error({ content: message_resp, key })
}