import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_BOARDS,
  RESET_REPORTS,
  RESET_SPC,
  SET_MESSAGE,
  USER_DETAIL
} from "../types";

import AuthService from "../services/auth";

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: data.access,
          refreshToken: data.refresh
        },
      });
      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, status: error.response.status },
      });

      return Promise.reject();
    }
  );
};

export const changeBranch = (token, branch) => (dispatch) => {
  return AuthService.changeBranch(token, branch).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: data.access,
          refreshToken: data.refresh
        },
      });
      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, status: error.response.status },
      });

      return Promise.reject();
    }
  );
}

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });

  dispatch({
    type: USER_DETAIL,
    payload: { user: {} }
  });
  dispatch({
    type: RESET_BOARDS,
    payload: {}
  });
  dispatch({
    type: RESET_REPORTS,
    payload: {}
  });
  dispatch({
    type: RESET_SPC,
    payload: {}
  });

  return Promise.resolve()
};

export const GetUserDetail = () => (dispatch) => {
  return AuthService.getUserDetail().then(
    (response) => {
      dispatch({
        type: USER_DETAIL,
        payload: { "user": response }
      });
      return response
    }
  );
};
