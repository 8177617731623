import React from "react";
import { Container } from "reactstrap";
import Jobs from '../../components/cyclic-job/jobs';

const JobsPage = () => {

    return (
        <Container fluid={true}>
            <Jobs jobType="" />
        </Container>
    );
};

export default JobsPage;
