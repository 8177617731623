export default function ListElement({ id, comps, setComps, sel, setSel, hov, setHov }) {

    return (
        <div key={id} className='element pointy' onMouseOver={() => {
            setHov(id);
        }} onMouseLeave={() => {
            setHov(-1);
        }} style={{
            backgroundColor: hov === id ? '#FAF3DD' : 'rgb(0 22 40 / 43%)',
            color: hov === id ? '#0F1108' : '#FAF3DD',
            transition: 'background-color 0.1s, color 0.1s',
            marginBottom: 5
        }} onDoubleClick={() => {
            if (sel === -1) {
                setSel(id);
            }
        }}>
            <i className="fa-solid fa-caret-right"></i>
            {sel !== id ?
                <p>{comps[id].name + ' (' + id.toString() + ')'}</p> :
                <>
                    <input style={{
                        marginLeft: '0.5vmax',
                        marginTop: '0.5vmax',
                        marginBottom: '0.5vmax'
                    }} className='panel-input' onChange={(e) => {
                        let new_comps = [...comps];
                        new_comps[id].name = e.target.value;
                        setComps(new_comps);
                    }} value={comps[id].name} />

                    <p>{' (' + id.toString() + ')'}</p>

                    <i className="fa-solid fa-angles-up" style={{
                        fontSize: '1vmax',
                        // marginLeft: '20%'
                    }} onClick={() => {
                        let new_comps = [...comps];
                        if (id !== 0) {
                            let above = comps[id - 1];
                            new_comps[id - 1] = new_comps[id];
                            new_comps[id] = above;
                            setComps(new_comps);
                            setSel(id - 1);
                        }
                    }}></i>
                    <i className="fa-solid fa-angles-down" style={{
                        fontSize: '1vmax',
                        marginLeft: '0%'
                    }} onClick={() => {
                        let new_comps = [...comps];
                        if (id !== new_comps.length - 1) {
                            let below = comps[id + 1];
                            new_comps[id + 1] = new_comps[id];
                            new_comps[id] = below;
                            setComps(new_comps);
                            setSel(id + 1);
                        }
                    }}>
                    </i>
                </>
            }
        </div>);

}