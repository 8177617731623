import React, { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';
import { connect } from 'react-redux';
const {TextArea} = Input

const CommonModal = (props) => { 
    const [itemForm] = Form.useForm(null)
    const {what_for, open, toggle} = props
    useEffect(()=>{
        let _itemFormData = {
            title: props.title
        }
        itemForm.setFieldsValue(_itemFormData)
    },[props.title])

    const handleOnOk = ()=>{
        itemForm.validateFields().then(values => {
            props.onOk(values, what_for);
        });
    }
    
    return (
        <div>
            <Modal
                visible={open}
                onCancel={toggle}
                title={`Edit ${what_for}`}
                onOk={handleOnOk}
                centered={true}
                destroyOnClose={true}
            >
                <Form
                        form={itemForm}
                        labelCol={{ span: 24 }}
                        layout={"vertical"}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                        >
                        <Form.Item 
                            label=""
                            name="title"
                            rules={[{ required: true, message: 'Title is required!' }]}
                        >
                            {what_for==="description" ? 
                                <TextArea rows="4" placeholder="Enter Description" allowClear={true} autoFocus={true} tabIndex={0}/>
                            :
                                <Input allowClear={true} autoFocus={true}
                                    placeholder="Enter Title"                                    
                                />
                            }
                        </Form.Item>
                    </Form>
            </Modal>
        </div>
    );
};

export default connect()(CommonModal);