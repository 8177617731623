import React from "react";

import CreateForm from '../../components/forms/create-form';

const CreateFormPage = (props) => {
    return (
        <CreateForm />
    );
}


export default CreateFormPage;