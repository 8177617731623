
import { useState } from 'react';

import { Modal as AntdModal } from 'antd';

import Modal from "../../utils/Modal";

import { LabelAdder } from '../../primitive/Label';
import { BoxAdder } from '../../primitive/Box';
import { InputAdder } from '../../primitive/Input';
import { ButtonAdder } from '../../primitive/Button';
import { RadioAdder } from '../../primitive/Radio';
import { CheckAdder } from '../../primitive/Check';
import { ListAdder } from '../../primitive/List';
import { DropAdder } from '../../primitive/Drop';
import { ImageAdder } from '../../primitive/Image';

import { SignatureAdder } from '../../intermediate/Signature';
import { InputTableAdder } from '../../intermediate/InputTable';
import { SnapshotAdder } from '../../intermediate/Snapshot';

export default function AddElement({ comps, setComps, setView, page, pageId, setSelectedEl }) {

    const [tab, setTab] = useState('primitive');

    const component_list = {
        primitive: [
            <LabelAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />,
            <InputAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />,
            <DropAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />,
            <RadioAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />,
            <CheckAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />,
            <ImageAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />,
            <BoxAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />,
            <ListAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />,
            <ButtonAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />
        ],
        intermediate: [
            <InputTableAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />,
            <SignatureAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />,
            <SnapshotAdder comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} setSelectedEl={setSelectedEl} />
        ],
        advanced: [

        ]
    };

    let component_list_render = component_list[tab];

    const tabStyling = {
        display: 'inline-block',
        marginLeft: '1vmax',
        marginRight: '1vmax',
        transition: 'font-weight 0.1s, border-bottom 0.1s',
        paddingBottom: '0.05vmax'
    };

    return (<>
        <AntdModal title="Add Element" visible={true} footer={null} onOk={() => {
            setView('');
        }} onCancel={() => {
            setView('');
        }}>
            <div style={{
                marginBottom: '0.5vmax'
            }}>
                <p className='panel-label pointy' style={{
                    ...tabStyling,
                    fontWeight: tab === 'primitive' ? '700' : '300',
                    borderBottom: tab === 'primitive' ? '1px #FAF3DD solid' : ''
                }} onClick={() => {
                    setTab('primitive');
                }}>Primitive</p>
                <p className='panel-label pointy' style={{
                    ...tabStyling,
                    fontWeight: tab === 'intermediate' ? '700' : '300',
                    borderBottom: tab === 'intermediate' ? '1px #FAF3DD solid' : ''
                }} onClick={() => {
                    setTab('intermediate');
                }}>Intermediate</p>
                <p className='panel-label pointy' style={{
                    ...tabStyling,
                    fontWeight: tab === 'advanced' ? '700' : '300',
                    borderBottom: tab === 'advanced' ? '1px #FAF3DD solid' : ''
                }} onClick={() => {
                    setTab('advanced');
                }}>Advanced</p>
            </div>
            <div style={{
                // overflow: 'scroll',
                // height: '47.5%'
            }} className='toolbar-element-container'>
                {component_list_render}
            </div>

            {/* <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                backgroundColor: '#000000'
            }}>
                <a href='' className='panel-button' style={{
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setView('');
                }}>Cancel</a>
            </div> */}
        </AntdModal>
    </>);

}