import { Modal } from 'antd';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    BrowserQRCodeReader
} from "@zxing/library";

const QRScanner = (props) => {

    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const [videoInputDevices, setVideoInputDevices] = useState([]);
    // const [sourceSelect, setSourceSelect] = useState(null);
    const codeReader = new BrowserQRCodeReader();
    const history = useHistory();
    const btnStart = React.useRef(0);

    useEffect(() => {
        codeReader
            .getVideoInputDevices()
            .then(videoInputDevices => {
                setupDevices(videoInputDevices);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    let sourceSelect;

    function setupDevices(videoInputDevices) {
        sourceSelect = document.getElementById("sourceSelect");
        // setSourceSelect(document.getElementById("sourceSelect"));

        // selects first device
        setSelectedDeviceId(videoInputDevices[0].deviceId);


        // setup devices dropdown
        if (videoInputDevices.length >= 1) {
            setVideoInputDevices(videoInputDevices)
        }
    }

    function resetClick() {
        codeReader.reset();
        console.log("Reset.");
        history.push('/forms');
    }

    function decodeOnce(selectedDeviceId) {
        codeReader.decodeFromInputVideoDevice(selectedDeviceId, 'video').then((result) => {
            if (result.text.startsWith(process.env.REACT_APP_URL)) {
                // resetClick();
                codeReader.reset();

                // recordId = x.replace("https://dashboard-stage.satet.us/forms/", "").split('/')[2];

                window.location = result;
                // props?.onQRScanned(result.text);
            }
            else {
                console.log('Not started with');
            }

        }).catch((err) => {
            console.error(err)
            document.getElementById('result').textContent = err
        });
    }

    // useEffect(() => {
    //     if (btnStart != null && btnStart != undefined) {
    //         btnStart?.current?.click();
    //     }
    // }, [selectedDeviceId])

    const startScan = () => {
        decodeOnce(selectedDeviceId);
    }

    return (
        <Modal width={370} title="Scan QR Code" visible={true} destroyOnClose={true} onOk={() => {
            resetClick();
            props?.onClosed();
        }} onCancel={() => {
            resetClick();
            props?.onClosed();
        }} footer={null}>
            <div style={{ textAlign: 'center' }}>
                <main class="wrapper1">
                    <section className="container" id="demo-content">
                        <div id="sourceSelectPanel">
                            <label for="sourceSelect">Change video source:</label>
                            <select
                                id="sourceSelect"
                                onChange={(value) => {
                                    // setSelectedDeviceId(sourceSelect?.value);
                                }}
                            >
                                {
                                    videoInputDevices.map(element => (
                                        <option value={element.deviceId}>{element.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <br />
                        <div>
                            <video id="video" width="100%" height="220" style={{ border: '1px solid #000' }} />
                        </div>

                        <button id="can" ref={btnStart} onClick={() => startScan()}>
                            Start Scan
                        </button> &nbsp; &nbsp;
                        <button id="resetButton" onClick={() => resetClick()}>
                            Reset
                        </button>
                    </section>
                </main>
            </div>
        </Modal>
    );
}

export default QRScanner;