import axios from "axios";
import authHeader from "./auth-header";
import { cyclicJobUrl, managementUrl, reportUrl } from "./base";

class ReportService {
  getArchivedItems(boardId, startDate, endDate) {
    let url = `${managementUrl}/item/`;
    const params = { 'archived': 1, 'board': boardId, 'startDate': startDate, 'endDate': endDate };

    return axios
      .get(url,
        { params: params, headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  itemActivitiesExport = async (boardId) => {
    return await axios.get(`${reportUrl}/item/activity/export/?board_id=${boardId}`, { headers: authHeader(), responseType: 'blob' }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `task_status_report_${new Date().toLocaleDateString()}.csv`); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  itemActivities(board_id, showCompleted, startDate, endDate) {
    let url = `${reportUrl}/item/activity/`; //?board_id=${board_id}&showCompleted=${showCompleted}`;

    const params = {
      'board_id': board_id,
      'showCompleted': showCompleted,
      'startDate': startDate,
      'endDate': endDate
    };

    return axios
      .get(url,
        { params: params, headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }


  ganttReport(payload, itemView) {
    let url;
    if (itemView == true) {
      url = `${reportUrl}/gantt/item`;
    }
    else {
      url = `${reportUrl}/gantt/`;
    }

    return axios
      .get(url,
        { params: payload, headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  personalPerformance(payload) {
    let url = `${reportUrl}/personal-performance/`;
    return axios
      .get(url,
        { params: payload, headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
  personalPerformanceExport() {
    let url = `${reportUrl}/personal-performance/?export=1`;
    return axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
      headers: authHeader()
    });
  }

  completetionAdherence(payload) {
    let url = `${cyclicJobUrl}/job/completetion/adherence/`;
    return axios.get(url,
      {
        params: payload,
        headers: authHeader()
      }).then((response) => {
        return response.data;
      });
  }


  missingItemSpentByCategory(payload) {
    let url = `${cyclicJobUrl}/reports/missing/items/spent/category/`;
    return axios.get(url,
      {
        params: payload,
        headers: authHeader()
      }).then((response) => {
        return response.data;
      });
  }

  missingItemDaysByCategory(payload) {
    let url = `${cyclicJobUrl}/reports/missing/days/category/`;
    return axios.get(url,
      {
        params: payload,
        headers: authHeader()
      }).then((response) => {
        return response.data;
      });
  }

  IMTEReportData(payload) {
    let url = `${cyclicJobUrl}/reports/imte/`;
    return axios.get(url,
      {
        params: payload,
        headers: authHeader()
      }).then((response) => {
        return response.data;
      });
  }

  personalPerformance(payload) {
    let url = `${cyclicJobUrl}/reports/chart6/`;
    return axios.get(url,
      {
        params: payload,
        headers: authHeader()
      }).then((response) => {
        return response.data;
      });
  }


  missingItemSpentByOwner(payload) {
    let url = `${cyclicJobUrl}/reports/missing/items/spent/owner/`;
    return axios.get(url,
      {
        params: payload,
        headers: authHeader()
      }).then((response) => {
        return response.data;
      });
  }

  overdueTaskByWeek(payload) {
    let url = `${cyclicJobUrl}/reports/delayed/jobs/`;
    return axios.get(url,
      {
        params: payload,
        headers: authHeader()
      }).then((response) => {
        return response.data;
      });
  }



  overallHealthReport(payload) {
    let url = `${reportUrl}/overall-health/`;
    return axios.get(url,
      {
        params: payload,
        headers: authHeader()
      }).then((response) => {
        return response.data;
      });
  }
  whoOwnsAsync(payload) {
    let url = `${reportUrl}/who-owns/`;
    return axios
      .get(url,
        { params: payload, headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }
  whereIsDelay(payload) {
    let url = `${reportUrl}/where-is-delay/`;
    return axios
      .get(url,
        { params: payload, headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allMembers(member_type) {
    return axios
      .get(`${managementUrl}/members/?m_type=${member_type}`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

}



export default new ReportService();