

import { Modal as AntdModal, Button } from 'antd';
import {
    useEffect,
    useState
} from 'react';

export default function Externals({
    comps,
    setComps,
    setView,
    pages,
    setPages,
    vars,
    setVars,
    externals,
    setExternals
}) {

    const [modalTitle, setModalTitle] = useState('Externals');
    const [isManagingFlags, setIsManagingFlags] = useState(false);
    const [isEditingFlag, setIsEditingFlag] = useState(-1);

    const [flagName, setFlagName] = useState('');


    useEffect(() => {
        if (isEditingFlag >= 0) {
            setFlagName(externals.flags[isEditingFlag].name);
        }
    }, [isEditingFlag])

    let flags_overview = [];
    if (externals?.flags?.length === 0) {
        flags_overview = <p style={{
            fontWeight: '300'
        }}>No Flags</p>;
    } else {
        for (let k = 0; k < externals?.flags?.length; ++k) {
            flags_overview.push(
                <div style={{
                    fontWeight: '300',
                    padding: '5px 0',
                    border: '1pt #faf3dd solid',
                    borderTop: k !== 0 ? '0pt' : '1pt #faf3dd solid'
                }}>
                    <a className='panel-button' style={{
                        marginLeft: '2vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        setIsEditingFlag(k);
                        setModalTitle('Edit Flag');
                    }}>Edit</a>
                    <a className='panel-button' style={{
                        marginLeft: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        let new_externals = { ...externals };
                        new_externals.flags.splice(k, 1);
                        setExternals(new_externals);
                    }}>Delete</a>
                    <span style={{ marginLeft: 10 }}>
                        {externals.flags[k].name}
                    </span>
                </div>
            );
        }
    }

    let flag_overview = [];
    if (isEditingFlag !== false) {
        for (let k = 0; k < externals?.flags[isEditingFlag]?.values?.length; ++k) {
            flag_overview.push(
                <>
                    <div style={{
                        width: '90%',
                        marginLeft: '5%',
                        fontWeight: '300',
                        padding: '1vmax 0',
                        border: '1pt #faf3dd solid',
                        borderTop: k !== 0 ? '0pt' : '1pt #faf3dd solid',
                        backgroundColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#faf3dd' : '#000',
                        color: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd'
                    }}>
                        <input type='color' className='panel-color-input' style={{
                            width: '1.6vmax',
                            marginRight: '1vmax'
                        }} onChange={(e) => {
                            let new_externals = { ...externals };
                            new_externals.flags[isEditingFlag].colors[k] = e.target.value;
                            setExternals(new_externals);
                        }} value={externals.flags[isEditingFlag].colors[k]} />
                        <input className='panel-input' value={externals.flags[isEditingFlag].values[k]} style={{
                            fontWeight: parseInt(externals.flags[isEditingFlag].value) === k ? '400' : '300',
                            padding: '0.5vmax',
                            backgroundColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#faf3dd' : '#000',
                            color: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd',
                            borderColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd'
                        }} onChange={(e) => {
                            let new_externals = { ...externals };
                            new_externals.flags[isEditingFlag].values[k] = e.target.value;
                            setExternals(new_externals);
                        }}></input>
                        <a className='panel-button' style={{
                            fontWeight: parseInt(externals.flags[isEditingFlag].value) === k ? '400' : '300',
                            marginLeft: '1vmax',
                            backgroundColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#faf3dd' : '#000',
                            color: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd',
                            borderColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd'
                        }} onClick={(e) => {
                            e.preventDefault();
                            let new_externals = { ...externals };
                            new_externals.flags[isEditingFlag].value = k;
                            setExternals(new_externals);
                        }}>Set To Default</a>
                        <a className='panel-button' style={{
                            fontWeight: parseInt(externals.flags[isEditingFlag].value) === k ? '400' : '300',
                            marginLeft: '1vmax',
                            backgroundColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#faf3dd' : '#000',
                            color: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd',
                            borderColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd'
                        }} onClick={(e) => {
                            e.preventDefault();
                            if (externals.flags[isEditingFlag]?.values?.length > 1) {
                                let new_externals = { ...externals };
                                new_externals.flags[isEditingFlag].values.splice(k, 1);
                                new_externals.flags[isEditingFlag].colors.splice(k, 1);
                                new_externals.flags[isEditingFlag].value = 0;
                                setExternals(new_externals);
                            }
                        }}>Delete</a>
                    </div>
                </>
            );
        }
    }

    const external_footer = [
        <Button type="primary" danger onClick={(e) => { setView(''); }}>
            Exit
        </Button>
    ];

    const flag_manager_footer =  [
        <Button type="primary" onClick={(e) => {
            e.preventDefault();
            let new_externals = {
                ...externals
            };
            if (new_externals["flags"] == undefined || new_externals["flags"] == null) {
                new_externals["flags"] = [];
            }
            new_externals.flags.push({
                name: 'New Flag ' + (new_externals.flags.length + 1),
                values: ['Incomplete', 'In Progress', 'Complete'],
                colors: ['#E74C3C', '#F1C40F', '#2ECC71'],
                value: 0
            });
            setExternals(new_externals);
        }}>
            Add Flag
        </Button>,

        <Button type="primary" danger onClick={(e) => {
            setModalTitle('Externals');
        }}>
            Exit
        </Button>

    ];

    const edit_flag_footer = [
        <Button type="primary" onClick={(e) => {
            e.preventDefault();
            let new_externals = { ...externals };
            new_externals.flags[isEditingFlag].values.push('New State');
            new_externals.flags[isEditingFlag].colors.push('#ffffff');
            setExternals(new_externals);
        }}>
            Add State
        </Button>,

        <Button type="primary" danger onClick={(e) => {
            setModalTitle('Flag Manager');
            let new_externals = { ...externals };
            if (new_externals.flags[isEditingFlag].name == '') {
                new_externals.flags[isEditingFlag].name = flagName;
                setExternals(new_externals);
            }

        }}>
            Exit
        </Button>
    ];

    return <>
        <AntdModal title={modalTitle} visible={true} onOk={() => {
            setView('');
        }} onCancel={() => {
            setView('');
        }}
            footer={modalTitle == 'External' ? external_footer : modalTitle == "Flag Manager" ? flag_manager_footer : modalTitle == "Edit Flag" ? edit_flag_footer : []}
        >
            {
                modalTitle == 'Externals' ? (
                    <div style={{ overflowY: 'auto', overflowX: 'hidden', height: '70%' }}>
                        <a className='panel-button' onClick={(e) => {
                            e.preventDefault();
                            setModalTitle('Flag Manager');
                            setIsManagingFlags(true);
                        }} style={{
                            marginTop: '2vmax'
                        }}>Manage Flags</a>
                    </div>
                ) : null
            }
            {
                modalTitle == 'Flag Manager' ? (
                    <div>
                        {flags_overview}
                    </div>
                ) : null
            }
            {
                modalTitle == 'Edit Flag' ? (
                    <div>
                        <div style={{
                            width: '90%',
                            marginLeft: '5%',
                            border: '1px solid #000',
                            padding: '5px'
                        }}>
                            {
                                isEditingFlag >= 0 ? (
                                    <>
                                        Flag Name: <input className='panel-input' value={externals?.flags[isEditingFlag]?.name} style={{
                                            fontSize: '1.25vmax',
                                            // padding: '0.75vmax',
                                            marginLeft: '1vmax',
                                            fontWeight: '300',
                                            width: '80%',
                                            fontSize: 12,
                                            padding: 0
                                        }} onChange={(e) => {
                                            let new_externals = { ...externals };
                                            new_externals.flags[isEditingFlag].name = e.target.value;
                                            setExternals(new_externals);
                                        }} />
                                    </>
                                ) : null
                            }

                        </div>
                        <br />

                        {flag_overview}
                    </div>
                ) : null

            }
        </AntdModal>
    </>;

}