import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { connect } from "react-redux";

import Boards from "../../components/boards";
import { GetFlags, AllPriority, AllMembers, AllCoOwners } from "../../redux/actions/boards";

const BoardsPage = (props) => {	
	useEffect(() => {	
		props.dispatch(AllPriority());	
		props.dispatch(GetFlags());
		props.dispatch(AllMembers());
		props.dispatch(AllCoOwners());
    }, []);

	return (
		<Container fluid={true}>
			<Boards />
		</Container>
	);
};

export default connect(null, null)(BoardsPage);
