import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/custom.css';
import App from "./components/app";
// import 'react-perfect-scrollbar/dist/css/styles.css';
import "./index.css";
// Components
import AfterLogin from './screen/after-login';
import BoardList from './screen/boardlist';
import BoardsPage from './screen/boards';
import BoardsV2Page from './screen/boards-v2';
import JobSetupPage from './screen/jobs/index';
import JobsPage from './screen/jobs/jobs';
import SPC from "./screen/spc";
import SPCGraph from "./screen/spc/spc-graph";

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import Login from './screen/Signin';
import ActivityReport from './screen/activity_report';
import ArchivedListReport from './screen/archived_list_report';
import GanttChartPage from './screen/gantt-chart/index';
import ItemGanttChartPage from './screen/gantt-chart/item';
import ReportsPage from './screen/reports/index';
import JobsReportsPage from './screen/reports/jobs';

import TaskDetail from './screen/task-detail';

import ChangeBranch from './screen/Signin/change_branch';
import ChangePassword from './screen/Signin/change_password';

import FormEmailNotificationm from './components/forms/email_notification';
import FormsPage from './screen/forms';
import QRScaner from './screen/forms/QRScaner';
import CreateFormPage from './screen/forms/create-form';
import CreateRecordPage from './screen/forms/create-record';
import RecordsPage from './screen/forms/records';
import PermissionDenied from './screen/permission-denied';



const Root = (props) => {
	return (
		<div style={{ height: '100%' }}>
			<React.StrictMode>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<BrowserRouter basename={"/"}>
							<Switch>
								<Route exact path={`/`} component={Login} />
								<App>
									<Route
										path={`/permission-denied`}
										component={PermissionDenied}
									/>
									<Route
										path={`/forms/:form_id/create/alert`}
										component={FormEmailNotificationm}
									/>

									<Route exact path={`/qr-scan`} component={QRScaner} />

									<Route
										path={`/loading`}
										component={AfterLogin}
									/>
									<Route
										path={`/change-branch`}
										component={ChangeBranch}
									/>
									<Route
										path={`/change-password`}
										component={ChangePassword}
									/>

									<Route
										path={`/graph/spc/:spc_id`}
										component={SPCGraph}
									/>
									<Route
										path={`/spc`}
										component={SPC}
									/>
									<Route
										exact
										path={`/forms`}
										component={FormsPage}
									/>
									<Route
										exact
										path={`/forms/:formId/records`}
										component={RecordsPage}
									/>
									<Route
										exact
										path={`/forms/:formId/records/create`}
										component={CreateRecordPage}
									/>
									<Route
										exact
										path={`/forms/:formId/records/:recordId/change`}
										component={CreateRecordPage}
									/>
									<Route
										exact
										path={`/forms/create`}
										component={CreateFormPage}
									/>
									<Route
										exact
										path={`/forms/:formId/create`}
										component={CreateFormPage}
									/>


									<Route
										exact
										path={`/jobs/:jobSetupId`}
										component={JobsPage}
									/>

									<Route
										exact
										path={`/jobs`}
										component={JobSetupPage}
									/>




									<Route
										exact
										path={`/boards`}
										component={BoardsPage}
									/>
									<Route
										exact
										path={`/boards-v2`}
										component={BoardsV2Page}
									/>
									<Route
										exact
										path={`/board/:board_id`}
										component={BoardList}
									/>
									<Route
										exact
										path={`/reports`}
										component={ReportsPage}
									/>
									<Route
										exact
										path={`/reports/jobs`}
										component={JobsReportsPage}
									/>

									<Route
										path={`/timeline`}
										component={GanttChartPage}
									/>
									<Route
										path={`/item/timeline`}
										component={ItemGanttChartPage}
									/>
									<Route
										path={`/task-detail/:board_id/:list_id/:item_id`}
										component={TaskDetail}
									/>
									<Route
										path={`/item/cadence/report`}
										component={ActivityReport}
									/>
									<Route
										path={`/archived/list/report`}
										component={ArchivedListReport}
									/>

									{/* <Route
										path={`/dnd`}
										component={DND}
									/> */}
								</App>
							</Switch>
						</BrowserRouter>
					</PersistGate>
				</Provider>
			</React.StrictMode>

		</div>

	);
};

ReactDOM.render(<Root />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
