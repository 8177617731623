import React, { useState } from "react";
import { Comment, Avatar, Form, Button, List, Input } from 'antd';
import { Modal, message, Badge } from "antd";
import { connect } from 'react-redux';
import { CreateComments, DeleteComment, UpdateComment } from "../../redux/actions/boards";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import MenLogo from "../../assets/images/men.png";
import CommonModal from "../common-modal";
const { TextArea } = Input
const key = 'updatable';
const { confirm } = Modal;



const Comments = (props) => {
    const [commentVal, setCommentVal] = useState("");
    const [isSubmit, setIsSubmit] = useState(false)
    const [editComment, setEditComment] = useState(false)
    const [comment, setComment] = useState(-1)

    const commentSubmit = async (event) => {
        setIsSubmit(true)
        event.preventDefault();
        if (commentVal == "" || commentVal == null)
            return;
        const data = {
            item: props?.item?.id,
            body: commentVal
        }
        props.dispatch(CreateComments(data)).then(res => {
            setCommentVal("")
            setIsSubmit(false)
        })
    };

    const AvtarShow = (name, object_type) => {
        return (
            <Badge dot={object_type === "activity" ? true : false}>
                <Avatar size="medium" gap={2}>
                    {name}
                </Avatar>
            </Badge>
        )
    }

    const showDeleteConfirm = (comment) => {
        confirm({
            title: `Are you sure delete this comment?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                message.loading({ content: 'Loading...', key });
                props.dispatch(DeleteComment(comment.id))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const EditComment = (item) => {
        setEditComment(true)
        setComment(item)
    }

    const CommentList = ({ comments }) => (
        <List
            className="comment-list"
            dataSource={comments}
            itemLayout="horizontal"
            renderItem={item => {
                let name = item.author?.first_name.substring(0, 1) + item.author?.last_name.substring(0, 1)
                return (
                    <li>
                        <Comment
                            author={`${item.author?.first_name} ${item.author?.last_name}`}
                            avatar={
                                <Avatar size="medium" gap={2}>
                                    {name}
                                </Avatar>
                                // AvtarShow(`${name}`, item.object_type)
                            }
                            content={
                                <div className={item.object_type}>{item.body}</div>
                            }
                            datetime={new Date(item.created_at).toLocaleString()}
                            actions={
                                item.object_type == 'comment' && item.author.id == props.user.id ? [
                                    <span key="comment-list-reply-to-0" onClick={() => EditComment(item)}>Edit</span>,
                                    <span key="comment-list-reply-to-0" onClick={() => showDeleteConfirm(item)}>Delete</span>
                                ] : []
                            }
                        />
                    </li>
                )
            }}
        />
    );

    const updateComment = (value) => {
        const data = {
            item: props?.item?.id,
            body: value.title,
        }
        props.dispatch(UpdateComment(comment.id, data)).then(res => {
            setEditComment(false)
        })
    }





    return (
        <>
            <Comment
                avatar={<Avatar>{props?.user?.first_name.substring(0, 1) + ' ' + props?.user?.last_name.substring(0, 1)}</Avatar>}
                content={
                    <>
                        <div>
                            <TextArea rows={4} placeholder="Write a comment" value={commentVal} onChange={(e) => setCommentVal(e.target.value)} />
                            <Button htmlType="submit" style={{ float: 'right', marginTop: 5 }} loading={isSubmit} onClick={commentSubmit} type="primary">
                                Add Comment
                            </Button>
                        </div>
                        {/* <Form.Item>
                            <TextArea rows={4} placeholder="Write a comment"  value={commentVal} onChange={(e) => setCommentVal(e.target.value)} />
                        </Form.Item> */}
                        <Form.Item>
                            {/* <TextArea rows={4} placeholder="Write a comment"  value={commentVal} onChange={(e) => setCommentVal(e.target.value)} />
                            <Button htmlType="submit" loading={isSubmit} onClick={commentSubmit} type="primary">
                                Add Comment
                            </Button> */}
                        </Form.Item>
                    </>
                }
            />
            {props?.comments?.length > 0 && <CommentList comments={props.comments} />}
            {editComment ?
                <CommonModal
                    open={editComment}
                    toggle={() => setEditComment(!editComment)}
                    what_for="comment"
                    title={comment.body}
                    onOk={updateComment}
                />
                :
                null
            }
        </>
    );
}

function mapStateToProps(state) {
    const { boards, comments } = state.boards;
    const { user } = state.auth
    return {
        boards,
        comments,
        user
    }
}

export default connect(mapStateToProps)(Comments);