import { Button, Modal, Select } from 'antd';
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import { changeBranch } from '../../redux/actions/auth';

const ChangeBranch = (props) => {
    const history = useHistory();
    const handleCancel = () => {
        // debugger;
        history.goBack();
    }
    const [branches, setBranches] = useState([]);
    const [currentBranch, setcurrentBranch] = useState(null);

    useEffect(() => {
        var decoded = jwt_decode(props.token);
        setBranches(decoded.branches);
        setcurrentBranch(decoded.branch)
    }, [props.token]);

    const handleSubmit = () => {

        props.dispatch(
            changeBranch(props.token, currentBranch)
        )
            .then((res) => {
                history.goBack();
            });
    }


    return (
        <Container fluid={true}>
            <Modal
                width="350px"
                visible={true}
                footer={null}
                onCancel={handleCancel}
                destroyOnClose={true}
            >
                <div style={{ height: '170px' }}>
                    <strong>Select Branch</strong>
                    <br />
                    <Select style={{ width: '100%' }} value={currentBranch} onChange={(value) => {
                        setcurrentBranch(value);
                    }}>
                        {
                            branches?.map((item, index) => {
                                return <Select.Option value={item.id}>{item.name}</Select.Option>
                            })
                        }

                    </Select>
                    <br />
                    <br />
                    <Button style={{ float: 'right' }} onClick={handleSubmit} color='primary'>Submit</Button>
                </div>
            </Modal>
        </Container>
    );
};

function mapStateToProps(state) {
    const { token } = state.auth;
    return {
        token
    }
}

export default connect(mapStateToProps)(ChangeBranch);


