import { JOB_SETUP_LIST, JOB_LIST, SET_MESSAGE } from "../types";

import JobService from "../services/cyclic-job";


export const AllJobSetup = (url, query) => (dispatch) => {
    return JobService.getAllJobSetups(url, query).then(
        (data) => {
            dispatch({
                type: JOB_SETUP_LIST,
                payload: data
            });
            return Promise.resolve()
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, status: error.response.status },
            });

            return Promise.reject();
        }
    );
};

export const SubmitJob = (payload) => (dispatch) => {
    return JobService.submitJob(payload).then(
        (data) => {
            return Promise.resolve()
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, status: error.response.status },
            });

            return Promise.reject();
        }
    );
}


export const AllJobs = (jobSetupId, url) => (dispatch) => {
    return JobService.getAllJobs(jobSetupId, url).then(
        (data) => {
            dispatch({
                type: JOB_LIST,
                payload: data
            });
            return Promise.resolve()
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SET_MESSAGE,
                payload: { message: message, status: error.response.status },
            });

            return Promise.reject();
        }
    );
};