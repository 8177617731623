
import { Rnd } from 'react-rnd';

import process_vars from '../../intermediate/process_vars';
import execute_script from './execute_script';

const boxShadowRadius = 15;

export default function Input({ id, comps, setter, vw, vars, setVars, editable, externals, setExternals }) {

    let boxShadow = "0px 0px 0px #ff4f42";

    if (comps[id].error) {
        boxShadow = `0px 0px ${boxShadowRadius}px #ff4f42`;
    }
    else if (comps[id].hasOwnProperty('warning')) {
        if (comps[id].warning) {
            boxShadow = `0px 0px ${boxShadowRadius}px #d35400`;
        }
    }
    else if (comps[id].disable) {
        boxShadow = `0px 0px ${boxShadowRadius}px #4e6df5`;
    }

    let innerContent = <input style={{
        color: process_vars(comps[id].props.color, vars),
        backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
        width: '100%',
        height: '100%',
        fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
        fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
        textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
        textAlign: process_vars(comps[id].props.alignment, vars),
        border: 'none'
    }} value={process_vars(comps[id].props.value, vars)}
        placeholder={process_vars(comps[id].props.placeholder, vars)} onChange={(e) => {
            if (editable) {
                if (!comps[id].disable) {
                    let new_comps = [...comps];
                    new_comps[id].props.value = e.target.value;
                    setter(new_comps);
                    execute_script(1, id, comps, setter, vars, setVars, externals, setExternals);
                }
            }
        }}></input>;

    try {
        if (comps[id].props.hasOwnProperty('textarea')) {
            if (comps[id].props.textarea) {
                innerContent =
                    <textarea style={{
                        color: process_vars(comps[id].props.color, vars),
                        fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
                        fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
                        textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
                        textAlign: process_vars(comps[id].props.alignment, vars),
                        border: 'none',
                        padding: '1% 1%',
                        marginTop: '0%',
                        height: '100%',
                        width: '100%',
                        resize: 'none',
                        backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
                        outline: 'none',
                        outlineStyle: 'none',
                        boxShadow: 'none',
                        borderColor: 'transparent',
                    }} value={process_vars(comps[id].props.value, vars)} placeholder={process_vars(comps[id].props.placeholder, vars)} onChange={(e) => {
                        if (editable) {
                            if (!comps[id].disable) {
                                let new_comps = [...comps];
                                new_comps[id].props.value = e.target.value;
                                setter(new_comps);
                                execute_script(1, id, comps, setter, vars, setVars, externals, setExternals);
                            }
                        }
                    }}>
                    </textarea>;
            }
        }
    } catch (e) {
        // do nothing
    }

    return (
        <div>
            <Rnd className='comp' style={{
                textAlign: 'center',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: 'hidden',
                fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                    `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                position: 'absolute',
                boxShadow: editable ? boxShadow : 'none',
                transition: 'box-shadow 0.5s'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} enableResizing={false} disableDragging={true}>{innerContent}</Rnd>
        </div>
    );

}