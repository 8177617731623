import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Card } from 'antd';
import { Column } from '@ant-design/plots';
import ReportService from "../../redux/services/reports";


const WhoOwns = (props) => {
    const [data, setData] = useState([]);
    const [colors, setColors] = useState([]);

    useEffect(() => {
        const payload = {
          'filters': props.filters
        };

        ReportService.whoOwnsAsync(payload).then(res=>{
            let x = [];
            res.data.map(el=>{
              if(x.indexOf(el.color)<=-1){
                x.push(el.color);
              }
            });

            setColors(x);
            setData(res.data);
        });
    }, [props.filters]);
    


    const config = {
        data,
        isStack: true,
        xField: 'username',
        yField: 'total',
        seriesField: 'priority',
        colorField: 'color',
        color: colors,
        // columnStyle:{
        //     fill: 'red',
        // },
        label: {
          position: 'middle',
          layout: [
            {
              type: 'interval-adjust-position',
            },
            {
              type: 'interval-hide-overlap',
            },
            {
              type: 'adjust-color',
            },
          ],
        },
      };

    return(
        <Card title="Who Owns">
            <Column {...config}  />
        </Card>
    );
};

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    }
}

export default connect(mapStateToProps)(WhoOwns);