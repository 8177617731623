
import { useState } from "react";
import Modal from "../../utils/Modal";
import { Modal as AntdModal, Button } from 'antd';
import FormService from '../../../../redux/services/forms';

import { getHeight } from "../../misc/layouts";

export default function ViewPages({ comps, setComps, setView, page, setPage, pages, setPages }) {

    const [del, setDel] = useState(false);

    let page_list = [];
    for (let k = 0; k < pages.length; k++) {
        page_list.push(
            <div style={{
                padding: '0.5vmax',
                textAlign: 'justify',
                border: '1px #FAF3DD solid',
                borderTop: k !== 0 ? '' : '1px #FAF3DD solid',
                margin: '2vmax 2vmax 0 2vmax',
                marginTop: k !== 0 ? '' : '2vmax',
                backgroundColor: page === k ? '#FAF3DD' : '#000'
            }}>
                <p style={{ color: '#fff' }}>{pages[k].page_id}</p>
                <i className="fa-solid fa-scroll" style={{
                    color: page === k ? '#000' : '#FAF3DD',
                    fontSize: '1.25vmax',
                    margin: '0 1vmax 0 0.5vmax'
                }}></i>
                <p className="panel-label" style={{
                    fontWeight: '300',
                    fontSize: '1.25vmax',
                    color: page === k ? '#000' : '#FAF3DD'
                }}>Page {k + 1}</p>
                <i className="fa-solid fa-eye pointy" style={{
                    color: page === k ? '#000' : '#FAF3DD',
                    fontSize: '1.25vmax',
                    margin: '0 1.75vmax 0 30%'
                }} onClick={() => {
                    setPage(k);
                }}></i>
                <i className="fa-solid fa-angles-down pointy" style={{
                    color: page === k ? '#000' : '#FAF3DD',
                    fontSize: '1.25vmax',
                    margin: '0 1vmax 0 0vmax'
                }} onClick={() => {
                    if (k !== pages.length - 1) {
                        let new_comps = [...comps];
                        for (let j = 0; j < new_comps.length; ++j) {
                            if (new_comps[j].page === k) {
                                new_comps[j].page += 1;
                            } else if (new_comps[j].page === k + 1) {
                                new_comps[j].page -= 1;
                            }
                        } setComps(new_comps);
                        let current = pages[k];
                        let new_pages = [...pages];
                        new_pages[k] = new_pages[k + 1];
                        new_pages[k + 1] = current;
                        setPages(new_pages);
                        setPage(k + 1);
                    }
                }}></i>


                <i className="fa-solid fa-angles-up pointy" style={{
                    color: page === k ? '#000' : '#FAF3DD',
                    fontSize: '1.25vmax',
                    margin: '0 0vmax 0 0vmax'
                }} onClick={() => {
                    if (k !== 0) {
                        let new_comps = [...comps];
                        for (let j = 0; j < new_comps.length; ++j) {
                            if (new_comps[j].page === k) {
                                new_comps[j].page -= 1;
                            } else if (new_comps[j].page === k - 1) {
                                new_comps[j].page += 1;
                            }
                        } setComps(new_comps);
                        let current = pages[k];
                        let new_pages = [...pages];
                        new_pages[k] = new_pages[k - 1];
                        new_pages[k - 1] = current;
                        setPages(new_pages);
                        setPage(k - 1);
                    }
                }}></i>

                <i className="fa-solid fa-trash pointy" style={{
                    color: page === k ? '#000' : '#FAF3DD',
                    fontSize: '1.25vmax',
                    margin: '0 0vmax 0 2vmax'
                }} onClick={() => {
                    if (pages.length > 1) {
                        // setDel(k);

                        if (window.confirm('Are you sure want to delete this page?')) {
                            let new_comps = [...comps];
                            let newer_comps = [];
                            for (let j = 0; j < new_comps.length; ++j) {
                                if (new_comps[j].page < k) {
                                    newer_comps.push(new_comps[j]);
                                } else if (new_comps[j].page > k) {
                                    new_comps[j].page -= 1;
                                    newer_comps.push(new_comps[j]);
                                }
                            } setComps(newer_comps);
                            let new_pages = [...pages];
                            new_pages.splice(k, 1);
                            if (page > k) {
                                setPage(page - 1);
                            } else if (k === pages.length - 1 && page === k) {
                                setPage(k - 1);
                            }
                            setPages(new_pages);
                            // setDel(false);
                        }
                    }
                }}></i>
            </div>
        );
    }

    return (
        <AntdModal title="View Pages" visible={true} onOk={() => {
            setView('');
        }} onCancel={() => {
            setView('');
        }}
            footer={[
                <Button type="primary" onClick={(e) => {
                    e.preventDefault();
                    let new_pages = [...pages];
                    new_pages.splice(page, 0, {
                        page_id: FormService.getGUID(),
                        height: getHeight('A4', 0.7),
                        visibleTo: 'all'
                    });
                    setPages(new_pages);

                    let new_comps = [...comps];
                    for (let k = 0; k < new_comps.length; ++k) {
                        if (new_comps[k].page > page) {
                            new_comps[k].page += 1;
                        }
                    }
                    setComps(new_comps);
                    setPage(page + 1);
                }}>
                    Add Page
                </Button>
            ]}
        >


            <div style={{
                height: '27.5vh',
                overflowX: 'hidden',
                overflowY: 'auto'
            }}>
                {page_list}
            </div>


            <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                display: 'none',
                backgroundColor: '#000000'
            }}>
                <a href='' className='panel-button' style={{
                    margin: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_pages = [...pages];
                    new_pages.splice(page, 0, {
                        page_id: FormService.getGUID(),
                        height: getHeight('A4', 0.7),
                        visibleTo: 'all'
                    });
                    setPages(new_pages);

                    let new_comps = [...comps];
                    for (let k = 0; k < new_comps.length; ++k) {
                        if (new_comps[k].page > page) {
                            new_comps[k].page += 1;
                        }
                    }
                    setComps(new_comps);
                    setPage(page + 1);
                }}>Add Page</a>


                {/* <a href='' className='panel-button' style={{
                        marginBottom: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        setView('');
                    }}>Ok</a> */}


            </div>
        </AntdModal>
    );

}