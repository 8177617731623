import { Checkbox, Form, Input, Modal, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { CopyMove, GetBoardList } from '../../../redux/actions/boards';
import { GetDepartments, GetLineNumber } from "../../../redux/actions/spc";



const { Option } = Select

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((resp) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                let newOptions = [];
                resp.results.map(spc => {
                    newOptions.push(
                        {
                            label: `${spc.part_number} (${spc.part_name})`,
                            value: spc.part_number,
                        }
                    )
                })


                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            showSearch={true}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
        // options={options}
        >
            {
                options.map((item, index) => (
                    <Option key={`part_${index}`} value={item.value}>{item.label}</Option>
                ))
            }

        </Select>
    );
} // Usage of DebounceSelect


const CopyFPPOCard = (props) => {
    const history = useHistory();
    const [copyMoveForm] = Form.useForm(null)
    const [lists, setLists] = useState([])
    const [departments, setDepartments] = useState([])
    const [subDepartments, setSubDepartments] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [selectedSubDepartment, setSelectedSubDepartment] = useState(null)
    const [lines, setLines] = useState([])
    const { board_id } = useParams()
    const [value, setValue] = React.useState(null);


    const onOk = () => {
        copyMoveForm
            .validateFields()
            .then(values => {
                copyMoveForm.resetFields();
                values["action"] = props.type;
                values["from_list"] = props.list.id;
                values["item_name"] = `${values?.part_number.label}`;
                values["department"] = selectedDepartment;
                values["sub_department"] = selectedSubDepartment;
                props.dispatch(CopyMove(props.itemId, values)).then(res => {
                    history.push(`/task-detail/${props.board.id}/${values.list_id}/${res.id}`);
                });
                props.toggle()
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    const changeBoard = (board_id) => {
        props.dispatch(GetBoardList(board_id)).then(res => {
            setLists(res.lists)
        })
    }

    const changePartNumber = (part_number) => {
        props.dispatch(GetLineNumber(part_number)).then(res => {
            setLines(res)
        })
    }

    useEffect(() => {
        props.dispatch(GetDepartments()).then(res => {
            setDepartments(res)
        })
    }, [])

    return (
        <Modal title="Copy FPPO Card" visible={props.open} onOk={onOk} onCancel={props.toggle}>
            <Form
                form={copyMoveForm}
                labelCol={{ span: 0 }}
                layout={"horizontal"}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                initialValues={
                    {
                        'keep_comments': props?.board?.board_type == 'template',
                        'keep_checklist': props?.board?.board_type == 'template',
                        'keep_attachements': props?.board?.board_type == 'template',
                        'item_name': props?.item?.title
                    }
                }
            >
                <Form.Item style={{ width: "100%" }}>
                    <Select onChange={(board_id) => changeBoard(board_id)} placeholder="Select Board">
                        {props.boards.map(board => {
                            if (board.board_type == 'board' || board.board_type == 'spc board') {
                                if (props.type == 'Copy') {
                                    return (
                                        <Option key={`board_${board.id}`} value={board.id}>{board.title}</Option>
                                    )
                                }
                                else {
                                    if (board.id !== parseInt(board_id)) {
                                        return (
                                            <Option key={`board_${board.id}`} value={board.id}>{board.title}</Option>
                                        )
                                    }
                                }
                            }
                        })}
                    </Select>
                </Form.Item>
                <Form.Item style={{ width: "100%" }} name="list_id" rules={[{ required: true, message: 'List is required!' }]}>
                    <Select placeholder="Select List">
                        {lists.map(list => {
                            return (
                                <Option key={`list_${list.id}`} value={list.id}>{list.title}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="Part Number" name="part_number" rules={[{ required: true, message: 'Part Number is required!' }]}>

                    {/* <DebounceSelect
                        value={value}
                        placeholder="Enter Part Number"
                        fetchOptions={SPCService.allSPCParts}
                        onChange={(newValue) => {
                            console.log('newValue > ', newValue);
                            setValue(newValue);
                            changePartNumber(newValue.value);
                        }}
                    /> */}

                    <Input placeholder="Enter Part Number" />
                </Form.Item>

                <Form.Item label="Machine Number" name="machine_number" rules={[{ required: true, message: 'Machine Number is required!' }]}>
                    <Input placeholder="Enter Machine Number" />
                </Form.Item>

                <Form.Item label="Line Number" name="line_number" rules={[{ required: true, message: 'Line Number is required!' }]}>
                    <Select placeholder="Select Line Number">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]?.map(line => {
                            return (
                                <Option key={`line_${line}`} value={line}>{line}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="Department" name="department" rules={[{ required: true, message: 'Department is required!' }]}>
                    <Select placeholder="Select Department" onChange={(val, e) => {
                        setSelectedDepartment(e.department);
                        setSubDepartments(e.department?.sub_departments);
                        setTimeout(() => {
                            setSelectedSubDepartment(e.department?.sub_departments[0]);
                        }, 300)
                    }}>
                        {departments?.map(department => {
                            return (
                                <Option key={`department_${department.id}`} department={department} value={department.id}>{department.name}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="Sub Department" name="sub_department" rules={[{ required: true, message: 'Sub Department is required!' }]}>
                    <Select placeholder="Select Sub Department" value={selectedSubDepartment?.id} onChange={(val, e) => {
                        setSelectedSubDepartment(e.sub_department);
                    }}>
                        {subDepartments?.map((sub_department, index) => {
                            return (
                                <Option key={`sub_department_${index}`} sub_department={sub_department}>{sub_department.name}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
                <Form.Item key={"keep_comments"} name="keep_comments" valuePropName='checked'>
                    <Checkbox disabled={props?.board?.board_type == 'template'}>Keep Comments:</Checkbox>
                </Form.Item>
                <Form.Item key={"keep_checklist"} name="keep_checklist" valuePropName='checked'>
                    <Checkbox disabled={props?.board?.board_type == 'template'}>Keep Checklist: </Checkbox>
                </Form.Item>
                <Form.Item key={"keep_attachements"} name="keep_attachements" valuePropName='checked'>
                    <Checkbox disabled={props?.board?.board_type == 'template'}>Keep Attachments: </Checkbox>
                </Form.Item>

            </Form>
        </Modal >
    );
};

function mapStateToProps(state) {
    const { boards, board } = state.boards;
    return { boards, board }
}

export default connect(mapStateToProps)(CopyFPPOCard);