import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Checkbox, List, Menu, Modal, Popover, Typography, message } from "antd";
import React, { useState } from "react";
import { connect } from 'react-redux';
import { DeleteChecklistItem, UpdateChecklistItem } from "../../redux/actions/boards";
import { CHECKLIST } from "../../redux/types";
import CreateCheckListItem from './create_checklist_item';

const { Text } = Typography;
const { confirm } = Modal;
const key = 'updatable';

const CheckListItems = (props) => {
    const [checklistItemModal, setChecklistItemModal] = useState(false)
    const { checklistItem, checklist, chkItems } = props;


    const showDeleteConfirm = (id, delete_for) => {
        confirm({
            title: `Are you sure delete this ${delete_for}?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                message.loading({ content: 'Loading...', key });
                props.dispatch(DeleteChecklistItem(id))
                    .then(res => {
                        let checklists = [...props.checklists]
                        let checklistIndex = checklists.findIndex(el => el.id === checklist.id)
                        let checklistItemIndex = checklists[checklistIndex].items.findIndex(el => el.id === checklistItem.id)
                        checklists[checklistIndex].items.splice(checklistItemIndex, 1)
                        props.dispatch({
                            type: CHECKLIST,
                            payload: checklists
                        });
                    }
                    )
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const checklistItemPopover = (checklistItem) => {
        return (
            <Menu className="item_menu" key={`checklist_item_menu_${checklistItem.id}`}>
                <Menu.Item
                    icon={<EditOutlined />}
                    onClick={(e) => {
                        e.domEvent.stopPropagation();
                        setChecklistItemModal(true);
                    }}
                >
                    Edit Item
                </Menu.Item>
                <Menu.Item
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                        e.domEvent.stopPropagation();
                        showDeleteConfirm(checklistItem.id, "Checklist Item")
                    }}
                >
                    Delete Item
                </Menu.Item>
            </Menu>
        )
    }

    const handleItemClick = (e, checklistId, checklistItem) => {
        if (checklistItem.assigned_to.id == props.user.id || props.user.groups.indexOf('Branch Admin') > -1 || checklistItem.created_by == props.user.id || props?.board?.is_co_owner || props?.board?.owner == props.user.id) {
            e.stopPropagation()
            let update_data = {
                checklist: checklistId,
                completed: e.target.checked,
                body: checklistItem.body
            }
            props.dispatch(UpdateChecklistItem(checklistItem.id, update_data))
        }
    }

    return (
        <>
            {checklistItemModal ?
                <CreateCheckListItem
                    checkListForm={checklistItemModal}
                    toggle={() => setChecklistItemModal(!checklistItemModal)}
                    checklistId={checklist.id}
                    checklistItemId={checklistItem.id}
                    items={chkItems}
                />
                : null}
            <div key={`checklist_item_${checklistItem.id}`} className="acordian_content" style={{ borderLeft: '7px solid #' + checklistItem?.flag?.color }}>
                <List.Item style={{ alignItems: 'start' }} actions={
                    (checklistItem.created_by == props.user.id || props.user.groups.indexOf('Branch Admin') > -1 || checklistItem.created_by == props.board.owner || props.board.co_owner) ? [
                        <Popover
                            key={`checklist_menu_popover_${checklistItem.id}`}
                            content={checklistItemPopover(checklistItem)}>
                            <Button type="default" icon={<MoreOutlined />} style={{ position: 'absolute', top: 5, right: 5, fontWeight: 'bold' }}></Button>
                        </Popover>
                    ] : []}>
                    <div style={{ position: 'relative' }}>
                        <Checkbox checked={checklistItem.completed} onChange={(e) => handleItemClick(e, checklist.id, checklistItem)} style={{ paddingRight: 10 }} />

                        <span style={checklistItem.completed ? { textDecoration: 'line-through' } : {}}>
                            {checklistItem.body}
                        </span>
                        <br />
                        <Text strong>Dates : </Text>
                        <Text>{checklistItem.start_date} - {checklistItem.due_date}</Text>
                        <br />
                        <Text strong> Assign To: </Text>
                        <Text>{checklistItem?.assigned_to?.first_name} {checklistItem?.assigned_to?.last_name}</Text>
                        {
                            checklistItem?.depend_on &&
                            <div>
                                <Text strong> Depends on: </Text>
                                <Text>{checklistItem?.depend_on?.body}</Text>
                            </div>
                        }
                    </div>
                </List.Item>
            </div>
        </>

    );
}

function mapStateToProps(state) {
    const { checklists, board } = state.boards;
    const { user } = state.auth;
    return {
        checklists,
        user
        // board
    }
}


export default connect(mapStateToProps)(CheckListItems);