
import { useEffect, useState } from "react";

const commandColor = '#74b9ff';

// Has params: props.id, props.comps, props.setComps, props.indices, props.setIsScriptRendered, props.setScriptErrorsExist
export default function NumberExpressionCommand (props) {

   const [ evaluatedErrors, setEvaluatedErrors ] = useState(false);
   const [ color, setColor ] = useState(commandColor);

   let value = "Loading...";
   let comment = -1;
   let thisObj = props.comps[props.id].script[props.indices[0]];
   for (let k = 0; k < props.indices.length - 1; ++k) {
       thisObj = thisObj.children[props.indices[k + 1]];
   }
   
   try {
       value = thisObj.value;
       comment = thisObj.comment;
   } catch (e) {

   }

   if (!evaluatedErrors) {
       if (!isNaN(value) && /\S/.test(value)) {
           setColor(commandColor);
           setEvaluatedErrors(true);
       } else {
           setColor('#FC427B');
           setEvaluatedErrors(true);
       }
   }

    useEffect(() => {
        if (!isNaN(value) && /\S/.test(value)) {
            setColor(commandColor);
            setEvaluatedErrors(true);
        } else {
            setColor('#FC427B');
            setEvaluatedErrors(true);
        }
    })
   

   return <>
       <div style={{
           marginBottom: '2vmax',
           overflow: 'auto',
           display: 'inline-block',
           marginRight: '4vmax',
           verticalAlign: 'top'
       }}>
           <div style={{
               padding: '1vmax',
               backgroundColor: color,
               color: '#000',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontFamily: "Work Sans, Lato, sans-serif",
               fontWeight: '500'
           }}>
               NUMBER
           </div>
           <a className='panel-button' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontFamily: "Work Sans, Lato, sans-serif",
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax'
           }} onClick={(e) => {
               e.preventDefault();
               let new_comps = [...props.comps];
               let thisObj = new_comps[props.id].script[props.indices[0]];
               for (let k = 0; k < props.indices.length - 2; ++k) {
                   thisObj = thisObj.children[props.indices[k + 1]];
               } thisObj.children.splice(props.indices[props.indices.length - 1], 1);
               props.setComps(new_comps);
               props.setIsScriptRendered(false);
           }}>
               Delete
           </a>
           <i className='fa-solid fa-comment-dots pointy comp' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax',
               backgroundColor: comment === -1 ? "#000" : "#faf3dd",
               color: comment === -1 ? "#faf3dd" : "#000"
           }} onClick={(e) => {
               e.preventDefault();
               let new_comps = [...props.comps];
               let thisObj = new_comps[props.id].script[props.indices[0]];
               for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
               if (thisObj.comment !== -1) {
                    thisObj.comment = -1;
               } else {
                    thisObj.comment = "Comments";
               }
               props.setComps(new_comps);
               props.setIsScriptRendered(false);
           }}></i>
           <div style={{
               padding: '2vmax 0',
               borderLeft: `3pt solid ${color}`,
               borderBottom: `1pt dashed ${color}`,
               paddingLeft: '2vmax',
               fontFamily: "Work Sans, Lato, sans-serif",
               fontWeight: '500',
               paddingRight: '2vmax',
               fontSize: '1.15vmax'
           }} className='code-block'>
               RETURNS <input style={{
                            fontWeight: '300',
                            marginLeft: '0.5vmax',
                            padding: '0.5vmax',
                            minWidth: '10vmax'
                        }} className='panel-input' value={value} onChange={(e) => {
                            let new_comps = [...props.comps];
                            let thisObj = new_comps[props.id].script[props.indices[0]];
                            for (let k = 0; k < props.indices.length - 1; ++k) {
                                thisObj = thisObj.children[props.indices[k + 1]];
                            } thisObj.value = e.target.value;
                            props.setComps(new_comps);
                            props.setIsScriptRendered(false);
                        }}></input>
                    <br/>
                        {
                    comment === -1 ? <></> : <>
                        <input className='panel-input-reverse' style={{
                            width: '100%',
                            height: '2vmax',
                            marginTop: '1vmax'
                        }} value={comment} onChange={(e) => {
                            let new_comps = [...props.comps];
                            let thisObj = new_comps[props.id].script[props.indices[0]];
                            for (let k = 0; k < props.indices.length - 1; ++k) {
                                thisObj = thisObj.children[props.indices[k + 1]];
                            }
                            thisObj.comment = e.target.value;
                            props.setComps(new_comps);
                            props.setIsScriptRendered(false);
                        }}></input>
                    </>
                }
           </div>
       </div>
   </>;

}