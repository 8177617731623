import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  UploadOutlined
} from '@ant-design/icons';
import { Avatar, Button, Col, List, Row, Upload } from 'antd';
import axios from "axios";
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DeleteAttachment, UpdateAttachement } from '../../../redux/actions/boards';
import authHeader from "../../../redux/services/auth-header";
import { managementUrl } from "../../../redux/services/base";
import { LIST_ITEM } from "../../../redux/types";
import CommonModal from '../../common-modal';



const Attachment = (props) => {
  const [fileName, setFileName] = useState(null);
  const [updateAttachModal, setUpdateAttachModal] = useState(false)
  const [attachment, setAttachment] = useState(null)
  const [blobData, setBlobData] = useState(null)

  const uploadProps = {
    action: `${managementUrl}/attachments/`,
    data: { title: fileName, item: props?.item?.id },
    name: 'upload',
    beforeUpload: file => {
      setFileName(file.name);
      return true;
    },
    headers: authHeader()
  };

  const handleChange = info => {
    if (info.file?.status === 'done') {
      let item = JSON.parse(JSON.stringify(props.item));
      let attachments = [...props.item.attachments];
      attachments.splice(0, 0, info.file?.response);
      item.attachments = attachments;
      item.timestamp = new Date().getTime();

      props.dispatch({
        type: LIST_ITEM,
        payload: item
      });
    }
  };

  const deleteAttachment = (e, attachmentId) => {
    e.stopPropagation();
    props.dispatch(DeleteAttachment(attachmentId))
  }

  const updateAttachements = (data) => {
    let update_data = {
      id: attachment.id,
      item: attachment.item,
      title: data.title
    }
    props.dispatch(UpdateAttachement(attachment.id, update_data)).then(res => {
      setUpdateAttachModal(false)
    })
  }

  const downloadAllAttachments = (e) => {
    axios.get(`${managementUrl}/board/export/files?item_id=${props.item.id}`, { headers: authHeader(), responseType: 'blob' }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');

      link.href = downloadUrl;

      link.setAttribute('download', `${props.item.title}_${props.item.id}_${new Date().toLocaleDateString()}.zip`); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();

    });
  }

  return (
    <div>
      <div className="item_detail_title attachement_upload">
        <UploadOutlined />
        <span style={{ fontWeight: 'bold', fontSize: 16, marginRight: 10, marginLeft: 20 }}>Attachments</span> {"   "}
        {
          (props?.item?.created_by?.id == props.user.id || props?.board?.is_co_owner || props?.board?.owner == props.user.id || props.user.groups.indexOf('Branch Admin') > -1) ?
            <Upload {...uploadProps} onChange={handleChange}>
              <div>
                <PlusCircleFilled />
              </div>
            </Upload> : null
        }
        <Button style={{ position: 'absolute', right: 20 }} shape="circle" type='text' icon={<CloudDownloadOutlined />} onClick={downloadAllAttachments} />,

      </div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb-3">
        <Col span={23} offset={1}>
          <List
            bordered
            dataSource={props?.item?.attachments}
            renderItem={attachement => (
              <List.Item actions={
                attachement.created_by == props.user.id || props.user.groups.indexOf('Branch Admin') > -1 || props.item.created_by == props.user.id || props?.board?.is_co_owner || props?.board?.owner == props.user.id ? [
                  <Button shape="circle" type='text' icon={<EditOutlined />} onClick={() => { setUpdateAttachModal(true); setAttachment(attachement) }} />,
                  <Button shape="circle" danger type='text' icon={<DeleteOutlined />} onClick={(e) => deleteAttachment(e, attachement.id)} />
                ] : []}>
                <List.Item.Meta key={`attachment_${attachement.id}`}
                  avatar={
                    <Avatar src={
                      attachement?.thumbnails?.length > 0 ? attachement?.thumbnails[0].photo : attachement?.upload
                    } />
                  }
                  title={<a target={"_blank"} rel="noreferrer" href={attachement.upload}>{attachement.title}</a>}
                />
              </List.Item>
            )}
          >

          </List>
        </Col>
      </Row>
      {
        updateAttachModal ?
          <CommonModal
            open={updateAttachModal}
            toggle={() => setUpdateAttachModal(!updateAttachModal)}
            what_for="Attachment Title"
            title={attachment?.title}
            onOk={updateAttachements} />
          :
          null
      }
    </div>
  )
};

function mapStateToProps(state) {
  const { board } = state.boards;
  const { user } = state.auth;
  return {
    board,
    user
  }
}
export default connect(mapStateToProps)(Attachment);
