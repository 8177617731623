import axios from "axios";
import { spcUrl } from "./base";
import authHeader from "./auth-header";

class SPCService {
  queryStringToJSON(queryString) {
    if (queryString.indexOf('?') > -1) {
      queryString = queryString.split('?')[1];
    }
    var pairs = queryString.split('&');
    var result = {};
    pairs.forEach(function (pair) {
      pair = pair.split('=');
      if (pair[0] != null && pair[0] != "" && pair[0] != undefined) {
        result[pair[0]] = decodeURIComponent(pair[1] || '');
      }
    });
    return result;
  };

  allSPC(url, query) {
    let data = {};

    if (url == "" || url == null || url == undefined) {
      url = `${spcUrl}/spc`;
    }
    else {
      data = this.queryStringToJSON(url);
      let x = data.search;
      if (x != null && x != undefined) {
        x = x.replace(/\+/g, " ")
        data.search = x;
      }
      delete data[""];
    }
    if (query == "clear") {
      data["search"] = "";
    }
    else if (query != null && query != "clear") {
      data["search"] = query;
    }
    if (url.indexOf('?') > 0) {
      url = url.substr(0, url.indexOf('?'));
    }
    url = url + "?all=1&" + new URLSearchParams(data).toString();
    return axios
      .get(url,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allSPCParts(query) {
    let data = {};

    let url = `${spcUrl}/spc/unique/parts`;
    if (query == "clear") {
      data["search"] = "";
    }
    else if (query != null && query != "clear") {
      data["search"] = query;
    }
    if (url.indexOf('?') > 0) {
      url = url.substr(0, url.indexOf('?'));
    }
    url = url + "?" + new URLSearchParams(data).toString();
    return axios
      .get(url,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allSPCLines(query) {
    let data = {};

    let url = `${spcUrl}/spc/unique/parts`;
    if (query == "clear") {
      data["search"] = "";
    }
    else if (query != null && query != "clear") {
      data["search"] = query;
    }
    data["lines"] = 1;
    if (url.indexOf('?') > 0) {
      url = url.substr(0, url.indexOf('?'));
    }
    url = url + "?" + new URLSearchParams(data).toString();
    return axios
      .get(url,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allDepartments() {
    let url = `${spcUrl}/spc/departments`;

    return axios.get(url, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

}




export default new SPCService();