import React, { useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import { Draggable } from 'react-beautiful-dnd';
import { DeleteListItem } from "../../../redux/actions/boards";

import {
    ArrowRightOutlined,
    ClockCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    MoreOutlined
} from '@ant-design/icons';
import { Dropdown, Menu, Modal, message } from 'antd';
import CopyFPPOCard from "./copy-fppo-card";
import CopyMoveTasklist from './copy-move-tasklist';


import { LIST } from "../../../redux/types";
const { confirm } = Modal;
const key = 'updatable';


const Task = (props) => {
    const history = useHistory();
    const [showCopyMoveTaskist, setShowCopyMoveTaskist] = useState(false)
    const [type, setType] = useState("")


    const handleMenuClick = (e, item) => {
        e.domEvent.stopPropagation();
        let event_type = e.key.split('_')[0];
        if (event_type === 'edit') {
            props.onEdit(item, props.list);
        }
    }

    const showDeleteConfirm = () => {
        confirm({
            title: `Are you sure delete this item?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                message.loading({ content: 'Loading...', key });
                props.dispatch(DeleteListItem(props.list.id, props.task.id))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const onTaskClick = (e) => {
        e.stopPropagation();
        props.dispatch({
            type: LIST,
            payload: props.list
        });
        history.push(`/task-detail/${props.board_id}/${props.list_id}/${props.task.id}`);
    }

    const attCopyMove = (open, type) => {
        setShowCopyMoveTaskist(open)
        setType(type)

    }

    const isCardDraggable = () => {
        var draggable = false;

        if (props.list.closed == false) {
            draggable = true;
        }
        else if (props?.board?.board_type != 'template') {
            draggable = true;
        }
        else if (props.list.closed == false) {
            draggable = true;
        }
        else if (props.board.is_co_owner == true || props.board.owner == props.user.id) {
            draggable = true;
        }
        else if (props.user.groups.indexOf('Branch Admin') > -1) {
            draggable = true;
        }
        return draggable;
    }



    return (
        <>
            <Draggable draggableId={`task_${props.task.id}`} index={props.index} isDragDisabled={isCardDraggable} isDragDisabled1={props.list.closed || props?.board?.board_type == 'template' || (props.board.is_co_owner == false && props.board.owner != props.user.id)}>
                {(provided) => (
                    <div className='task'
                        onClick={onTaskClick}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}>

                        <div style={{ padding: 8, borderBottomLeftRadius: 5, borderTopLeftRadius: 5, borderLeft: `5px solid ${props.task.priority?.color}` }}>
                            {
                                (props.task.created_by?.id === props.user.id || props?.board?.is_co_owner || props?.board?.owner === props.user.id || props?.board?.board_type == 'template' || props.user.groups.indexOf('Branch Admin') > -1) ? (
                                    <Dropdown
                                        style={{ position: 'absolute', top: 5, right: 5 }}
                                        overlay={
                                            <Menu className="item_menu" onClick={(e) => { handleMenuClick(e, props.task) }}>
                                                {
                                                    props.list.closed === false && (props.task.created_by?.id === props.user.id || props?.board?.is_co_owner || props.board.owner === props.user.id) ? (
                                                        <Menu.Item key={`edit_${props.task.id}`} icon={<EditOutlined />}>Edit</Menu.Item>
                                                    ) : null
                                                }
                                                <Menu.Item key={`copy_${props.task.id}`} icon={<CopyOutlined />} onClick={() => attCopyMove(true, "Copy")}>Copy</Menu.Item>
                                                {
                                                    props.list.closed === false && props.board.board_type != 'template' ? (
                                                        <Menu.Item key={`move_${props.task.id}`} icon={<ArrowRightOutlined />} onClick={() => attCopyMove(true, "Move")}>Move</Menu.Item>
                                                    ) : null
                                                }
                                                {
                                                    props.list.closed === false && props.board.board_type != 'template' ? (
                                                        <Menu.Item key={`delete_${props.task.id}`} icon={<DeleteOutlined style={{ color: 'red' }} />} onClick={showDeleteConfirm} >Delete</Menu.Item>
                                                    ) : null
                                                }
                                            </Menu>
                                        }>
                                        <MoreOutlined style={{ fontSize: '20px', fontWeight: 'bold', color: '#important', float: 'right' }} />
                                    </Dropdown>
                                ) : null
                            }
                            {props.task.title}
                            <div className="card_footer_container">
                                {
                                    props.board.board_type != 'template' && <div className="task-stats">
                                        <span className="completed">{props.task.stats.completed}</span>
                                        <span className="pending">{props.task.stats.pending}</span>
                                        <span className="due">{props.task.stats.due}</span>
                                    </div>
                                }
                                <div className="card_due_date">
                                    #{props.task.id}&nbsp;&nbsp;

                                    {
                                        props.task.due_date && <span>
                                            <ClockCircleOutlined style={{ color: "orange" }} /> {new Date(props?.task?.due_date).toLocaleDateString()}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
            {showCopyMoveTaskist ?
                props?.board?.template == 'template' || props?.board?.template == null ? (
                    <CopyMoveTasklist open={showCopyMoveTaskist} toggle={() => setShowCopyMoveTaskist(!showCopyMoveTaskist)} type={type} item={props.task} itemId={props.task.id} list={props.list} />
                ) : (
                    <CopyFPPOCard open={showCopyMoveTaskist} toggle={() => setShowCopyMoveTaskist(!showCopyMoveTaskist)} type={type} item={props.task} itemId={props.task.id} list={props.list} />
                )
                :
                null
            }
        </>
    );
}

function mapStateToProps(state) {
    const { board } = state.boards;
    const { user } = state.auth;
    return {
        board,
        user
    }
}

export default connect(mapStateToProps)(Task);
