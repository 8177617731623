
import { useEffect, useState } from "react";
import AddCommand from "../AddCommand";

const commandColor = '#a29bfe';

// Has params: props.id, props.comps, props.setComps, props.indices, props.setIsScriptRendered, props.childrenArray
export default function SetFlagCommand (props) {

   const [ evaluatedErrors, setEvaluatedErrors ] = useState(false);
   const [ color, setColor ] = useState(commandColor);

    let flag = "";
    let value = "";
    let comment = -1;

    let thisObj = props.comps[props.id].script[props.indices[0]];
    for (let k = 0; k < props.indices.length - 1; ++k) {
        thisObj = thisObj.children[props.indices[k + 1]];
    }
    
    try {
        flag = thisObj.flag;
        value = thisObj.value;
        comment = thisObj.comment;
    } catch (e) {

    }

    let flags_to_options = [<option value="">None</option>];
    for (let k = 0; k < props.externals.flags.length; ++k) {
        flags_to_options.push(<option value={props.externals.flags[k].name}>
            {props.externals.flags[k].name}
        </option>);
    }
    
    let indexOfFlag = 0;
    let Values = [];
    for (let k = 0; k < props.externals.flags.length; ++k) {
        if (props.externals.flags[k].name === flag) {
            indexOfFlag = k;
            Values = props.externals.flags[k].values;
        }
    }

    let values_to_options = [<option value="">None</option>];
    for (let k = 0; k < Values.length; ++k) {
        values_to_options.push(<option value={Values[k]}>{Values[k]}</option>);
    }

    if (!evaluatedErrors) {
        if (flag !== "" && value !== "") {
            setColor(commandColor);
            setEvaluatedErrors(true);
        } else {
            setColor('#FC427B');
            setEvaluatedErrors(true);
        }
    }

    useEffect(() => {
        if (flag !== "" && value !== "") {
        setColor(commandColor);
        setEvaluatedErrors(true);
        } else {
        setColor('#FC427B');
        setEvaluatedErrors(true);
        }
    });

   return <>
       <div style={{
           marginBottom: '2vmax',
           overflow: 'auto',
           display: 'inline-block',
           marginRight: '4vmax',
           verticalAlign: 'top'
       }}>
           <div style={{
               padding: '1vmax',
               backgroundColor: color,
               color: '#000',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontFamily: "Work Sans, Lato, sans-serif",
               fontWeight: '500'
           }}>
               SET FLAG
           </div>
           <a className='panel-button' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontFamily: "Work Sans, Lato, sans-serif",
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax'
           }} onClick={(e) => {
               e.preventDefault();
               let new_comps = [...props.comps];
               let thisObj = new_comps[props.id].script[props.indices[0]];
               for (let k = 0; k < props.indices.length - 2; ++k) {
                   thisObj = thisObj.children[props.indices[k + 1]];
               } thisObj.children.splice(props.indices[props.indices.length - 1], 1);
               props.setComps(new_comps);
               props.setIsScriptRendered(false);
           }}>
               Delete
           </a>
           <i className='fa-solid fa-comment-dots pointy comp' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax',
               backgroundColor: comment === -1 ? "#000" : "#faf3dd",
               color: comment === -1 ? "#faf3dd" : "#000"
           }} onClick={(e) => {
               e.preventDefault();
               let new_comps = [...props.comps];
               let thisObj = new_comps[props.id].script[props.indices[0]];
               for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
               if (thisObj.comment !== -1) {
                    thisObj.comment = -1;
               } else {
                    thisObj.comment = "Comments";
               }
               props.setComps(new_comps);
               props.setIsScriptRendered(false);
           }}></i>
           {/* Copy Command Icon */}
           <i className='fa-solid fa-copy pointy comp panel-button' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax'
           }} onClick={(e) => {
               e.preventDefault();
               let thisObj = props.comps[props.id].script[props.indices[0]];
               for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
               navigator.clipboard.writeText(JSON.stringify(thisObj));
           }}></i>
           <div style={{
               padding: '2vmax 0',
               borderLeft: `3pt solid ${color}`,
               borderBottom: `1pt dashed ${color}`,
               paddingLeft: '2vmax',
               fontFamily: "Work Sans, Lato, sans-serif",
               fontWeight: '500',
               paddingRight: '2vmax'
           }} className='code-block'>
               <div style={{
                display: 'inline-block',
                fontSize: '1.15vmax',
                marginBottom: '0'
               }}>SET <select className='panel-dropdown' onChange={(e) => {
                    e.preventDefault();
                    let new_comps = [...props.comps];
                    let thisObj = new_comps[props.id].script[props.indices[0]];
                    for (let k = 0; k < props.indices.length - 1; ++k) {
                        thisObj = thisObj.children[props.indices[k + 1]];
                    } thisObj.flag = e.target.value;
                    props.setComps(new_comps);
                    props.setIsScriptRendered(false);
                }} value={flag} style={{
                    marginLeft: '0.5vmax',
                    marginRight: '0.5vmax',
                    minWidth: '5vmax'
                }}>
                    { flags_to_options }
                </select> TO <select className='panel-dropdown' onChange={(e) => {
                    e.preventDefault();
                    let new_comps = [...props.comps];
                    let thisObj = new_comps[props.id].script[props.indices[0]];
                    for (let k = 0; k < props.indices.length - 1; ++k) {
                        thisObj = thisObj.children[props.indices[k + 1]];
                    } thisObj.value = e.target.value;
                    props.setComps(new_comps);
                    props.setIsScriptRendered(false);
                }} value={value} style={{
                    marginLeft: '0.5vmax',
                    marginRight: '0.5vmax',
                    minWidth: '5vmax'
                }}>
                    { values_to_options }
                </select></div><br />
                {
                    comment === -1 ? <></> : <>
                        <br />
                        <input className='panel-input-reverse' style={{
                            width: '100%',
                            height: '2vmax',
                            marginTop: '1vmax'
                        }} value={comment} onChange={(e) => {
                            let new_comps = [...props.comps];
                            let thisObj = new_comps[props.id].script[props.indices[0]];
                            for (let k = 0; k < props.indices.length - 1; ++k) {
                                thisObj = thisObj.children[props.indices[k + 1]];
                            }
                            thisObj.comment = e.target.value;
                            props.setComps(new_comps);
                            props.setIsScriptRendered(false);
                        }}></input>
                    </>
                }
           </div>
       </div>
   </>;

}