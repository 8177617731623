import axios from "axios";
import authHeader from "./auth-header";
import { formsUrl } from "./base";
// import formsAuthHeader from './form-auth-header';


class FormService {
    async getForms(payload) {
        const response = await axios
            .get(`${formsUrl}/`,
                { params: payload, headers: authHeader() });
        return response.data;
    }

    async getFormDetail(formId) {
        const response = await axios
            .get(`${formsUrl}/${formId}/detail`,
                { headers: authHeader() });
        return response.data;
    }

    async getFormPages(formId) {
        const response = await axios
            .get(`${formsUrl}/${formId}/page/details/`,
                { headers: authHeader() });
        return response.data;
    }

    getGUID() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    async getUsers(payload) {
        const response = await axios
            .get(`${formsUrl}/users/?limit=1000`,
                { params: payload, headers: authHeader() });
        return response.data;
    }

    async getMembers(payload) {
        const response = await axios
            .get(`${formsUrl}/members/`,
                { params: payload, headers: authHeader() });
        return response.data;

    }

    async addMember(payload) {
        const response = await axios.post(
            `${formsUrl}/members/add/`,
            payload,
            { headers: authHeader() }
        );
        return response.data;
    }

    async changeMember(payload) {
        const response = await axios.put(
            `${formsUrl}/members/change/`,
            payload,
            { headers: authHeader() }
        );
        return response.data;
    }

    async deleteMember(payload) {
        const response = await axios.delete(
            `${formsUrl}/members/${payload.id}/delete/`,
            { headers: authHeader() }

        );
        return response.data;
    }

    async createForm(payload) {
        const response = await axios.post(
            `${formsUrl}/create/`,
            payload,
            { headers: authHeader() }
        );
        return response.data;
    }

    async updateForm(dbId, payload) {
        payload["form_id"] = dbId;

        const response = await axios.patch(
            `${formsUrl}/${dbId}/change/`,
            payload,
            {
                headers: authHeader()
            }
        );
        return response.data;
    }

    async deleteForm(payload, is_delete) {
        const response = await axios.delete(
            `${formsUrl}/${payload.form_id}/delete/?is_delete=${is_delete}`,
            {
                // params: payload,
                headers: authHeader()
            }
        );
        return response.data;
    }

    async copyForm(payload) {
        const response = await axios.post(
            `${formsUrl}/create/`,
            payload,
            {
                headers: authHeader()
            }
        );
        return response.data;
    }

    async getRecords(payload, url) {
        let _url = `${formsUrl}/records/`;
        if (url != null && url != "" && url != undefined) {
            _url = url;
        }

        const response = await axios
            .get(_url, { params: payload, headers: authHeader() });
        return response.data;
    }



    async getRecord(id) {
        const response = await axios.get(`${formsUrl}/record/${id}/detail/`, { headers: authHeader() });
        for (var i = 0; i < response.data.record.content.length; i++) {
            var content = response.data.record.content[i];

            if (content.type == "intermediate/Snapshot") {
                var image_id = content["image_id"];
                if (image_id != '' && image_id != null && image_id != undefined) {
                    const resp = await axios.get(`${formsUrl}/records/image/${image_id}/get/`, { headers: authHeader() });
                    if (resp.status == 200) {
                        content["data"] = resp.data.image;
                    }
                }
            }
            if (content.type == "intermediate/Signature") {
                var image_id = content["props"]["image_id"];
                if (image_id != '' && image_id != null && image_id != undefined) {
                    const resp = await axios.get(`${formsUrl}/records/image/${image_id}/get/`, { headers: authHeader() });
                    if (resp.status == 200) {
                        content["props"]["data"] = resp.data.image;
                    }
                }
            }
        }

        return response.data;
    }

    async deleteRecord(payload) {
        const response = await axios.delete(
            `${formsUrl}/records/${payload.id}/delete/`,
            {
                params: payload,
                headers: authHeader()
            }
        );
        return response.data;
    }

    async createRecord(payload) {
        const response = await axios.post(
            `${formsUrl}/records/create/`,
            payload,
            { headers: authHeader() }
        );
        return response.data;
    }

    async updateRecord(recordId, payload) {
        const response = await axios.patch(
            `${formsUrl}/records/${recordId}/change/`,
            payload,
            { headers: authHeader() }
        );
        return response.data;
    }

    async getFlags(formId) {
        const response = await axios.get(`${formsUrl}/${formId}/flags/`, { headers: authHeader() });
        return response.data;
    }
    async archiveForm(formId, is_archive) {
        const response = await axios.get(`${formsUrl}/${formId}/archive/?is_archive=${is_archive}`, { headers: authHeader() });
        return response.data;
    }

    async exportForm(formId) {
        const response = await axios.get(`${formsUrl}/import/export/?form_id=${formId}`, { headers: authHeader() });
        return response.data;
    }

    async importForm(data, fid, restore) {
        const response = await axios.post(`${formsUrl}/import/export/?fid=${fid}&restore=${restore}`, data, { headers: authHeader() });
        return response.data;
    }

    async createEmailNotification(data) {
        const response = await axios.post(`${formsUrl}/notification/create/`, data, { headers: authHeader() });
        return response;
    }
    async updateEmailNotification(data) {
        const response = await axios.put(`${formsUrl}/notification/change/`, data, { headers: authHeader() });
        return response;
    }
    async deleteEmailNotification(id) {
        const response = await axios.delete(`${formsUrl}/notification/delete/?id=${id}`, { headers: authHeader() });
        return response;
    }
    async getEmailNotification(form) {
        const response = await axios.get(`${formsUrl}/notification/list/?form=${form}`, { headers: authHeader() });
        return response;
    }
}

export default new FormService();