
import { useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';

import process_vars from '../../intermediate/process_vars';
import execute_script from './execute_script';


const boxShadowRadius = 15;

export default function Check({ id, comps, setter, vw, vars, setVars, editable, externals, setExternals }) {

    let boxShadow = "0px 0px 0px #ff4f42";

    if (comps[id].error) {
        boxShadow = `0px 0px ${boxShadowRadius}px #ff4f42`;
    }
    else if (comps[id].hasOwnProperty('warning')) {
        if (comps[id].warning) {
            boxShadow = `0px 0px ${boxShadowRadius}px #d35400`;
        }
    }
    else if (comps[id].disable) {
        boxShadow = `0px 0px ${boxShadowRadius}px #4e6df5`;
    }

    const [inner_content, setInnerContent] = useState([]);

    useEffect(() => {
        let innerContent = [];
        for (let k = 0; k < comps[id].props.content.length; ++k) {
            let left_margin = '0.5vmax';
            if (process_vars(comps[id].props.alignment === 'center')) {
                left_margin = '40%';
            } else if (process_vars(comps[id].props.alignment === 'right')) {
                left_margin = '80%';
            }
            innerContent.push(
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: (100 / comps[id].props.content.length).toString() + '%',
                    alignItems: "center"
                }} onClick={(e) => {
                    e.preventDefault();
                    if (editable) {
                        if (!comps[id].disable) {
                            let new_comps = [...comps];
                            if (new_comps[id].props.selected.includes(k.toString())) {
                                for (let i = 0; i < new_comps[id].props.selected.length; ++i) {
                                    if (new_comps[id].props.selected[i] === k.toString()) {
                                        new_comps[id].props.selected.splice(i, 1);
                                    }
                                }
                            } else {
                                new_comps[id].props.selected.push(k.toString());
                            }
                            setter(new_comps);
                            execute_script(1, id, comps, setter, vars, setVars, externals, setExternals);
                        }
                    }
                }}>
                    {/* <input type='checkbox' style={{
                        marginLeft: left_margin
                    }}
                        onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}

                        id={process_vars(comps[id].props.content[k], vars)}
                        value={process_vars(comps[id].props.content[k], vars)}
                        checked={comps[id].props.selected.includes(k.toString())} /> */}

                    <span>
                        {
                            comps[id].props.selected.includes(k.toString()) ? (
                                <i style={{ color: '#0275ff', fontSize: 15, marginLeft: 5 }} className="fa fa-check-square-o" aria-hidden="true"></i>
                            ) : (
                                <i style={{ fontSize: 15, marginLeft: 5 }} className="fa fa-square-o" aria-hidden="true"></i>
                            )
                        }
                    </span>

                    <label style={{
                        marginLeft: '0.5vmax'
                    }}
                        htmlFor={process_vars(comps[id].props.content[k], vars)}>
                        {process_vars(comps[id].props.content[k], vars)}
                    </label>
                </div>
            );
        }
        setInnerContent(innerContent);
    }, [comps]);

    return (
        <div onClick={(e) => {
            let innerContent = [];
            for (let k = 0; k < comps[id].props.content.length; ++k) {
                let left_margin = '0.5vmax';
                if (process_vars(comps[id].props.alignment === 'center')) {
                    left_margin = '40%';
                } else if (process_vars(comps[id].props.alignment === 'right')) {
                    left_margin = '80%';
                }
                innerContent.push(
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        height: (100 / comps[id].props.content.length).toString() + '%',
                        alignItems: "center"
                    }} onClick={(e) => {
                        e.preventDefault();
                        if (editable) {
                            if (!comps[id].disable) {
                                let new_comps = [...comps];
                                if (new_comps[id].props.selected.includes(k.toString())) {
                                    for (let i = 0; i < new_comps[id].props.selected.length; ++i) {
                                        if (new_comps[id].props.selected[i] === k.toString()) {
                                            new_comps[id].props.selected.splice(i, 1);
                                        }
                                    }
                                } else {
                                    new_comps[id].props.selected.push(k.toString());
                                }
                                setter(new_comps);
                                execute_script(1, id, comps, setter, vars, setVars, externals, setExternals);
                            }
                        }
                    }}>
                        {/* <input type='checkbox' style={{
                            marginLeft: left_margin
                        }}
                            onChange={(e) => {
                                e.stopPropagation();
                            }}
                            id={process_vars(comps[id].props.content[k], vars)}
                            value={process_vars(comps[id].props.content[k], vars)}
                            checked={comps[id].props.selected.includes(k.toString())} /> */}

                        <span>
                            {
                                comps[id].props.selected.includes(k.toString()) ? (
                                    <i style={{ color: '#0275ff', fontSize: 15, marginLeft: 5 }} className="fa fa-check-square-o" aria-hidden="true"></i>
                                ) : (
                                    <i style={{ fontSize: 15, marginLeft: 5 }} className="fa fa-square-o" aria-hidden="true"></i>
                                )
                            }
                        </span>
                        <label style={{
                            marginLeft: '0.5vmax'
                        }}
                            for={process_vars(comps[id].props.content[k], vars)}>
                            {process_vars(comps[id].props.content[k], vars)}
                        </label>
                    </div>
                );
            }
            setInnerContent(innerContent);
        }}>
            <Rnd className='comp' style={{
                color: process_vars(comps[id].props.color, vars),
                backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
                overflow: 'hidden',
                fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
                fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
                fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
                textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                    `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                position: 'absolute',
                boxShadow: editable ? boxShadow : 'none',
                transition: 'box-shadow 0.5s'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} enableResizing={false} disableDragging={true}>{inner_content}</Rnd>
        </div>
    );

}