import React from "react";
import { useParams } from "react-router-dom";
import {appUrl} from '../../redux/services/base';

const SPCGraph = () => {
	const { spc_id } = useParams()

	return (
		<iframe src={`${appUrl}/spc/graph/react?page=1&spc_id=${spc_id}&token=`} 
			style={{width: '100%', height: 'calc(100% - 2px)', overflow: 'hidden', border: 'none'}} scrolling="yes">

			</iframe>
	);
};


export default SPCGraph;