
import {
    useState
} from 'react';
import { Rnd } from 'react-rnd';

import Modal from '../utils/Modal';

import '../component.css';
import process_vars from '../intermediate/process_vars';

import ScriptView from '../script/ScriptView';

export default function Check({ id, comps, setter, vw, gridSize, sel, setSel, hov, setHov, vars, setVars, contextMenu, setContextMenu, pdf, magneticGrid, setMagneticGrid, magneticGridPartitions }) {

    let background_color = '';
    if (hov === id) {
        background_color = '#FAF3DD';
    } else {
        background_color = process_vars(comps[id].props.backgroundColor, vars);
    }

    let innerContent = [];
    if (sel === id)
        background_color = process_vars(comps[id].props.backgroundColor, vars);
    for (let k = 0; k < comps[id].props.content.length; ++k) {
        let left_margin = '0.5vmax';
        if (process_vars(comps[id].props.alignment === 'center')) {
            left_margin = '40%';
        } else if (process_vars(comps[id].props.alignment === 'right')) {
            left_margin = '80%';
        }
        if (pdf == true) {
            if (comps[id].props.selected.includes(k.toString())) {
                innerContent.push(" ☑ " + comps[id].props.content[k]);
            }
            else {
                innerContent.push(" ☐ " + comps[id].props.content[k]);
            }

        }
        else {
            innerContent.push(
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: (100 / comps[id].props.content.length).toString() + '%',
                    alignItems: "center"
                }}>
                    <input type='checkbox' style={{
                        marginLeft: left_margin
                    }}
                        id={process_vars(comps[id].props.content[k], vars)}
                        value={process_vars(comps[id].props.content[k], vars)}
                        checked={comps[id].props.selected.includes(k.toString())} />
                    <label style={{
                        marginLeft: '0.5vmax'
                    }}
                        for={process_vars(comps[id].props.content[k], vars)}>
                        {process_vars(comps[id].props.content[k], vars)}
                    </label>
                </div>
            );
        }

    }

    return (
        <div>
            <Rnd className='comp' style={{
                whiteSpace: pdf == true ? 'pre-line' : 'initial',
                paddingLeft: 5,
                color: process_vars(comps[id].props.color, vars),
                backgroundColor: background_color,
                overflow: 'hidden',
                fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
                fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
                fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
                textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                    `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                transition: 'transform 0.05s ease'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} onDragStop={(e, d) => {
                let new_comps = [...comps];
                
                let dx = 0;
                let dy = 0;
                if (magneticGrid) {
                    dx = Math.round(d.x / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                    dy = Math.round(d.y / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                } else {
                    dx = d.x;
                    dy = d.y;
                }

                new_comps[id].position = [
                    dx / vw,
                    dy / vw
                ];

                setter(new_comps);
            }} onResize={(e, direction, ref, delta, position) => {
                let new_comps = [...comps];

                let px = 0;
                let py = 0;
                if (magneticGrid) {
                    px = Math.round(position.x / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                    py = Math.round(position.y / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                } else {
                    px = position.x;
                    py = position.y;
                }

                let ow = 0;
                let oh = 0;
                if (magneticGrid) {
                    ow = Math.round(ref.offsetWidth / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                    oh = Math.round(ref.offsetHeight / (window.innerWidth / magneticGridPartitions)) * (window.innerWidth / magneticGridPartitions);
                } else {
                    ow = ref.offsetWidth;
                    oh = ref.offsetHeight;
                }

                new_comps[id].position = [
                    px / vw,
                    py / vw
                ];
                new_comps[id].size = [
                    ow / vw,
                    oh / vw
                ];
                setter(new_comps);
            }} minWidth={window.innerWidth * 0.02} minHeight={window.innerWidth * 0.012} bounds='.form-view'
                dragGrid={[
                    gridSize,
                    gridSize
                ]} resizeGrid={[
                    gridSize,
                    gridSize
                ]} onMouseEnter={() => {
                    setHov(id);
                }} onMouseLeave={() => {
                    setHov(-1);
                }} onDoubleClick={() => {
                    setSel(id);
                }} onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setContextMenu([true, id, [e.clientX, e.clientY]]);
                }} onClick={(e) => {
                    e.stopPropagation();
                }}>{pdf == true ? innerContent.join('\n') : innerContent}</Rnd>
        </div>
    );

}

export function CheckProperties({ id, comps, setter, setSel, vars, setVars, externals, setExternals }) {

    const [editing, setEditing] = useState(false);

    let init_selected_content = [
        <option value='-1'>None</option>
    ];
    for (let k = 0; k < comps[id].props.content.length; ++k) {
        init_selected_content.push(
            <option value={k}>{comps[id].props.content[k]}</option>
        )
    }

    const [isShowingScript, setIsShowingScript] = useState(false);

    return (<>
        <br></br>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setIsShowingScript(true);
        }}>Edit Script</a>
        <br /><br />
        <p className='panel-label'>Position: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].position[0] = e.target.value;
            setter(new_comps);
        }} value={comps[id].position[0]} style={{ width: '15%' }} placeholder='X' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].position[1] = e.target.value;
            setter(new_comps);
        }} value={comps[id].position[1]} style={{
            width: '15%',
            marginLeft: '0.5vmax'
        }} placeholder='Y' />
        <br /> <br />
        <p className='panel-label'>Dimensions: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].size[0] = e.target.value;
            setter(new_comps);
        }} value={comps[id].size[0]} style={{ width: '15%' }} placeholder='Width' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].size[1] = e.target.value;
            setter(new_comps);
        }} value={comps[id].size[1]} style={{
            width: '15%',
            marginLeft: '0.5vmax'
        }} placeholder='Height' />
        <br /> <br />
        <p className='panel-label'>Content: </p>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setEditing(true);
        }}>Open Edit Modal</a>
        <br /> <br />
        <p className='panel-label'>Size: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.fontSize = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.fontSize} />
        <br /> <br />
        <p className='panel-label'>Style: </p>
        <i className='fa-solid fa-bold panel-icon' style={{
            color: comps[id].props.bold ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.bold ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.bold = !new_comps[id].props.bold;
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-italic panel-icon' style={{
            color: comps[id].props.italic ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.italic ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.italic = !new_comps[id].props.italic;
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-underline panel-icon' style={{
            color: comps[id].props.underlined ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.underlined ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.underlined = !new_comps[id].props.underlined;
            setter(new_comps);
        }}></i>
        <br /> <br />
        <p className='panel-label'>Alignment: </p>
        <i className='fa-solid fa-align-left panel-icon' style={{
            color: comps[id].props.alignment === 'left' ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.alignment === 'left' ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.alignment = 'left';
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-align-center panel-icon' style={{
            color: comps[id].props.alignment === 'center' ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.alignment === 'center' ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.alignment = 'center';
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-align-right panel-icon' style={{
            color: comps[id].props.alignment === 'right' ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.alignment === 'right' ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.alignment = 'right';
            setter(new_comps);
        }}></i>
        <br /> <br />
        <p className='panel-label'>Text Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.color = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.color} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.color = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.color} />
        <br /> <br />
        <p className='panel-label'>Background Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.backgroundColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.backgroundColor} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.backgroundColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.backgroundColor} />
        <br /> <br />
        <p className='panel-label'>Border Enabled: </p>
        <input type="checkbox" checked={comps[id].props.borderEnabled} onClick={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderEnabled = !new_comps[id].props.borderEnabled;
            setter(new_comps);
        }}></input>
        <br /><br />
        <p className='panel-label'>Border Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.borderColor} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.borderColor} />
        <br /><br />
        <p className='panel-label'>Border Width:</p>
        <input type='range' min='1' max='10' value={comps[id].props.borderWidth} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderWidth = parseInt(e.target.value);
            setter(new_comps);
        }}></input>
        <br /><br />
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setSel(-1);
        }}>Ok</a>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            let new_comps = [...comps];
            new_comps.splice(id, 1);
            setSel(-1);
            setter(new_comps);
        }} style={{
            marginLeft: '1vmax'
        }}>Delete</a>
        <br /> <br />
        {editing ?
            <CheckContentMenu id={id} comps={comps} setter={setter} setEditing={setEditing} /> :
            <></>
        }
        {isShowingScript && !editing ? <ScriptView id={id} comps={comps} setComps={setter}
            setIsShowingScript={setIsShowingScript}
            vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} /> : <></>}
    </>);

}

export function CheckAdder({ comps, setComps, setView, page, pageId, setSelectedEl }) {

    const [hovered, setHovered] = useState(false);

    return (
        <div className="toolbar-element" style={{
            // border: '1px #FAF3DD solid',
            // display: 'inline-block',
            // padding: '1vmax',
            // color: hovered ? 'black' : '#FAF3DD',
            // backgroundColor: hovered ? '#FAF3DD' : 'black',
            // transition: 'background-color 0.1s, color 0.1s',
            // marginLeft: '1vmax'
        }} onMouseEnter={() => {
            setHovered(true);
        }} onMouseLeave={() => {
            setHovered(false);
        }} onClick={(e) => {
            e.preventDefault();
            let new_comps = [...comps];
            new_comps.push({
                page: page,
                page_id: pageId,
                type: 'primitive/Check',
                name: 'Check',
                position: [0.475, 0.15 + document.getElementById("wrapper").scrollTop / document.getElementById("wrapper").offsetWidth],
                size: [0.10, 0.05],
                props: {
                    content: [
                        'Check'
                    ],
                    selected: [],
                    fontSize: '1',
                    bold: false,
                    italic: false,
                    underlined: false,
                    color: '#000000',
                    backgroundColor: '#ffffff',
                    alignment: 'left',
                    borderEnabled: true,
                    borderColor: '#000000',
                    borderWidth: 1
                }, script: [
                    {
                        type: "event/start",
                        children: []
                    },
                    {
                        type: "event/change",
                        children: []
                    },
                    {
                        type: "event/save",
                        children: []
                    }
                ],
                error: "",
                warning: "",
                disable: false
            });
            setComps(new_comps);
            setView('');
            setSelectedEl(comps.length);
        }}>
            <i className="fa-solid fa-square-check" style={{
                color: hovered ? 'black' : '#FAF3DD'
            }}></i>
            <p className='panel-label' style={{
                color: hovered ? 'black' : '#FAF3DD',
                marginLeft: '1vmax',
                fontSize: '1.15vmax'
            }}>Check</p>
        </div>
    );

}

export function CheckContentMenu({ id, comps, setter, setEditing }) {

    let content_list = [];
    for (let k = 0; k < comps[id].props.content.length; k++) {
        content_list.push(
            <div style={{
                padding: '0.5vmax',
                textAlign: 'justify',
                // border: '1px #FAF3DD solid',
                // borderTop: '1px #FAF3DD solid',
                margin: '2vmax 2vmax 0 2vmax',
                marginTop: k !== 0 ? '' : '2vmax',
                backgroundColor: '#000'
            }}>
                <div style={{
                    display: 'inline-block',
                    width: '65%'
                }}>
                    <i class="fa-solid fa-check panel-icon" style={{
                        backgroundColor: comps[id].props.selected.includes(k.toString()) ? '#FAF3DD' : '#000',
                        color: comps[id].props.selected.includes(k.toString()) ? '#000' : '#FAF3DD',
                        fontSize: '1.25vmax',
                        margin: '0 1vmax 0 0.5vmax'
                    }} onClick={() => {
                        let new_comps = [...comps];
                        if (new_comps[id].props.selected.includes(k.toString())) {
                            let j = new_comps[id].props.selected.indexOf(k.toString());
                            new_comps[id].props.selected.splice(j, 1);
                        } else {
                            new_comps[id].props.selected.push(k.toString());
                        } setter(new_comps);
                    }}></i>
                    <input className='panel-input' style={{
                        fontWeight: '300',
                        fontSize: '1.25vmax',
                        width: '60%',
                        // padding: '0.5vmax'
                        padding: '0px 5px',
                        height: 25
                    }} value={comps[id].props.content[k]} onChange={(e) => {
                        let new_comps = [...comps];
                        new_comps[id].props.content[k] = e.target.value;
                        setter(new_comps);
                    }}></input>
                </div>
                <div style={{
                    display: 'inline-block',
                    width: '35%'
                }}>
                    <i class="fa-solid fa-angles-down pointy" style={{
                        color: '#FAF3DD',
                        fontSize: '1.25vmax',
                        margin: '0 1vmax 0 0vmax'
                    }} onClick={() => {
                        if (k !== comps[id].props.content.length - 1) {
                            let new_comps = [...comps];
                            let current = new_comps[id].props.content[k];
                            new_comps[id].props.content[k] = new_comps[id].props.content[k + 1];
                            new_comps[id].props.content[k + 1] = current;
                            setter(new_comps);
                        }
                    }}></i>
                    <i class="fa-solid fa-angles-up pointy" style={{
                        color: '#FAF3DD',
                        fontSize: '1.25vmax',
                        margin: '0 0vmax 0 0vmax'
                    }} onClick={() => {
                        if (k !== 0) {
                            let new_comps = [...comps];
                            let current = new_comps[id].props.content[k];
                            new_comps[id].props.content[k] = new_comps[id].props.content[k - 1];
                            new_comps[id].props.content[k - 1] = current;
                            setter(new_comps);
                        }
                    }}></i>
                    <i class="fa-solid fa-trash pointy" style={{
                        color: '#FAF3DD',
                        fontSize: '1.25vmax',
                        margin: '0 0vmax 0 2vmax'
                    }} onClick={() => {
                        let new_comps = [...comps];
                        new_comps[id].props.content.splice(k, 1);
                        setter(new_comps);
                    }}></i>
                </div>
            </div>
        );
    }

    return (
        <>
            <Modal width={30} height={40}>
                <h1 style={{
                    color: '#FAF3DD',
                    fontWeight: '300',
                    margin: '0',
                    padding: '1vmax 0',
                    fontSize: '1.75vmax',
                    borderBottom: '1px #FAF3DD solid',
                    display: 'block'
                }}>Edit Checkbox Element</h1>
                <div style={{
                    height: '27.5vh',
                    overflow: 'scroll'
                }}>
                    {content_list}
                    <br /> <br /> <br />
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: '#000000'
                }}>
                    <a href='' className='panel-button' style={{
                        margin: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        let new_comps = [...comps];
                        new_comps[id].props.content.push('New Option');
                        setter(new_comps);
                    }}>Add Option</a>
                    <a href='' className='panel-button' style={{
                        marginBottom: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();

                        // trim start and end spaces all list elements
                        // this will ensure strings like ' hello, world!    ' will become 'hello, world!'
                        let new_comps = [...comps];
                        for (let k = 0; k < new_comps[id].props.content.length; ++k) {
                            new_comps[id].props.content[k] = new_comps[id].props.content[k].trim();
                        }
                        setter(new_comps);

                        setEditing(false);
                    }}>Exit</a>
                </div>
            </Modal>
        </>
    );

}