export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const USER_DETAIL = "USER_DETAIL";

export const BOARDS = "BOARDS";
export const PRIORITY = "PRIORITY";
export const BOARD_CATEGORY = "BOARD_CATEGORY";
export const BOARDS_ADD = "BOARDS_ADD";
export const BOARD_LIST = "BOARD_LIST";
export const BOARD_LIST_UPDATE = "BOARD_LIST_UPDATE";
export const UPDATE_LIST_ITEMS = "UPDATE_LIST_ITEMS";
export const COMMENTS_DATA = "COMMENTS_DATA"
export const LIST = "LIST";
export const LIST_ITEM = "LIST_ITEM";
export const FLAGS_DATA = "FLAGS_DATA";
export const CHECKLIST = "CHECKLIST";
export const CHECKLIST_ITEM = "CHECKLIST_ITEM";
export const MEMBER_DATA = "MEMBER_DATA";
export const COOWNERS_DATA = "COOWNERS_DATA";
export const SPC = "SPC";
export const LINE_NUMBER = "LINE_NUMBER";
export const REPORTS = "REPORTS";
export const OVERALL_REPORTS = "OVERALL_REPORTS";
export const UPDATE_ATTACHMENTS = "UPDATE_ATTACHMENTS";
export const UPDATE_URLS = "UPDATE_URLS";
export const FILTERS = "FILTERS";
export const FILTERS_APPLIED = "FILTERS_APPLIED";
export const JOBS_FILTERS_APPLIED = "JOBS_FILTERS_APPLIED";

export const RESET_BOARDS = "RESET_BOARDS";
export const RESET_REPORTS = "RESET_REPORTS";
export const RESET_SPC = "RESET_SPC";
export const PARTICIPIENTS = "PARTICIPIENTS";
export const RESPONSIBLE_PERSON = "RESPONSIBLE_PERSON";
export const BOARD_OWNERS = "BOARD_OWNERS";
export const BOARD_CO_OWNERS = "BOARD_CO_OWNERS";

export const JOB_SETUP_LIST = "JOB_SETUP_LIST";
export const JOB_LIST = "JOB_LIST";


export const FORMS = "FORMS";
export const RECORDS = "RECORDS";
export const FORMS_LOADING = "FORMS_LOADING";
