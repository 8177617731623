import { Column } from '@ant-design/plots';
import { Card } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import ReportService from "../../redux/services/reports";

const OverallHealth = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const payload = {
      'filters': props.filters
    };
    ReportService.overallHealthReport(payload).then(res => {
      setData(res.data);
    });
  }, [props.filters])

  const config = {
    data,
    isGroup: true,
    xField: 'priority',
    yField: 'total',
    seriesField: 'status',
    color: ["#1B5E20", "#FF9800"],
    colorField: 'color',
    label: {
      position: 'middle',
      visible: true,
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  return (
    <Card title="Overall Health Report">
      <Column {...config} />
    </Card>
  );
};

function mapStateToProps(state) {
  const { overall_health } = state.reports;
  return {
    overall_health
  }
}

export default connect(mapStateToProps)(OverallHealth);
