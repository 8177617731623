
let math = require('mathjs'); // needed for evaluating embedded mathematical expressions

// Takes in a string and a map of variables and replaces every instance of a variable
// with that variable's value.
//
// e.g. ("Measurement: {measurement}", { name: "measurement", type: "Number", value: '4' })
//      -> "Measurement: 4"
//
// Also processes numbers whose values are not constants, but mathematical expressions.
// e.g. { name: "x", type: "Number", value: "[{a} + {b}]f" }
// 
// The function will evaluate such expressions via the implementation given in the `renderLabelText` function.
//
export default function process_vars(str, vars) {

    // wrapped in try/catch in case of edge rendering errors
    try {

        // regex for searching for embedded variables in the form: {variable}
        let searcher = /\{(\w*)\}/g;
        let match = str.match(searcher);

        // runs only if a match is found
        if (match.length > 0) {
            for (let k = 0; k < match.length; ++k) {
                // we must check if the embedded variable exists in `vars`
                let in_vars = false;

                for (let j = 0; j < vars.length; ++j) {
                    // check if "{variable}" converted to "variable" == vars[j].name
                    if (match[k].slice(1, match[k].length - 1) === vars[j].name) {
                        in_vars = vars[j].value;

                        // if we have a number, we must check if it contains a mathematical expression
                        if (vars[j].type === 'Number') {
                            // regex for checking for an expression in form: [{x} + {y}]f
                            let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
                            let matchResults = [...in_vars.matchAll(mathRegex)];

                            // if we have a match, replace that expression with its evaluated number
                            if (matchResults.length > 0) {
                                matchResults.forEach(result => {
                                    let mathExp = process_vars(result[1], vars);
                                    let evaluatedExp = 0;

                                    // there may be evaluation errors via mathjs, so we use try/catch
                                    try {
                                        // we use math.js here
                                        evaluatedExp = math.evaluate(mathExp).toString();
                                    } catch (e) {
                                        // if error is found, don't do anything
                                    }

                                    // we replace the expression with its evaluated form
                                    in_vars = in_vars.replace(result[0], evaluatedExp);
                                });
                            }
                        }

                        break;
                    }
                }

                if (in_vars !== false) {
                    str = str.replace(match[k], in_vars);
                }
            }
        }

        return str;
    } catch (e) {
        return str;
    }

}

// The renderLabelText function for reference
//
// function renderLabelText(str, vars) {
//     let parsedText = str;

//     let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
//     let matchResults = [...parsedText.matchAll(mathRegex)];
//     matchResults.forEach(result => {
//         let mathExp = process_vars(result[1], vars);
//         let evaluatedExp = 0;
//         try {
//             evaluatedExp = math.evaluate(mathExp).toString();
//             if (result[3] != null) {
//                 evaluatedExp = parseFloat(evaluatedExp).toFixed(parseInt(result[3]));
//             }
//         } catch (e) {
//             // do nothing
//         }
//         parsedText = parsedText.replace(result[0], evaluatedExp);
//     });

//     parsedText = process_vars(parsedText, vars);
//     return parsedText;
// }