import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BoardService from '../../redux/services/boards';
import ArchivedList from '../../components/reports/archived_list_report';


const ArchivedListReport = (props) => {
    const [boards, setBoards] = useState([]);


    useEffect(() => {
        BoardService.getFilters({ 'type': 'board' }).then(res => {
            setBoards(res);
        });
    }, [])

    return (
        <div>
            <ArchivedList boards={boards} />
        </div>
    );
};

function mapStateToProps(state) {
    const { filters_applied } = state.reports;
    return {
        filters_applied
    }
}

export default connect(mapStateToProps)(ArchivedListReport);

