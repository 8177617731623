import { Column } from '@ant-design/plots';
import { Card } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import ReportService from "../../../redux/services/reports";

const MissingItemSpentByOwner = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const payload = {
            'filters': props.filters
        };
        ReportService.missingItemSpentByOwner(payload).then(res => {
            setData(res);
        });
    }, [props.filters])

    const config = {
        data,
        isGroup: true,
        xField: 'job_setup__owner__first_name',
        yField: 'total'
    };


    return (
        <Card title="Spent on missing devices by owner">
            <Column {...config} />
        </Card>
    );
}


function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(MissingItemSpentByOwner);

