
import { CopyOutlined, DeleteOutlined, EditOutlined, ExportOutlined, FileAddOutlined, FileSearchOutlined, FolderAddOutlined, FolderFilled, FolderOpenOutlined, MailOutlined, MoreOutlined, RollbackOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';


export default function FormIcon({
    form,
    onDoubleClick,
    onMenuClick,
    showArchive
}) {

    return (
        <div className="card-panel folder" onDoubleClick={(e) => {
            e.stopPropagation();
            onDoubleClick(form);
        }} title={form.name}>
            <FolderFilled />
            {form.name}
            <span style={{ position: 'absolute', fontSize: 18, top: 15, right: -8 }}>
                <Dropdown style={{ position: 'absolute', top: 5, right: 5 }}
                    trigger={['click']}
                    overlay={
                        <Menu className="item_menu">
                            {form?.is_archived ? (
                                <>
                                    <Menu.Item key={`2_${form._id}`} onClick={(e) => onMenuClick(e, "searchRecord", form)} icon={<FileSearchOutlined />}>View Records</Menu.Item>

                                    {
                                        form.is_archived == true && form.parent < 0 ? (
                                            <>
                                                <Menu.Item key={`7_${form._id}`} onClick={(e) => onMenuClick(e, "unarchive", form)} icon={<DeleteOutlined style={{ color: "red" }} />}>UnArchive</Menu.Item>
                                                <Menu.Item key={`6_${form._id}`} onClick={(e) => onMenuClick(e, "Delete", form)} icon={<DeleteOutlined style={{ color: "red" }} />}>Delete</Menu.Item>
                                            </>
                                        ) : null
                                    }
                                </>
                            ) :
                                form.is_deleted ? (
                                    <>
                                        <Menu.Item key={`2_${form._id}`} onClick={(e) => onMenuClick(e, "searchRecord", form)} icon={<FileSearchOutlined />}>View Records</Menu.Item>
                                        {
                                            form.is_deleted == true && form.parent < 0 ? (
                                                <>
                                                    <Menu.Item key={`5_${form._id}`} onClick={(e) => onMenuClick(e, "restore", form)} icon={<RollbackOutlined />}>Restore</Menu.Item>
                                                    <Menu.Item key={`6_${form._id}`} onClick={(e) => onMenuClick(e, "permanent_delete", form)} icon={<DeleteOutlined style={{ color: "red" }} />}>Permanent Delete</Menu.Item>
                                                </>
                                            ) : null
                                        }

                                    </>
                                ) : (
                                    <>
                                        <Menu.Item key={`1_${form._id}`} onClick={(e) => onMenuClick(e, "addRecord", form)} icon={<FileAddOutlined />}>Add New Records</Menu.Item>
                                        <Menu.Item key={`2_${form._id}`} onClick={(e) => onMenuClick(e, "searchRecord", form)} icon={<FileSearchOutlined />}>View Records</Menu.Item>
                                        {
                                            // For Branch Admin Only
                                            showArchive ? (
                                                <>
                                                    <Menu.Item key={`3_${form._id}`} onClick={(e) => onMenuClick(e, "addChildForm", form)} icon={<FolderAddOutlined />}>Add New Child Form</Menu.Item>
                                                    <Menu.Item key={`4_${form._id}`} onClick={(e) => onMenuClick(e, "Copy", form)} icon={<CopyOutlined />}>Copy</Menu.Item>
                                                    <Menu.Item key={`5_${form._id}`} onClick={(e) => onMenuClick(e, "Edit", form)} icon={<EditOutlined />}>Edit</Menu.Item>
                                                    <Menu.Item key={`6_${form._id}`} onClick={(e) => onMenuClick(e, "Delete", form)} icon={<DeleteOutlined style={{ color: "red" }} />}>Delete</Menu.Item>
                                                </>
                                            ) : null
                                        }


                                        {
                                            showArchive ? (
                                                <Menu.Item key={`7_${form._id}`} onClick={(e) => onMenuClick(e, "archive", form)} icon={<FolderOpenOutlined style={{ color: "red" }} />}>Archive</Menu.Item>
                                            ) : null
                                        }
                                        {
                                            showArchive ? (
                                                <Menu.Item key={`8_${form._id}`} onClick={(e) => onMenuClick(e, "Export", form)} icon={<ExportOutlined />}>Export</Menu.Item>
                                            ) : null
                                        }
                                        {
                                            showArchive ? (
                                                <Menu.Item key={`16_${form._id}`} onClick={(e) => onMenuClick(e, "Restore", form)} icon={<ExportOutlined />}>Restore</Menu.Item>
                                            ) : null
                                        }

                                        <Menu.Item key={`12_${form._id}`} onClick={(e) => onMenuClick(e, "setEmailAlert", form)} icon={<MailOutlined />}>Set Email Alert</Menu.Item>


                                    </>
                                )
                            }

                        </Menu>
                    }>
                    <MoreOutlined />
                </Dropdown>

            </span >
        </div >
    );

}