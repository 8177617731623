import { store } from '../store';

export default function authHeader() {
  const { auth } = store.getState()
  const { token } = auth
  if (token !== null) {
    return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
}


