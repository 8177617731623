import { Card, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import ReportService from '../../redux/services/reports';

import { Gantt, ViewMode } from 'gantt-task-react';
import '../../assets/css/gantt_task_react.css';


const GanttELSChart = (props) => {
  const history = useHistory();
  const [tasks, setTasks] = useState([]);
  const [columnWidth, setColumnWidth] = useState(60);
  const [view, setView] = useState(ViewMode.Month);

  useEffect(() => {
    let rows = [];
    const payload = {
      'filters': props.filters
    };


    ReportService.ganttReport(payload, props?.itemView).then(res => {
      res.data.map((item, index) => {
        if (item.start_date != null && item.due_date != null) {
          let x;
          var percentage = 0;
          if (item?.percentage != null && item?.percentage != undefined) {
            percentage = item?.percentage;
          }
          if (props.itemView == true) {
            x = {
              start: new Date(item.start_date),
              end: new Date(item.due_date),
              name: item.title,
              id: item.id,
              data: item,
              type: 'task',
              progress: percentage,
              isDisabled: false,
              styles: {
                progressColor: item?.priority == null ? '#ffbb54' : item?.priority?.color,
                progressSelectedColor: item?.priority == null ? '#ffbb54' : item?.priority?.color,
              },
            };
          }
          else {
            x = {
              start: new Date(item.start_date),
              end: new Date(item.due_date),
              name: item.body,
              id: item.id,
              data: item,
              type: 'task',
              progress: 0,
              isDisabled: false,
              styles: {
                backgroundColor: item?.flag == null ? '#ffbb54' : item?.flag?.color,
                progressColor: item?.flag == null ? '#ffbb54' : item?.flag?.color,
                progressSelectedColor: item?.flag == null ? '#ffbb54' : item?.flag?.color,
              },
            };
          }
          rows.push(x);
        }
      });
      setTasks(rows);
    });
  }, [props.filters])



  const handleViewModeChange = (e) => {
    setView(e.target.value);
    if (e.target.value == "Month") {
      setColumnWidth(60);
    }
    else {
      setColumnWidth(40);
    }
  }

  const onDoubleClick = (task) => {
    let nextPage = "?next=/timeline";
    if (props?.itemView == true) {
      nextPage = "?next=/item/timeline";
    }
    history.push({
      pathname: `/task-detail/${task.data.board_id}/${task.data.list_id}/${task.data.item_id}`,
      search: nextPage
    });
  }

  return (
    <Card style={{ width: 'calc(100% - 1px)' }} title={
      <div style={{ position: 'relative' }}>
        <div>Gantt Chart</div>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <Radio.Group value={view} onChange={handleViewModeChange}>
            <Radio.Button value={ViewMode.Day}>Day</Radio.Button>
            <Radio.Button value={ViewMode.Week}>Week</Radio.Button>
            <Radio.Button value={ViewMode.Month}>Month</Radio.Button>
          </Radio.Group>
        </div>
      </div>
    }>
      {
        tasks?.length > 0 && <Gantt
          tasks={tasks}
          viewMode={view}
          fontSize={9}
          todayColor={"#e7ebed"}
          rowHeight={28}
          headerHeight={35}
          columnWidth={columnWidth}
          ganttHeight={450}
          onDoubleClick={onDoubleClick}
        // onSelect={handleOnSelect} 
        />
      }
    </Card>
  );
};

function mapStateToProps(state) {
  const { checklist_items } = state.reports;
  return {
    checklist_items
  }
}


export default connect(mapStateToProps)(GanttELSChart);
