import { Form, Input, Modal, Radio, Select } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { AllCategories, CreateBoard, UpdateBoard } from "../../redux/actions/boards";


const { Option } = Select;

const CreateBoardComp = (props) => {

    const [boardFormData, setBoardFormData] = useState(null);
    const [boardForm] = Form.useForm(null);


    const [selectedCoOwners, setSelectedCoOwners] = useState([])
    const [coOwners, setCoOwners] = useState(props?.members);
    const [boardType, setBoardType] = useState('board');

    useEffect(() => {
        props.dispatch(AllCategories());
        boardForm.setFieldsValue(props?.board)
    }, [boardForm, boardFormData])

    useEffect(() => {
        if (props?.board !== null) {
            setBoardFormData(props.board);

            setBoardType(props.board.board_type)
            props.board.co_owners.map((co_owner, index) => {
                setSelectedCoOwners(oldCoOwners => [...oldCoOwners, co_owner.id]);
            });

            let data = [];
            coOwners.map(item => {
                item["selected"] = false;
                if (selectedCoOwners.indexOf(item.id) > -1) {
                    item["selected"] = true
                }
                data.push(item)
                // setCoOwners(old => [...old, item]);
            });
            setCoOwners(data);
        }
    }, [props?.board])

    useEffect(() => {
        if (props?.boards != null && props?.boards != undefined && props?.boards != null && props?.board != undefined && props?.board != null && props?.board != undefined) {
            if (props.board.status != 'create_new') {
                const index = props.boards.findIndex(el => el.id === props.board?.id);
                let x = [];
                props.boards[index].co_owners.map((co_owner, index) => {
                    x.push(co_owner.id);
                    // setSelectedCoOwners(oldCoOwners => [...oldCoOwners, co_owner.id]);
                });
                setSelectedCoOwners(x);
            }
        }
    }, [props?.boards, props?.boards.co_owners])

    const clearData = () => {
        boardForm.resetFields();
        setSelectedCoOwners([]);
        props.setModalOpen(false)
    }

    const filterMember = (value) => {
        let filter_ = props.members.filter(member => {
            return member.username.toLowerCase().includes(value.toLowerCase())
        });
        setCoOwners(filter_)
    }

    const handleOnOk = () => {
        boardForm.validateFields().then(data => {
            if (props?.board?.id != null) {
                props.dispatch(UpdateBoard(props?.board.id, data))
            }
            else {
                data["co_owners"] = selectedCoOwners;
                props.dispatch(CreateBoard(data))
            }
            props.setModalOpen(false)
            setSelectedCoOwners([]);
        });
    }

    const handleOnMemberClick = (member) => {
        if (props?.board?.id != null) {
            let data = {
                "id": props?.board?.id,
                "title": props?.board?.title,
                "co_owners": [member.id]
            };
            props.dispatch(UpdateBoard(props?.board.id, data))
        }
        else {
            if (selectedCoOwners.indexOf(member.id) > -1) {
                selectedCoOwners.splice(selectedCoOwners.indexOf(member.id), 1);
                setSelectedCoOwners(oldCoOwners => [...selectedCoOwners]);
            } else {
                setSelectedCoOwners(oldCoOwners => [...oldCoOwners, member.id]);
            }
        }
    }


    const onBoardTypeChange = (e) => {
        setBoardType(e.target.value);
    }


    return (
        <Modal
            forceRender
            visible={props.modalOpen}
            afterClose={clearData}
            title={props?.modalId ? "Edit Board" : "Create Board"}
            onOk={handleOnOk}
            onCancel={clearData}
            centered={true}
            destroyOnClose={true}
        >
            <Form
                preserve={false}
                form={boardForm}
                initialValues={boardFormData}
                labelCol={{ span: 24 }}
                layout={"vertical"}
                wrapperCol={{ span: 24 }}
                autoComplete="off">
                <Form.Item label="Title" name="title" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Input autoFocus={true} />
                </Form.Item>

                {
                    props.user.groups.indexOf('Branch Admin') > -1 ? (
                        <Form.Item label="Board Type" name="board_type" rules={[{ required: true, message: 'This field is required!' }]}>
                            <Radio.Group onChange={onBoardTypeChange} value={boardType} defaultValue='board'>
                                <Radio value={'board'}>Board</Radio>
                                <Radio value={'template'}>Template</Radio>
                            </Radio.Group>
                        </Form.Item>
                    ) : null
                }

                {
                    boardType == 'board' ? (
                        <Form.Item name="category" label="Board Category" rules={[{ required: true, message: 'This field is required!' }]}>
                            <Select placeholder="Select Category">
                                <option value="">---Select Category---</option>
                                {props?.categories?.map((category) => {
                                    return (
                                        <Option key={"category_" + category.id} value={category.id}>{category.name}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    ) : null
                }

                <Form.Item label="Description" name="description" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Input.TextArea />
                </Form.Item>

                {/* <div>
                    <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 10}}>
                        <h5>Co-Owners</h5>
                        <Input style={{maxWidth: 250}} size="small" placeholder="Type here to search..." suffix={<SearchOutlined />} onChange={(e) => filterMember(e.target.value)}  tabIndex={2} />
                    </div>
                    <ListGroup style={{maxHeight: 200, overflowY:'auto'}}>

                        {
                            props?.members.map((member, index)=>{
                                return (
                                    selectedCoOwners.indexOf(member.id)>-1 && 
                                        <ListGroupItem className="list_item" key={"member_"+index} onClick={()=>{handleOnMemberClick(member)}}>
                                            {selectedCoOwners.indexOf(member.id) > -1 ? <UserCheck/>: <User/>} {member.first_name} {member.last_name}
                                        </ListGroupItem>
                                )
                            })
                        }
                        {
                            props?.members.map((member, index)=>{
                                return (
                                    selectedCoOwners.indexOf(member.id)<0 && 
                                        <ListGroupItem className="list_item" key={"member_"+index} onClick={()=>{handleOnMemberClick(member)}}>
                                            {selectedCoOwners.indexOf(member.id) > -1 ? <UserCheck/>: <User/>} {member.first_name} {member.last_name}
                                        </ListGroupItem>
                                )
                            })
                        }
                        
                    </ListGroup>
                </div> */}
            </Form>
        </Modal>
    );
};

function mapStateToProps(state) {
    const { boards, members, categories } = state.boards;
    const { user } = state.auth;
    return {
        boards, members, user, categories
    }
}

export default connect(mapStateToProps)(CreateBoardComp);
