import { FORMS, RECORDS, FORMS_LOADING } from "../types";
import FormService from "../services/forms";
import { MESSAGE_ERROR } from '../message_error';

export const GetForms = (payload) => (dispatch) => {
    dispatch({ type: FORMS_LOADING, payload: true });
    const promise = FormService.getForms(payload).then(
        (data) => {
            dispatch({
                type: FORMS,
                payload: data
            });
            return Promise.resolve()
        }).catch(
            (error) => {
                MESSAGE_ERROR(error, dispatch);
                return Promise.reject();
            }
        );
    dispatch({ type: FORMS_LOADING, payload: false });
    return promise;
};

export const GetRecords = (payload, url) => (dispatch) => {
    dispatch({ type: FORMS_LOADING, payload: true });
    const promise = FormService.getRecords(payload, url).then(
        (data) => {
            dispatch({
                type: RECORDS,
                payload: data
            });
            return Promise.resolve()
        }).catch(
            (error) => {
                MESSAGE_ERROR(error, dispatch);
                return Promise.reject();
            }
        );
    dispatch({ type: FORMS_LOADING, payload: false });
    return promise;
};