import React, { useState } from 'react';
import { Modal, Form, Select, Checkbox, Input } from 'antd';
import { connect } from 'react-redux';
import { CopyMove, GetBoardList } from '../../../redux/actions/boards';
import { useHistory, useParams } from "react-router-dom";

const { Option } = Select
const CopyMoveTasklist = (props) => {
    const history = useHistory();
    const [copyMoveForm] = Form.useForm(null)
    const [lists, setLists] = useState([])
    const { board_id } = useParams()


    const onOk = () => {
        copyMoveForm
            .validateFields()
            .then(values => {
                copyMoveForm.resetFields();
                values["action"] = props.type;
                values["from_list"] = props.list.id;
                props.dispatch(CopyMove(props.itemId, values)).then(res => {
                    history.push(`/task-detail/${props.board.id}/${values.list_id}/${res.id}`);
                });
                props.toggle()
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    const changeBoard = (board_id) => {
        props.dispatch(GetBoardList(board_id)).then(res => {
            setLists(res.lists)
        })

    }
    return (
        <Modal title={props.type === "Copy" ? "Copy Card" : "Move Card"} visible={props.open} onOk={onOk} onCancel={props.toggle}>
            <Form
                form={copyMoveForm}
                labelCol={{ span: 0 }}
                layout={"horizontal"}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                initialValues={
                    {
                        'keep_comments': props?.board?.board_type == 'template',
                        'keep_checklist': props?.board?.board_type == 'template',
                        'keep_attachements': props?.board?.board_type == 'template',
                        'item_name': props?.item?.title
                    }
                }
            >
                <Form.Item style={{ width: "100%" }}>
                    <Select onChange={(board_id) => changeBoard(board_id)} placeholder="Select Board">
                        {props.boards.map(board => {
                            if (board.board_type == 'board' || board.board_type == 'spc board') {
                                if (props.type == 'Copy') {
                                    return (
                                        <Option key={`board_${board.id}`} value={board.id}>{board.title}</Option>
                                    )
                                }
                                else {
                                    if (board.id !== parseInt(board_id)) {
                                        return (
                                            <Option key={`board_${board.id}`} value={board.id}>{board.title}</Option>
                                        )
                                    }
                                }
                            }
                        })}
                    </Select>
                </Form.Item>
                <Form.Item style={{ width: "100%" }} name="list_id" rules={[{ required: true, message: 'List is required!' }]}>
                    <Select placeholder="Select List">
                        {lists.map(list => {
                            return (
                                <Option key={`list_${list.id}`} value={list.id}>{list.title}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="Item Name" name="item_name" rules={[{ required: true, message: 'Item name is required!' }]}>
                    <Input placeholder="Enter Item Name" value={""} autoFocus={true} />
                </Form.Item>

                <Form.Item key={"keep_comments"} name="keep_comments" valuePropName='checked'>
                    <Checkbox disabled={props?.board?.board_type == 'template'}>Keep Comments:</Checkbox>
                </Form.Item>
                <Form.Item key={"keep_checklist"} name="keep_checklist" valuePropName='checked'>
                    <Checkbox disabled={props?.board?.board_type == 'template'}>Keep Checklist: </Checkbox>
                </Form.Item>
                <Form.Item key={"keep_attachements"} name="keep_attachements" valuePropName='checked'>
                    <Checkbox disabled={props?.board?.board_type == 'template'}>Keep Attachments: </Checkbox>
                </Form.Item>

            </Form>
        </Modal >
    );
};

function mapStateToProps(state) {
    const { boards, board } = state.boards;
    return { boards, board }
}

export default connect(mapStateToProps)(CopyMoveTasklist);