import React, { useState } from "react";
import { connect } from 'react-redux';

import { Modal, Form, Input, Radio, Space } from 'antd';

import { CreateListItem, UpdateItem } from "../../../redux/actions/boards";


const EditTask = (props) => {

    let _itemFormData = {
        list: props?.list==null?"":props?.list, 
        title: props?.item==null?"":props?.item.title, 
        priority: props?.item==null?"":props?.item?.priority?.id 
    };
    const [itemFormData, setItemFormData] = useState(_itemFormData);
    const [itemForm] = Form.useForm(null);

    const handlOnSubmit = ()=>{
        itemForm
        .validateFields()
        .then(values => {
          itemForm.resetFields();
          values["list"] = props.list.id;
          if(props?.item!==null){
            values["id"] = props.item.id;
          }
          if(values?.id===null || values?.id===undefined){
              // Create New Item in this list
              props.dispatch(CreateListItem(values)).then((res)=>{
                props.onSubmitItemForm(values);
              });
            }
            else{
                props.dispatch(UpdateItem(values["list"], values["id"], values)).then((res)=>{
                    props.onSubmitItemForm(values);
                });
                // props.dispatch();
            }

        //   props.onSubmitItemForm(values);
        })
        .catch(info => {
          console.log('Validate Failed:', info);
        });
    }

    return(
        <Modal title={props?.item?"Edit Item":"Create New Item"} visible={props.isItemModalVisible} 
                onCancel={()=>{props.onSubmitItemForm(null)}}
                onOk={handlOnSubmit}>
                    <Form
                        form={itemForm}
                        initialValues={itemFormData}
                        labelCol={{ span: 8 }}
                        layout={"vertical"}
                        wrapperCol={{ span: 16 }}
                        autoComplete="off"
                        >
                        <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Title is required!' }]}>
                            <Input placeholder="Enter title" value={""} autoFocus={true} />
                        </Form.Item>

                        <Form.Item name="priority" label="Priority" rules={[{ required: true, message: 'Priority is required!' }]}>
                            <Radio.Group>
                                <Space direction="vertical">
                                    {
                                        props?.priorities?.map((priority, index)=>{
                                            return (<Radio value={priority.id} key={"priority_"+index} >
                                                <span style={{backgroundColor: priority.color, width: 12, height:12, borderRadius: 100, marginRight: 5, display: 'inline-block', border: '1px solid #ccc'}}></span>
                                                {priority.title}
                                            </Radio>)
                                        })
                                    }
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Modal>
    )
}

function mapStateToProps(state) {
    const { board, priorities } = state.boards;
    return {
        board,
        priorities
    }
}

export default connect(mapStateToProps)(EditTask);