import React, { useState } from "react";
import { connect } from "react-redux";
import Records from '../../components/forms/records';
import ReportFilter from '../../components/forms/records/filters';



const RecordsPage = (props) => {
    const [filters, setFilters] = useState(props.filters_applied);
    const handleOnFilterChange = (_filters) => {
        setFilters(_filters);
    }

    return (
        <div>
            <div className="filter-container">
                <ReportFilter onFilterChange={handleOnFilterChange} />
            </div>
            <Records filters={filters} />
        </div>
    );
}


// export default RecordsPage;

function mapStateToProps(state) {
    const { filters_applied } = state.forms;
    return {
        filters_applied
    }
}

export default connect(mapStateToProps)(RecordsPage);