
import { Rnd } from 'react-rnd';

import process_vars from '../../intermediate/process_vars';
import execute_script from './execute_script';

const boxShadowRadius = 15;

export default function Button({ id, comps, setter, vw, vars, setVars, editable, externals, setExternals }) {

    let boxShadow = "0px 0px 0px #ff4f42";

    if (comps[id].error) {
        boxShadow = `0px 0px ${boxShadowRadius}px #ff4f42`;
    }
    else if (comps[id].hasOwnProperty('warning')) {
        if (comps[id].warning) {
            boxShadow = `0px 0px ${boxShadowRadius}px #d35400`;
        }
    }
    else if (comps[id].disable) {
        boxShadow = `0px 0px ${boxShadowRadius}px #4e6df5`;
    }

    return (
        <div>
            <Rnd className='comp' style={{
                color: process_vars(comps[id].props.color, vars),
                backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
                textAlign: 'center',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: 'hidden',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                    `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                position: 'absolute',
                boxShadow: boxShadow,
                transition: 'box-shadow 0.5s'
            }} position={{
                x: comps[id].position[0] * vw,
                y: comps[id].position[1] * vw
            }} size={{
                width: comps[id].size[0] * vw,
                height: comps[id].size[1] * vw
            }} enableResizing={false} disableDragging={true} onClick={() => {
                if (editable) {
                    if (!comps[id].disable) {
                        execute_script(1, id, comps, setter, vars, setVars, externals, setExternals);
                    }
                }
            }}><p style={{
                color: process_vars(comps[id].props.color, vars),
                width: '90%',
                height: '100%',
                textAlign: process_vars(comps[id].props.alignment, vars),
                display: "flex",
                alignItems: "center",
                justifyContent: process_vars(comps[id].props.alignment, vars),
                fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
                fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
                fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
                textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
                overflow: 'hidden',
                margin: '0px auto'
            }} className='pointy'>{process_vars(comps[id].props.text, vars)}</p>
            </Rnd>
        </div>
    );

}