
import { useState, useEffect } from "react";
import Modal from "../utils/Modal";


// import { Select, Spin, Modal, Button, List, Tag, Avatar, message } from 'antd';

// import { useSelector } from 'react-redux';

// import { selectId } from '../../redux/features/idSlice';
// import { selectToken } from '../../redux/features/tokenSlice';

import FormService from '../../../redux/services/forms';
import SharePage from './SharePage';

import { getHeight, layouts } from "../misc/layouts";

export default function FormView({
    reference,
    comps,
    setComps,
    content,
    position,
    size,
    pages,
    setPages,
    currentPage,
    setCurrentPage,
    setSelectedEl,
    setContextMenu,
    css,
    formId,
    user,
    onFormSave,
    magneticGrid,
    setMagneticGrid,
    magneticGridPartitions,
    setMagneticGridPartitions,
    layout,
    setLayout
}) {
    const [view, setView] = useState('');
    // '' == no action, 'left' == add page to left, 'right' == add page to right

    const [visibilityLoaded, setVisibilityLoaded] = useState(false); // refreshes visibility
    const [refreshVisibility, setRefreshVisibility] = useState(false); // refreshes visibility
    const [visibilityStatus, setVisibilityStatus] = useState(0);

    const [visibilityList, setVisibilityList] = useState([]);

    const [editabilityLoaded, setEditabilityLoaded] = useState(false); // refreshes editability
    const [refreshEditability, setRefreshEditability] = useState(false); // refreshes editability
    const [editabilityStatus, setEditabilityStatus] = useState(0);
    const [editabilityList, setEditabilityList] = useState([]);

    const [isAddingGroup, setIsAddingGroup] = useState(false);
    const [isAddingEditGroup, setIsAddingEditGroup] = useState(false);

    const [members, setMembers] = useState([]);

    // useEffect(() => {
    //     console.log('form width', reference.current ? reference.current.offsetWidth : window.innerWidth * 0.6);
    //   }, [reference.current]);

    if (!visibilityLoaded) {
        if (pages[currentPage].visibleTo === 'all') {
            setVisibilityStatus(0);
        } else {
            setVisibilityStatus(1);
            setVisibilityList(pages[currentPage].visibleTo);
        } setVisibilityLoaded(true);
    }

    if (refreshVisibility) {
        if (pages[currentPage].visibleTo === 'all') {
            setVisibilityStatus(0);
        } else {
            setVisibilityStatus(1);
            setVisibilityList(pages[currentPage].visibleTo);
        } setRefreshVisibility(false);
    }

    if (!editabilityLoaded) {
        if (pages[currentPage].editableTo === 'all') {
            setEditabilityStatus(0);
        } else {
            setEditabilityStatus(1);
            setEditabilityList(pages[currentPage].editableTo);
        } setEditabilityLoaded(true);
    }

    if (refreshEditability) {
        if (pages[currentPage].editableTo === 'all') {
            setEditabilityStatus(0);
        } else {
            setEditabilityStatus(1);
            setEditabilityList(pages[currentPage].editableTo);
        } setRefreshEditability(false);
    }

    let selected_members_render = [];
    let members_render = [];

    let selected_edit_members_render = [];
    let edit_members_render = [];

    try {
        for (let k = 0; k < visibilityList.length; ++k) {
            selected_members_render.push(
                <div style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    width: '90%',
                    marginLeft: '5%',
                    padding: '1vmax 0',
                    textAlign: 'center',
                    border: '1pt solid #faf3dd',
                    marginTop: k === 0 ? '2vmax' : '0'
                }}>
                    {visibilityList[k]}
                    <a className='panel-button' style={{
                        marginLeft: '1vmax',
                        border: '0pt',
                        borderLeft: '1pt solid #faf3dd',
                        padding: '0.5vmax 1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        let new_visibility_list = [...visibilityList];
                        new_visibility_list.splice(k, 1);
                        setVisibilityList(new_visibility_list);
                    }}>Delete</a>
                </div>
            );
        }

        for (let k = 0; k < members.length; ++k) {
            members_render.push(
                <a className='panel-button' style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    padding: '1vmax',
                    width: '90%'
                }} onClick={(e) => {
                    e.preventDefault();
                    if (!visibilityList.includes(members[k].name)) {
                        let new_visibility_list = [...visibilityList];
                        new_visibility_list.push(members[k].name);
                        setVisibilityList(new_visibility_list);
                        setIsAddingGroup(false);
                    }
                }}>{members[k].name}</a>
            );
        }

        for (let k = 0; k < editabilityList.length; ++k) {
            selected_edit_members_render.push(
                <div style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    width: '90%',
                    marginLeft: '5%',
                    padding: '1vmax 0',
                    textAlign: 'center',
                    border: '1pt solid #faf3dd',
                    marginTop: k === 0 ? '2vmax' : '0'
                }}>
                    {editabilityList[k]}
                    <a className='panel-button' style={{
                        marginLeft: '1vmax',
                        border: '0pt',
                        borderLeft: '1pt solid #faf3dd',
                        padding: '0.5vmax 1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        let new_editability_list = [...editabilityList];
                        new_editability_list.splice(k, 1);
                        setEditabilityList(new_editability_list);
                    }}>Delete</a>
                </div>
            );
        }

        for (let k = 0; k < members.length; ++k) {
            edit_members_render.push(
                <a className='panel-button' style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    padding: '1vmax',
                    width: '90%'
                }} onClick={(e) => {
                    e.preventDefault();
                    if (!editabilityList.includes(members[k].name)) {
                        let new_editability_list = [...editabilityList];
                        new_editability_list.push(members[k].name);
                        setEditabilityList(new_editability_list);
                        setIsAddingEditGroup(false);
                    }
                }}>{members[k].name}</a>
            );
        }
    } catch (err) {

    }

    function wrap(str) {
        return 'calc(' + str + ')';
    }


    // let boundaryHeight = document.getElementById("form-view")?.offsetWidth;
    // if (boundaryHeight) {
    //     boundaryHeight = boundaryHeight * 1.414;
    // }
    return (

        <div id='fv' className="form-designer">
            {/* <div className="formDesignerBoundary"> */}
            <div ref={reference} className='form-view' id="form-view" style={{
                width: wrap(size.w),
                // height: (pages[currentPage].height * 60).toString() + 'vw',

                // new way to precisely set page height -- not here before. 
                height: (pages[currentPage].height * (reference.current ? reference.current.offsetWidth : window.innerWidth * 0.6)).toString() + 'px',
                // backgroundColor: '#ccc',
            }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedEl(-1);
                    setContextMenu([false, -1, [0, 0]]);
                }}>
                {content}
            </div>
            {/* </div> */}
            {/* <span className="boundaryInfo" style={{ top: boundaryHeight }}>PDF Limit</span> */}
            <div className="form-designer-footer" style={{
                marginTop: '10px'
            }}>
                <div style={{
                    color: '#FAF3DD',
                    textAlign: 'center',
                    padding: 5
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <i className="fa-solid fa-angle-left panel-button" style={{
                            fontSize: '1.5vmax'
                        }} onClick={() => {
                            if (currentPage !== 0) {
                                setCurrentPage(currentPage - 1);
                                setVisibilityLoaded(false);
                                setEditabilityLoaded(false);
                            } else {
                                setView('left');
                            }
                        }}></i>

                        {layouts.includes(layout) ? <></> : <div>
                            <i className="fa-solid fa-angle-up panel-button" style={{
                                fontSize: '1.5vmax'
                            }} onClick={() => {
                                if (pages[currentPage].height >= 0.3) {
                                    let new_pages = [...pages];
                                    new_pages[currentPage].height -= .1;
                                    setPages(new_pages);
                                }
                            }}></i>
                            &nbsp;&nbsp;

                            <i className="fa-solid fa-angle-down panel-button" style={{
                                fontSize: '1.5vmax'
                            }} onClick={() => {
                                let new_pages = [...pages];
                                new_pages[currentPage].height += .1;
                                setPages(new_pages);
                            }}></i>
                        </div>}

                        <i className="fa-solid fa-angle-right panel-button" style={{
                            fontSize: '1.5vmax'
                        }} onClick={() => {
                            if (currentPage !== pages.length - 1) {
                                setCurrentPage(currentPage + 1);
                                setVisibilityLoaded(false);
                                setEditabilityLoaded(false);
                            } else {
                                setView('right');
                            }
                        }}></i>
                    </div>

                    <br />

                    <div style={{
                        marginBottom: '5vmax',
                    }}>
                        {
                            (pages != null && pages[currentPage] != null) ?
                                <SharePage onFormSave={onFormSave} formId={formId} user={user} page={pages[currentPage]}
                                            magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                                            magneticGridPartitions={magneticGridPartitions}
                                            setMagneticGridPartitions={setMagneticGridPartitions} /> : null
                        }

                        <p className='panel-label'>Page {currentPage + 1}/{pages.length}</p>
                    </div>
                </div>

                {
                    view === 'left' ? <Modal width='15' height='15'>
                        <p style={{
                            fontWeight: '400',
                            fontSize: '1.25vmax',
                            marginTop: '3vh'
                        }}>Create new page?</p>
                        <a className='panel-button' style={{
                            marginRight: '1vmax'
                        }} onClick={() => {
                            let new_comps = [...comps];
                            for (let k = 0; k < new_comps.length; k++) {
                                new_comps[k].page += 1;
                            } setComps(new_comps);
                            let new_pages = [...pages];
                            new_pages.splice(0, 0, {
                                page_id: FormService.getGUID(),
                                height: getHeight('A4', 0.7),
                                visibleTo: 'all',
                                editableTo: 'all'
                            }); setPages(new_pages);
                            setView('');
                            setVisibilityLoaded(false);
                            setEditabilityLoaded(false);
                        }}>Yes</a>
                        <a className='panel-button' onClick={() => {
                            setView('');
                        }}>No</a>
                    </Modal> : <></>
                }

                {
                    view === 'right' ? <Modal width='15' height='15'>
                        <p style={{
                            fontWeight: '400',
                            fontSize: '1.25vmax',
                            marginTop: '3vh'
                        }}>Create new page?</p>
                        <a className='panel-button' style={{
                            marginRight: '1vmax'
                        }} onClick={() => {
                            let new_pages = [...pages];
                            new_pages.push({
                                page_id: FormService.getGUID(),
                                height: getHeight('A4', 0.7),
                                visibleTo: 'all',
                                editableTo: 'all'
                            }); setPages(new_pages);
                            setCurrentPage(currentPage + 1);
                            setView('');
                            setVisibilityLoaded(false);
                            setEditabilityLoaded(false);
                        }}>Yes</a>
                        <a className='panel-button' onClick={() => {
                            setView('');
                        }}>No</a>
                    </Modal> : <></>
                }


            </div>
        </div>
    );

}
