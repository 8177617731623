// Production Server
// export const baseUrl = `https://stpc.satet.us/api`;
// export const managementUrl = "https://stpc.satet.us/task-management/api"
// export const spcUrl = "https://stpc.satet.us/api/v1";

// Dev Server
// export const spcUrl = "http://127.0.0.1:8000/api/v1";
// export const baseUrl = `http://127.0.0.1:8000/api`;
// export const managementUrl = "http://127.0.0.1:8000/task-management/api";

// Stage Server

// export const spcUrl = "https://staging.satet.us/api/v1";
// export const baseUrl = `https://staging.satet.us/api`;
// export const managementUrl = "https://staging.satet.us/task-management/api";


// Server using env
export const appUrl = `${process.env.REACT_APP_API}`;
export const spcUrl = `${process.env.REACT_APP_API}/api/v1`;
export const baseUrl = `${process.env.REACT_APP_API}/api`;
export const managementUrl = `${process.env.REACT_APP_API}/task-management/api`;
export const cyclicJobUrl = `${process.env.REACT_APP_API}/cyclic/job/api`;
export const reportUrl = `${process.env.REACT_APP_API}/api/v1/task-management/reports`;
// export const formsUrl = `https://atlas-api.satet.us`;
export const formsUrl = `${process.env.REACT_APP_API}/api/v1/forms`;