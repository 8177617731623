import {
  BOARDS,
  PRIORITY,
  BOARD_LIST,
  BOARDS_ADD,
  BOARD_LIST_UPDATE,
  UPDATE_LIST_ITEMS,
  COMMENTS_DATA,
  LIST,
  LIST_ITEM,
  CHECKLIST,
  MEMBER_DATA,
  COOWNERS_DATA,
  FLAGS_DATA,
  CHECKLIST_ITEM,
  UPDATE_ATTACHMENTS,
  FILTERS,
  RESET_REPORTS,
  BOARD_CATEGORY
} from "../types";
import { PURGE } from 'redux-persist';

const initialState = {
  board: null,
  boards: [],
  list: null,
  item: null,
  comments: [],
  checklists: [],
  members: [],
  co_owners: [],
  flags: [],
  priorities: [],
  categories: [],
  filters: null
}

function boards(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET_REPORTS:
      return initialState
    case PURGE:
      return initialState
    case BOARD_CATEGORY:
      return {
        ...state,
        categories: payload
      };
    case BOARDS:
      return {
        ...state,
        boards: payload
      };
    case BOARDS_ADD:
      return {
        ...state,
        boards: payload
      }
    case BOARD_LIST:
      return {
        ...state,
        board: payload
      }
    case BOARD_LIST_UPDATE:
      return {
        ...state,
        board: {
          ...state.board,
          lists: payload
        }
      }
    case LIST:
      return {
        ...state,
        list: payload
      }
    case UPDATE_LIST_ITEMS:
      return {
        ...state,
        board: {
          ...state.board,
          lists: payload
        }
      }
    case COMMENTS_DATA:
      return {
        ...state,
        comments: payload
      }
    case LIST_ITEM:
      let x = {
        ...state,
        item: payload,
        timestamp: new Date().getTime()
      }
      return x
    case UPDATE_ATTACHMENTS:
      let attach = {
        ...state,
        item: {
          ...state.item,
          attachements: payload
        }
      }
      return attach
    case CHECKLIST:
      return {
        ...state,
        checklists: payload
      }
    case CHECKLIST_ITEM:
      let nestedState = state.checklists[payload.index]
      nestedState.items = payload.items
      return state
    case MEMBER_DATA:
      return {
        ...state,
        members: payload
      }
    case COOWNERS_DATA:
      return {
        ...state,
        co_owners: payload
      }
    case FLAGS_DATA:
      return {
        ...state,
        flags: payload
      }
    case PRIORITY:
      return {
        ...state,
        priorities: payload
      }
    case FILTERS:
      return {
        ...state,
        filters: payload
      }
    default:
      return state;
  }
}

export default boards;