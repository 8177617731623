import { FORMS, RECORDS, RESET_REPORTS, FORMS_LOADING } from "../types";
import { PURGE } from 'redux-persist';


const initialState = {
    forms: [],
    form: null,
    records: [],
    loading: false
}

function forms(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case RESET_REPORTS:
            return initialState
        case PURGE:
            return initialState
        case FORMS:
            return {
                ...state,
                forms: payload
            };
        case RECORDS:
            return {
                ...state,
                records: payload
            };
        case FORMS_LOADING:
            return {
                ...state,
                loading: payload
            };
        default:
            return state;
    }
}

export default forms;