import React from "react";
import { Container } from "reactstrap";
import TaskDetailPage from '../../components/board-list/task_detail';
import { connect } from "react-redux";

const TaskDetail = () => {
	return (
		<Container fluid={true}>
			<TaskDetailPage/>
		</Container>
	);
};

export default connect()(TaskDetail);
