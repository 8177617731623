
import ActionPanel from "./ActionPanel";
import ListElement from "./ListElement";

// components
import { LabelProperties } from "../primitive/Label";
import { BoxProperties } from "../primitive/Box";
import { InputProperties } from "../primitive/Input";
import { ButtonProperties } from "../primitive/Button";
import { RadioProperties } from "../primitive/Radio";
import { CheckProperties } from "../primitive/Check";
import { ListProperties } from "../primitive/List";
import { DropProperties } from "../primitive/Drop";
import { ImageProperties } from "../primitive/Image";

import { SignatureProperties } from "../intermediate/Signature";
import { InputTableProperties } from "../intermediate/InputTable";
import { SnapshotProperties } from "../intermediate/Snapshot";

import { Card } from 'antd';



export default function ControlPanel({
    comps,
    setComps,
    sel,
    setSel,
    hov,
    setHov,
    pages,
    setPages,
    currentPage,
    setCurrentPage,
    vars,
    setVars,
    externals,
    setExternals,
    parent,
    setParent,
    name,
    setName,
    dbId,
    setDbId,
    pageId,
    onFormSaveCallback,
    layout,
    setLayout
}) {

    let properties = <></>;
    if (sel === -1) {
        properties = <p style={{
            // color: '#FAF3DD',
            fontSize: '1vmax',
            marginTop: '10vh',
            fontWeight: '300'
        }}>Empty</p>
    } else {
        if (comps[sel].type === 'primitive/Label') {
            properties = <LabelProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'primitive/Box') {
            properties = <BoxProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'primitive/Input') {
            properties = <InputProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'primitive/Button') {
            properties = <ButtonProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'primitive/Radio') {
            properties = <RadioProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'primitive/Check') {
            properties = <CheckProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'primitive/List') {
            properties = <ListProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'primitive/Drop') {
            properties = <DropProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'primitive/Image') {
            properties = <ImageProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'intermediate/Signature') {
            properties = <SignatureProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'intermediate/InputTable') {
            properties = <InputTableProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        } else if (comps[sel].type === 'intermediate/Snapshot') {
            properties = <SnapshotProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} externals={externals} setExternals={setExternals} />;
        }
    }

    let elements = [];
    for (let k = 0; k < comps.length; ++k) {
        if (comps[k].page === currentPage) {
            elements.push(
                <ListElement id={k} comps={comps} setComps={setComps} sel={sel} setSel={setSel}
                    hov={hov} setHov={setHov} />
            );
        }
    } if (elements.length === 0) {
        elements = <p style={{
            // color: '#FAF3DD',
            fontSize: '1vmax',
            marginTop: '10vh',
            fontWeight: '300'
        }}>Empty</p>;
    }

    return (
        <div className="form-control-box">
            <Card className="card1" title="Properties" bordered={false}>
                {properties}
            </Card>

            <Card className="card2" title="Elements" bordered={false}>
                <div>
                    {elements}
                </div>
            </Card>

            <ActionPanel
                setSelectedEl={setSel}
                comps={comps} setComps={setComps} page={currentPage} setPage={setCurrentPage}
                pages={pages} setPages={setPages} vars={vars} setVars={setVars}
                externals={externals} setExternals={setExternals} parent={parent} setParent={setParent}
                name={name} setName={setName} dbId={dbId} setDbId={setDbId} pageId={pageId} onFormSaveCallback={onFormSaveCallback} layout={layout} setLayout={setLayout} />
        </div>
    );

}