import { QrcodeOutlined } from '@ant-design/icons';
import { Card, Col, Input, Row, Tabs } from 'antd';
import React, { useState } from "react";
import { connect } from 'react-redux';
import Forms from '../../components/forms';
import Records from '../../components/forms/records';
import QRScaner from './QRScaner';

let timer = null;

const FormsPage = (props) => {
    const [formQuery, setFormQuery] = useState('');
    const [formType, setFormType] = useState('all');
    const [refreshRecords, setRefreshRecords] = useState("");
    const [openQRModel, setOpenQRModel] = useState(false);


    const search = (event) => {
        if (timer != null) {
            window.clearTimeout(timer);
        }
        timer = window.setTimeout(function () {
            setFormQuery(event.target.value);
        }, 500);
    };

    const onQROk = () => {

    }
    const onQRScanned = (result) => {
        setOpenQRModel(false);
        // setShowRecordModal(true);
        // setQRCode(result);
        window.location = result;
    }


    return (
        <div>
            {
                openQRModel ? (
                    <QRScaner onQRScanned={onQRScanned} onClosed={() => {
                        setOpenQRModel(false);
                    }} />
                    // <Modal width={370} title="Scan QR Code" visible={true} destroyOnClose={true} onOk={onQROk} onCancel={() => {
                    //     setOpenQRModel(false);
                    // }} footer={null}>
                    //     <div style={{ textAlign: 'center' }}>
                    //         <QRScaner onQRScanned={onQRScanned} />
                    //     </div>
                    // </Modal>
                ) : null
            }

            {/* <Modal title={"Record"} visible={showRecordModal} destroyOnClose={true} footer={null}>
                <h1>{qrCode}</h1>

            </Modal> */}

            <Row gutter={[8, 8]}>
                <Col md={12}>
                    <Card title={
                        <div>
                            Forms
                            <div style={{ float: 'right' }}>
                                {/* <input type="text" placeholder="Search..." onKeyUp={search} /> */}

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <Input
                                            placeholder="Search..."
                                            // onKeyUp={search}
                                            onChange={search}
                                            allowClear
                                            style={{
                                                width: 200,
                                            }}
                                        />
                                    </div>
                                    <div onClick={() => {
                                        setOpenQRModel(true);
                                        // history.push('/qr-scan');
                                    }}>
                                        <QrcodeOutlined style={{ color: '#0084ff', fontSize: 32, cursor: 'pointer' }} />
                                    </div>
                                </div>

                                &nbsp;&nbsp;
                                {/* <Radio.Group defaultValue={formType} size="small" onChange={(e) => {
                                    setFormType(e.target.value);
                                }} buttonStyle="solid">
                                    <Radio.Button size="small" value="all">Active</Radio.Button>
                                    {
                                        props?.user?.groups.includes('Branch admin') ? (
                                            <Radio.Button value="is_archived">Archived</Radio.Button>
                                        ) : null
                                    }

                                    <Radio.Button danger={true} value="is_deleted">Trash</Radio.Button>
                                </Radio.Group> */}
                            </div>
                        </div>
                    } bordered={true}>
                        <Tabs defaultActiveKey="all" destroyInactiveTabPane={true} centered={true} onChange={(key) => {
                            setFormType(key);
                        }}>
                            <Tabs.TabPane tab="Active" key="all">
                                <div style={{ height: '71vh', overflowY: "auto" }}>
                                    <Forms formType={'all'} formQuery={formQuery} setRefreshRecords={setRefreshRecords} />
                                </div>
                            </Tabs.TabPane>

                            {
                                props?.user?.groups.includes('Branch Admin') ? (

                                    <Tabs.TabPane tab="Archived" key="is_archived">
                                        <div style={{ height: '70vh', overflowY: "auto" }}>
                                            <Forms formType={'is_archived'} formQuery={formQuery} setRefreshRecords={setRefreshRecords} />
                                        </div>
                                    </Tabs.TabPane>
                                ) : null
                            }

                            <Tabs.TabPane tab="Trash" key="is_deleted">
                                <div style={{ height: '70vh', overflowY: "auto" }}>
                                    <Forms formType={'is_deleted'} formQuery={formQuery} setRefreshRecords={setRefreshRecords} />
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </Card>
                </Col>
                <Col md={12}>
                    <Card title="Recent Records" bordered={true} style={{ height: '90vh' }}>
                        <Records withCard={true} formType={formType} refresh={refreshRecords} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}


function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    }
}
export default connect(mapStateToProps)(FormsPage);

// export default FormsPage;