import axios from "axios";
import authHeader from "./auth-header";
import { managementUrl } from "./base";

class BoardService {
  manageBoardMember(payload) {
    return axios
      .post(`${managementUrl}/board/member`, payload,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getBoardMembers(payload) {
    return axios
      .get(`${managementUrl}/board/member`,
        { params: payload, headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getFilters(payload) {
    return axios
      .get(`${managementUrl}/filters`,
        { params: payload, headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allBoards() {
    return axios
      .get(`${managementUrl}/boards/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allCategories() {
    return axios
      .get(`${managementUrl}/board/categories`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allPriority() {
    return axios
      .get(`${managementUrl}/priorities/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getFlags() {
    return axios
      .get(`${managementUrl}/flags`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createBoard(data) {
    return axios
      .post(`${managementUrl}/boards/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteBoard(id) {
    return axios
      .delete(`${managementUrl}/boards/${id}/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getBoard(id) {
    return axios
      .get(`${managementUrl}/boards/${id}`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createList(data) {
    return axios
      .post(`${managementUrl}/list/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getListDetail(id) {
    return axios.get(`${managementUrl}/lists/${id}/`, { headers: authHeader() });
  }

  updateList(id, data) {
    return axios
      .put(`${managementUrl}/lists/${id}/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data
      });
  }

  archiveListItem(listId) {
    return axios.get(`${managementUrl}/lists/${listId}/?archive=1`,
      { headers: authHeader() })
      .then((response) => {
        debugger;
        return response.data;
      });
  }

  deleteList(id) {
    return axios
      .delete(`${managementUrl}/lists/${id}/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }


  createListItem(data) {
    return axios
      .post(`${managementUrl}/item/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
  }

  deleteListItem(id) {
    return axios
      .delete(`${managementUrl}/item/${id}/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createComments(data) {
    return axios
      .post(`${managementUrl}/comments/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateComment(id, data) {
    return axios
      .put(`${managementUrl}/comments/${id}/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteComment(id) {
    return axios
      .delete(`${managementUrl}/comments/${id}/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getListItem(id) {
    return axios
      .get(`${managementUrl}/item/${id}/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });

  }

  getComments(id) {
    return axios
      .get(`${managementUrl}/comments/?item=${id}`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createChecklists(data) {
    return axios
      .post(`${managementUrl}/checklists/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateChecklist(id, data) {
    return axios
      .put(`${managementUrl}/checklist/${id}/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteCheckList(id) {
    return axios
      .delete(`${managementUrl}/checklist/${id}/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getChecklists(id) {
    return axios
      .get(`${managementUrl}/checklists/?item=${id}`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  createChecklistItem(data) {
    return axios
      .post(`${managementUrl}/checklist/item/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateChecklistItem(id, data) {
    return axios
      .put(`${managementUrl}/checklist/item/${id}/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteCheckListItem(id) {
    return axios
      .delete(`${managementUrl}/checklist/item/${id}/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allMembers(member_type, query = "") {
    const url = `${managementUrl}/members/?m_type=${member_type}&query=${query}`
    return axios
      .get(url,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  updateItem(id, data) {
    return axios
      .put(`${managementUrl}/item/${id}/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data
      });
  }

  updateBoard(id, data) {
    return axios
      .put(`${managementUrl}/boards/${id}/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response
      });
  }

  createAttachment(data) {
    return axios
      .post(`${managementUrl}/attachments/`, data,
        { headers: authHeader(), 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        return response.data;
      });
  }

  addUrl(data, itemId) {
    return axios
      .post(`${managementUrl}/urls/?item=${itemId}`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data
      });
  }

  updateAttachements(id, data) {
    return axios
      .put(`${managementUrl}/attachments/${id}/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data
      });
  }



  deleteUrl(id) {
    return axios
      .delete(`${managementUrl}/url/${id}/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  deleteAttachment(id) {
    return axios
      .delete(`${managementUrl}/attachments/${id}/`,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  copyMove(itemId, data) {
    return axios
      .post(`${managementUrl}/item/${itemId}/copy-move/`, data,
        { headers: authHeader() })
      .then((response) => {
        return response.data
      });
  }
}

export default new BoardService();