import { MESSAGE_ERROR } from '../message_error';
import { MESSAGES } from "../messages";
import {
  BOARDS,
  BOARDS_ADD,
  BOARD_CATEGORY,
  BOARD_LIST,
  BOARD_LIST_UPDATE,
  CHECKLIST,
  COMMENTS_DATA,
  COOWNERS_DATA,
  FILTERS,
  FLAGS_DATA,
  LIST_ITEM,
  MEMBER_DATA,
  PRIORITY,
  UPDATE_ATTACHMENTS,
  UPDATE_LIST_ITEMS,
  UPDATE_URLS
} from "../types";

import { message } from "antd";
import BoardService from "../services/boards";
const key = 'updatable';

export const GetFilters = (payload) => (dispatch) => {

  return BoardService.getFilters(payload).then(
    (data) => {
      dispatch({
        type: FILTERS,
        payload: data
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch);
        return Promise.reject();
      }
    );
};

export const AllBoards = () => (dispatch) => {
  message.loading({ content: MESSAGES.boards_loading, key, duration: 1 });
  return BoardService.allBoards().then(
    (data) => {
      dispatch({
        type: BOARDS,
        payload: data.results
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const CreateBoard = (data) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let { boards } = getState().boards
  return BoardService.createBoard(data).then(
    (response) => {
      let new_array = [...boards]
      new_array.push(response)
      dispatch({
        type: BOARDS_ADD,
        payload: new_array
      });
      message.success({ content: MESSAGES.board_created, key });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const UpdateBoard = (id, data) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let { boards } = getState().boards
  return BoardService.updateBoard(id, data).then(
    (response) => {
      let new_array = [...boards]
      let parentIndex = new_array.findIndex(el => el.id === id)
      new_array[parentIndex] = response.data
      dispatch({
        type: BOARDS,
        payload: new_array
      });
      message.success({ content: MESSAGES.board_updated, key })
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const DeleteBoard = (board) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let { boards } = getState().boards
  return BoardService.deleteBoard(board.id).then(
    (response) => {
      let _boards = [...boards]
      let index = _boards.findIndex(el => el.id === board.id)
      _boards.splice(index, 1);
      dispatch({
        type: BOARDS_ADD,
        payload: _boards
      });
      message.success({ content: MESSAGES.board_deleted, key });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const GetBoard = (id) => (dispatch) => {
  message.loading({ content: MESSAGES.board_loading, key, duration: 1 });
  return BoardService.getBoard(id).then(
    (data) => {
      dispatch({
        type: BOARD_LIST,
        payload: data
      });
      return Promise.resolve()
    },
    (error) => {
      MESSAGE_ERROR(error, dispatch)
      return Promise.reject();
    }
  );
};

export const CreateList = (data) => (dispatch, getState) => {
  let state = getState().boards
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.createList(data).then(
    (response) => {
      let board_list = state.board.lists
      board_list.splice(board_list.length - 1, 0, response);
      dispatch({
        type: BOARD_LIST_UPDATE,
        payload: board_list
      });
      message.success({ content: MESSAGES.list_created, key, duration: 4 });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const ArchiveListItem = (listId) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let state = getState().boards

  return BoardService.archiveListItem(listId).then(
    (response) => {
      debugger;
      let board_list = state.board.lists
      let index = board_list.findIndex(el => el.id === listId);
      board_list[index] = response;
      // board_list.items = response.data.items;
      dispatch({
        type: BOARD_LIST_UPDATE,
        payload: board_list
      });

      message.success({ content: MESSAGES.list_item_archived, key });
      return Promise.resolve();
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch);
        return Promise.reject();
      }
    );

}

export const DeleteList = (listId) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let state = getState().boards
  return BoardService.deleteList(listId).then(
    (response) => {
      let board_list = state.board.lists
      let index = board_list.findIndex(el => el.id === listId)
      board_list.splice(index, 1);
      dispatch({
        type: BOARD_LIST_UPDATE,
        payload: board_list
      });
      message.success({ content: MESSAGES.list_deleted, key });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const DeleteListItem = (listId, listItemId) => (dispatch, getState) => {
  let state = getState().boards
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.deleteListItem(listItemId).then(
    (response) => {
      let board_list = state.board.lists
      let listIndex = board_list.findIndex(el => el.id === listId);
      let itemIndex = board_list[listIndex].items.findIndex(el => el.id === listItemId)
      board_list[listIndex].items.splice(itemIndex, 1);
      dispatch({
        type: UPDATE_LIST_ITEMS,
        payload: board_list
      });
      message.success({ content: MESSAGES.item_deleted, key });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const CreateComments = (data) => (dispatch, getState) => {
  let { comments } = getState().boards
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.createComments(data).then(
    (response) => {
      let new_array = [...comments]
      new_array.splice(0, 0, response)
      dispatch({
        type: COMMENTS_DATA,
        payload: new_array
      });
      message.success({ content: MESSAGES.comment_created, key })
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const UpdateComment = (id, data) => (dispatch, getState) => {
  let { comments } = getState().boards
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.updateComment(id, data).then(
    (response) => {
      let new_array = [...comments]
      let index = new_array.findIndex(el => el.id === id)
      new_array[index] = response
      dispatch({
        type: COMMENTS_DATA,
        payload: new_array
      });
      message.success({ content: MESSAGES.comment_updated, key })
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const DeleteComment = (id) => (dispatch, getState) => {
  let { comments } = getState().boards
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.deleteComment(id).then(
    (response) => {
      let new_array = [...comments]
      let index = new_array.findIndex(el => el.id === id)
      new_array.splice(index, 1)
      dispatch({
        type: COMMENTS_DATA,
        payload: new_array
      });
      message.success({ content: MESSAGES.comment_deleted, key });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};



export const AllCategories = () => (dispatch) => {
  return BoardService.allCategories().then(
    (data) => {
      dispatch({
        type: BOARD_CATEGORY,
        payload: data.results
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};


export const AllPriority = () => (dispatch) => {
  return BoardService.allPriority().then(
    (data) => {
      dispatch({
        type: PRIORITY,
        payload: data.results
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const GetFlags = () => (dispatch) => {
  return BoardService.getFlags().then(
    (data) => {
      dispatch({
        type: FLAGS_DATA,
        payload: data.results
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const GetListItem = (id) => (dispatch) => {
  message.loading({ content: MESSAGES.item_loading, key, duration: 1 });
  return BoardService.getListItem(id).then(
    (data) => {
      dispatch({
        type: LIST_ITEM,
        payload: data
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const GetComments = (id) => (dispatch) => {
  return BoardService.getComments(id).then(
    (data) => {
      dispatch({
        type: COMMENTS_DATA,
        payload: data.results
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const CreateChecklist = (data) => (dispatch, getState) => {
  let { checklists } = getState().boards
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.createChecklists(data).then(
    (response) => {
      let new_array = [...checklists]
      new_array.push(response)
      message.success({ content: MESSAGES.checklist_created, key })
      dispatch({
        type: CHECKLIST,
        payload: new_array
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const UpdateCheckist = (id, data) => (dispatch, getState) => {
  let { checklists } = getState().boards
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.updateChecklist(id, data).then(
    (response) => {
      let new_array = [...checklists]
      let index = new_array.findIndex(el => el.id === id)
      new_array[index] = response
      message.success({ content: MESSAGES.checklist_updated, key })
      dispatch({
        type: CHECKLIST,
        payload: new_array
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const DeleteChecklist = (id) => (dispatch, getState) => {
  let { checklists } = getState().boards
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.deleteCheckList(id).then(
    (response) => {
      let array = [...checklists]
      let rem_index = array.findIndex(el => el.id === response.id)
      array.splice(rem_index, 1)
      dispatch({
        type: CHECKLIST,
        payload: array
      });
      message.success({ content: MESSAGES.checklist_deleted, key });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const GetChecklists = (id) => (dispatch) => {
  return BoardService.getChecklists(id).then(
    (data) => {
      dispatch({
        type: CHECKLIST,
        payload: data.results
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const CreateChecklistItem = (data) => (dispatch) => {
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.createChecklistItem(data).then(
    (response) => {
      message.success({ content: MESSAGES.checklist_item_created, key })
      return response
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const UpdateChecklistItem = (id, data) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.updateChecklistItem(id, data).then(
    (response) => {
      message.success({ content: MESSAGES.checklist_item_updated, key })
      let checklists = [...getState().boards.checklists]
      let checklistIndex = checklists.findIndex(el => el.id === data.checklist)
      let checklistItemIndex = checklists[checklistIndex].items.findIndex(el => el.id === id)
      checklists[checklistIndex].items[checklistItemIndex] = response
      dispatch({
        type: CHECKLIST,
        payload: checklists
      });
      return Promise.resolve();
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const DeleteChecklistItem = (id) => (dispatch) => {
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.deleteCheckListItem(id).then(
    (response) => {
      message.success({ content: MESSAGES.checklist_item_deleted, key });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const AllMembers = () => (dispatch) => {
  return BoardService.allMembers("members").then(
    (data) => {
      dispatch({
        type: MEMBER_DATA,
        payload: data.results
      });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const AllCoOwners = () => (dispatch) => {
  return BoardService.allMembers("co_owner").then(
    (data) => {
      dispatch({
        type: COOWNERS_DATA,
        payload: data.results
      });
      // message.loading({content: 'members fetched', key})
      return Promise.resolve()
    }).catch(
      (error) => {
        const message_resp =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        message.error({ content: message_resp, key })
        // dispatch({
        //   type: SET_MESSAGE,
        //   payload: { message: message, status: error.response.status },
        // });

        return Promise.reject();
      }
    );
};

export const CreateListItem = (data) => (dispatch, getState) => {
  let state = getState().boards
  message.loading({ content: MESSAGES.request_processing, key });
  return BoardService.createListItem(data)
    .then(
      (response) => {
        let board_list_item = state.board.lists
        let index = board_list_item.findIndex(el => el.id === data.list)
        board_list_item[index].items.push(response)
        dispatch({
          type: UPDATE_LIST_ITEMS,
          payload: board_list_item
        });
        message.success({ content: MESSAGES.item_created, key, duration: 4 })
        return Promise.resolve()
      }).catch(
        (error) => {
          MESSAGE_ERROR(error, dispatch)
          return Promise.reject();
        });
};

export const UpdateItem = (listId, id, data) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let state = getState().boards;

  return BoardService.updateItem(id, data).then(
    (response) => {
      if (listId != null && listId > 0) {
        let board_list = state.board.lists;
        let list_index = board_list.findIndex(el => el.id == listId);
        let items = board_list[list_index].items;
        let item_index = items.findIndex(el => el.id == id);

        board_list[list_index].items[item_index] = response;

        dispatch({
          type: UPDATE_LIST_ITEMS,
          payload: board_list
        });
      }
      dispatch({
        type: LIST_ITEM,
        payload: response
      });
      message.success({ content: MESSAGES.item_updated, key });
      return response;
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const UpdateList = (id, data) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let state = getState().boards
  return BoardService.updateList(id, data).then(
    (response) => {
      let board_list = state.board.lists
      let index = board_list.findIndex(el => el.id === id)
      board_list[index] = response
      dispatch({
        type: BOARD_LIST_UPDATE,
        payload: board_list
      });
      message.success({ content: MESSAGES.list_updated, key })
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};



export const addUrl = (payload, itemId) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let state = getState().boards
  return BoardService.addUrl(payload, itemId).then(
    (response) => {
      let urls = state.item.urls;
      urls.push(response);
      dispatch({
        type: UPDATE_URLS,
        payload: urls
      });
      message.success({ content: MESSAGES.link_created, key })
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const DeleteUrl = (Id) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let state = getState().boards
  return BoardService.deleteUrl(Id).then(
    (response) => {
      let urls = state.item.urls;
      let index = urls.findIndex(el => el.id === Id);
      urls.splice(index, 1);
      dispatch({
        type: UPDATE_URLS,
        payload: urls
      });
      message.success({ content: MESSAGES.link_deleted, key })
      return Promise.resolve();
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};



export const UpdateAttachement = (id, data) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let state = getState().boards
  return BoardService.updateAttachements(id, data).then(
    (response) => {
      let attachments = state.item.attachments
      let index = attachments.findIndex(el => el.id === id)
      attachments[index] = response
      dispatch({
        type: UPDATE_ATTACHMENTS,
        payload: attachments
      });
      message.success({ content: MESSAGES.attachement_updated, key })
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const DeleteAttachment = (attachmentId) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let state = getState().boards
  return BoardService.deleteAttachment(attachmentId).then(
    (response) => {
      let attachments = state.item.attachments
      let index = attachments.findIndex(el => el.id === attachmentId)
      attachments.splice(index, 1);
      dispatch({
        type: UPDATE_ATTACHMENTS,
        payload: attachments
      });
      message.success({ content: MESSAGES.attachement_deleted, key });
      return Promise.resolve()
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};



export const CopyMove = (itemId, data) => (dispatch, getState) => {
  message.loading({ content: MESSAGES.request_processing, key });
  let state = getState().boards

  return BoardService.copyMove(itemId, data).then(
    (response) => {
      let messages = MESSAGES.move_card
      let board_list = state.board.lists
      let from_list_index = board_list.findIndex(el => el.id === data.from_list);
      let itemIndex = board_list[from_list_index].items.findIndex(el => el.id === itemId);
      if (data.action === "Copy") {
        messages = MESSAGES.copy_card
        let to_list_index = board_list.findIndex(el => el.id === data.list_id);
        if (to_list_index > -1) {
          let item_to_copy = board_list[from_list_index].items[itemIndex];
          board_list[to_list_index].items.push(item_to_copy);
          dispatch({
            type: UPDATE_LIST_ITEMS,
            payload: board_list
          });
        }
      }
      else {
        board_list[from_list_index].items.splice(itemIndex, 1);
        dispatch({
          type: UPDATE_LIST_ITEMS,
          payload: board_list
        });
      }
      message.success({ content: messages, key });
      return response
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};

export const GetBoardList = (id) => (dispatch) => {
  return BoardService.getBoard(id).then(
    (data) => {
      return data
    }).catch(
      (error) => {
        MESSAGE_ERROR(error, dispatch)
        return Promise.reject();
      }
    );
};