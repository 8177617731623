import { Button, Form, Input, Modal } from 'antd';
import axios from 'axios';
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";

import authHeader from "../../redux/services/auth-header";
import { baseUrl } from "../../redux/services/base";


const ChangePassword = (props) => {
    const history = useHistory();
    const handleCancel = () => {
        history.goBack();
    }


    const onFinish = (values) => {
        axios.post(`${baseUrl}/change-password/`, values, { headers: authHeader() }).then((response) => {
            alert('Password changed successfully.');
            history.goBack();
        });
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <Container fluid={true}>
            <Modal
                width="400px"
                visible={true}
                footer={null}
                onCancel={handleCancel}
                destroyOnClose={true}
                title={"Change Password"}
            >
                <div style={{ height: '200px' }}>
                    <Form name="basic" abelCol={{
                        span: 8,
                    }}
                        wrapperCol={{
                            span: 22,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Old Password&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                            name="old_password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your old password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="New Password&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                            name="new_password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your new password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Confirm Password"
                            name="new_password1"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please re-enter your new password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>



                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </Container>
    );
};

function mapStateToProps(state) {
    const { token } = state.auth;
    return {
        token
    }
}

export default connect(mapStateToProps)(ChangePassword);


