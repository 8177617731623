import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import SPCList from "../../components/spc";

// import { AllMembers } from '../../redux/actions/reports';
// import { GetFlags, AllPriority, AllCoOwners } from "../../redux/actions/boards";


const SPC = (props) => {

	// useEffect(() => {
	// 	props.dispatch(AllMembers('participients'));
	// 	props.dispatch(AllMembers('responsible_person'));
	// 	props.dispatch(AllMembers('board_owners'));
	// 	props.dispatch(AllMembers('board_co_owners'));
	// 	props.dispatch(AllPriority());
	// 	props.dispatch(GetFlags());
	// 	props.dispatch(AllCoOwners());
	// }, [])

	return (
		<Container fluid={true}>
			<SPCList />
		</Container>
	);
};

// export default SPC;
export default connect()(SPC);

