import React, { useState } from 'react';
import { Popover, Button, Input, Form } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import { CreateChecklist } from '../../redux/actions/boards';

const ChecklistCreate = (props) => {
    const [checklistForm] = Form.useForm(null);
    const [isSubmit, setIsSubmit] = useState(false)
    const [showChecklistForm, setShowChecklistForm] = useState(false)

    const checklistSubmit = async (event) => {
        event.preventDefault();
        checklistForm.validateFields()
            .then(values => {
                setIsSubmit(true)
                const data = {
                    item: props?.item?.id,
                    title: values.checklist
                }
                props.dispatch(CreateChecklist(data)).then(res => {
                    checklistForm.resetFields()
                    setIsSubmit(false)
                    setShowChecklistForm(false)
                }).catch(res => {
                    setIsSubmit(false);
                    setShowChecklistForm(false)
                })
            })
    };

    const checklistCreateForm = () => {
        return (
            <Form
                form={checklistForm}
                labelCol={{ span: 24 }}
                layout={"vertical"}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
            >
                <Form.Item label="" name="checklist" rules={[{ required: true, message: 'Checklist name is required!' }]}>
                    <Input type="text" autoFocus={true} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" loading={isSubmit} onClick={checklistSubmit}>
                        Add
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    return (
        <Popover
            content={checklistCreateForm}
            title="Checklist"
            trigger="click"
            placement={"rightTop"}
            visible={showChecklistForm}
            onVisibleChange={() => setShowChecklistForm(!showChecklistForm)}
        >
            <PlusCircleFilled />
        </Popover>
    );
};

function mapStateToProps(state) {
    const { item } = state.boards;
    return {
        item
    }
}

export default connect(mapStateToProps)(ChecklistCreate);