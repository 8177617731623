
import { useState, useEffect, useCallback } from "react";
import Modal from "../../utils/Modal";

import React from 'react';
// import { useSelector } from 'react-redux';
// import { selectId } from '../../../redux/features/idSlice';
// import { selectUsername } from "../../../redux/features/usernameSlice";
// import { selectToken } from '../../../redux/features/tokenSlice';
import { useNavigate } from "react-router-dom";
import { Modal as AntdModal, Button } from 'antd';
import { useHistory } from "react-router-dom";
import FormService from '../../../../redux/services/forms';

import { Radio, Select, Space } from 'antd';
import { layouts, getHeight, layoutToHeight } from "../../misc/layouts";

export default function Settings({
    comps,
    setComps,
    setView,
    pages,
    setPages,
    vars,
    externals,
    parent,
    name,
    setName,
    dbId,
    setDbId,
    onFormSaveCallback,
    layout,
    setLayout
}) {

    const [status, setStatus] = useState(0); // -1 => error, 0 => nothing, 1 => success, 2 => success with leave
    const username = '';
    const token = '';
    const history = useHistory();

    let form_obj = {
        name: name,
        content: comps,
        pages: pages,
        vars: vars,
        externals: externals,
        parent: parent
    };


    const onSaveForm = (e, exit) => {
        if (dbId === '') {
            FormService.createForm(form_obj).then(resp => {
                setDbId(resp.form_id);
                setStatus(1);
                window.alert('Your form was successfully saved.');
                if (exit == true) {
                    history.push('/forms');
                }
                else {
                    let form = resp;
                    history.push({
                        pathname: `/forms/${resp.form_id}/create`,
                        state: form
                    });

                    // history.push(`/loading?form_id=${resp.form_id}`);
                    setView('');
                    // window.location = `/forms/${resp.form_id}/create`;
                }
            }).catch(err => {
                setStatus(-1);
                window.alert('Your form could not be saved.');
            });
        } else {
            FormService.updateForm(dbId, form_obj).then(resp => {
                onFormSaveCallback();
                window.alert('Your form was successfully saved.');
                setStatus(1);
                if (exit == true) {
                    history.push('/forms');
                }
                else {
                    setView('');
                }
            }).catch(err => {
                setStatus(-1);
                window.alert('Your form could not be saved.');
            });
        }
    }

    return (
        <>
            <AntdModal title="Form Settings" visible={true} onOk={() => {
                setView('');
            }} onCancel={() => {
                setView('');
            }}
                footer={[
                    <Button type="primary" onClick={(e) => {
                        onSaveForm(e, false);
                    }}>
                        Save
                    </Button>,
                    <Button type="primary" onClick={(e) => {
                        onSaveForm(e, true);
                    }}>
                        Save & Exit
                    </Button>,
                    <Button type="primary" danger onClick={(e) => {
                        setView('');
                    }}>
                        Close
                    </Button>
                ]}
            >
                <p className="panel-label" style={{
                    fontSize: '1.1vmax',
                    fontWeight: '300',
                    marginTop: '2vmax',
                    display: 'inline-block'
                }}>Form Name:</p>
                <input className='panel-input' value={name} onChange={(e) => {
                    setName(e.target.value);
                }} />

                <br />
                <p className="panel-label" style={{
                    fontSize: '1.1vmax',
                    fontWeight: '300',
                    display: 'inline-block'
                }}>Page Layout:</p>
                <Radio.Group value={layout} onChange={(e) => {
                    if (layouts.includes(e.target.value)) {
                        let newPages = [...pages];
                        for (let k = 0; k < newPages.length; ++k) {
                            newPages[k].height = getHeight(e.target.value);
                        }
                        setPages(newPages);
                    }
                    setLayout(e.target.value);
                }} style={{
                    marginLeft: '0.2vmax'
                }}>
                    <Radio.Button value="None">None</Radio.Button>
                    <Radio.Button value="A4">A4</Radio.Button>
                </Radio.Group>
            </AntdModal>
        </>
    );

}