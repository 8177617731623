import React, { useEffect, useState } from "react";
import BoardDetail from '../../components/board-list/board_detail';

import { useParams } from "react-router-dom";
import { GetBoard} from "../../redux/actions/boards";
import { connect } from "react-redux";

const BoardListScreen = (props) => {
	
	const { board_id } = useParams()
	const [showBoardList, setShowBoardList] = useState(false)
	useEffect(() => {
        props.dispatch(GetBoard(board_id)).then(res => setShowBoardList(true));
    }, [board_id])
    

	return (
		<div>
			{showBoardList ? 
				<BoardDetail />
			:
				null
			}
		</div>
	);
};


export default connect(null, null)(BoardListScreen);
