import { SET_MESSAGE, CLEAR_MESSAGE } from "../types";

const initialState = {message: "", status: null};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      // return { message: payload.message, status: payload.status };

    case CLEAR_MESSAGE:
      return { message: "", status: null };

    default:
      return state;
  }
}