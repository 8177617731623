
const commandColor = '#a29bfe';

 // Has params: props.id, props.comps, props.setComps, props.indices, props.setIsScriptRendered
 export default function SelfValueCommand (props) {

    return <>
        <div style={{
            marginBottom: '2vmax',
            overflow: 'auto',
            display: 'inline-block',
            marginRight: '4vmax',
            verticalAlign: 'top'
        }}>
            <div style={{
                padding: '1vmax',
                backgroundColor: commandColor,
                color: '#000',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '500'
            }}>
                SELF
            </div>
            <a className='panel-button' style={{
                padding: '1vmax',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '300',
                border: '0pt',
                borderLeft: '1pt solid #faf3dd',
                marginLeft: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                let new_comps = [...props.comps];
                let thisObj = new_comps[props.id].script[props.indices[0]];
                for (let k = 0; k < props.indices.length - 2; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                } thisObj.children.splice(props.indices[props.indices.length - 1], 1);
                props.setComps(new_comps);
                props.setIsScriptRendered(false);
            }}>
                Delete
            </a>
            {/* Copy Command Icon */}
           <i className='fa-solid fa-copy pointy comp panel-button' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax'
           }} onClick={(e) => {
               e.preventDefault();
               let thisObj = props.comps[props.id].script[props.indices[0]];
               for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
               navigator.clipboard.writeText(JSON.stringify(thisObj));
           }}></i>
            <div style={{
                padding: '2vmax 0',
                borderLeft: `3pt solid ${commandColor}`,
                borderBottom: `1pt dashed ${commandColor}`,
                paddingLeft: '2vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '500',
                paddingRight: '2vmax',
                fontSize: '1vmax'
            }} className='code-block'>
                THIS ELEMENT'S VALUE
            </div>
        </div>
    </>;

}