import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { CreateChecklistItem, UpdateChecklistItem } from "../../redux/actions/boards";
import { CHECKLIST } from "../../redux/types";
import moment from 'moment';
const { TextArea } = Input
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
const { Option } = Select;

const CreateCheckListItem = (props) => {
    const { toggle, checklistItemId, checklists, checklistId, item, checkListForm } = props
    const [checklistItemForm] = Form.useForm(null);
    const [startDate, setStartDate] = useState('');
    const [dueDate, setDueDate] = useState('');


    useEffect(() => {
        if (checklistItemId !== -1) {
            let findChecklistIndex = checklists.findIndex(el => el.id === checklistId)
            let checkItemIndex = checklists[findChecklistIndex].items.findIndex(el => el.id === checklistItemId)
            let data = checklists[findChecklistIndex].items[checkItemIndex]
            if (data) {
                // debugger;
                // if (data?.start_date)
                setStartDate(data?.start_date)
                // if (data?.due_date)
                setDueDate(data?.due_date)

                let _itemFormData = {
                    desc: data?.body,
                    start_date: data?.start_date,
                    due_date: data?.due_date,
                    assign: data?.assigned_to?.id,
                    flag: data?.flag.id,
                    depend_on: data?.depend_on?.id,
                    dates: [
                        data?.start_date ? moment(data?.start_date, dateFormat) : null,
                        data?.due_date ? moment(data?.due_date, dateFormat) : null
                    ]
                }
                checklistItemForm.setFieldsValue(_itemFormData);
            }
        } else {
            let _itemFormData = {
                desc: "",
                start_date: "",
                due_date: "",
                assign: '',
                flag: '',
                depend_on: ''
            };
            checklistItemForm.setFieldsValue(_itemFormData)
        }

    }, [checklistItemId])

    const checkListPost = async (values) => {
        let _data = {
            body: values.desc,
            checklist: checklistId,
            assigned_to: values.assign,
            start_date: values.start_date == "" ? null : values.start_date,
            due_date: values.due_date == "" ? null : values.due_date,
            flag: parseInt(values.flag),
            depend_on: values?.depend_on
        }
        if (checklistItemId === -1) {
            props.dispatch(CreateChecklistItem(_data)).then(res => {
                let checklists = [...props.checklists]
                let checklistIndex = checklists.findIndex(el => el.id === checklistId)
                checklists[checklistIndex].items.push(res)
                props.dispatch({
                    type: CHECKLIST,
                    payload: checklists
                });
                checklistItemForm.resetFields();
                toggle()
            })
        } else {
            props.dispatch(UpdateChecklistItem(checklistItemId, _data)).then(res => {
                checklistItemForm.resetFields();
                toggle();
            })
        }

    }

    const onDateChange = (dates, dates_str) => {
        setStartDate(dates_str[0])
        setDueDate(dates_str[1])
    }

    return (
        <Modal
            title={`${checklistItemId === -1 ? "Create" : "Update"} checklist item`}
            visible={checkListForm}
            onCancel={toggle}
            onOk={() => {
                checklistItemForm
                    .validateFields()
                    .then(values => {
                        values["start_date"] = startDate;
                        values["due_date"] = dueDate;
                        checkListPost(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={checklistItemForm}
                labelCol={{ span: 24 }}
                layout={"vertical"}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
            >
                {props?.items &&
                    < Form.Item name="depend_on" label="Depend On" rules={[{ required: false }]}>
                        <Select
                            // disabled={checklistItemId != -1}
                            placeholder="Depend On"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                        >
                            <option value="">---Select---</option>
                            {props.items.filter(element => element.depend_on == null && element.id != checklistItemId)?.map((item) => {
                                return (
                                    <Option key={"chk_item_" + item.id} value={item.id}>{item.body}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                }

                <Form.Item label="" name="desc" rules={[{ required: true, message: 'Title is required!' }]}>
                    <TextArea rows="4" placeholder="Enter Checklist Item Name" />
                </Form.Item>

                {
                    (props?.board?.board_type != 'template' || props?.board?.board_type == undefined) && <Form.Item label="Start & Due Date" name="dates" rules={[{ required: true, message: 'Start and Due Date is required!' }]}>
                        <RangePicker style={{ width: '100%' }} onChange={onDateChange} />
                    </Form.Item>
                }
                {
                    (props?.board?.board_type != 'template' || props?.board?.board_type == undefined) && <Form.Item name="assign" label="Assign To" rules={[{ required: true, message: 'This field is required!' }]}>
                        <Select
                            placeholder="Select Member"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                        >
                            <option value="">---Select---</option>
                            {props.item?.assigned_to?.map((member) => {
                                return (
                                    <Option key={"member_" + member.id} value={member.id}>{member.first_name} {member.last_name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                }
                <Form.Item name="flag" label="Flag To" rules={[{ required: true, message: 'This field is required!' }]}>
                    <Select
                        placeholder="Select Flag"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return option.children !== "" && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                    >
                        <option value="">---Select---</option>
                        {props.flags?.map((flag) => {
                            return (
                                <Option key={"flag_" + flag.id} value={flag.id}>{flag.title}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal >
    );
}

function mapStateToProps(state) {
    const { board, checklists, members, flags, item } = state.boards;
    const { user } = state.auth
    return {
        board,
        checklists,
        members,
        user,
        flags,
        item
    }
}

export default connect(mapStateToProps)(CreateCheckListItem);