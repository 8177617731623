import { Col, Row } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { AllMembers } from '../../redux/actions/reports';

import ReportFilter from '../../components/filters';
import OverallHealth from '../../components/reports/overall_health';
import PieChartReport from '../../components/reports/pie_chart';
import WhereIsDelay from '../../components/reports/where_is_delay';
import WhoOwns from '../../components/reports/who_owns';




const ReportsPage = (props) => {
	const [filters, setFilters] = useState(props.filters_applied);

	useEffect(() => {
		props.dispatch(AllMembers('participients'));
		props.dispatch(AllMembers('responsible_person'));
		props.dispatch(AllMembers('board_owners'));
		props.dispatch(AllMembers('board_co_owners'));
	}, []);

	const handleOnFilterChange = (_filters) => {
		setFilters(_filters);
	}

	return (
		<div>
			<div className="filter-container">
				<ReportFilter onFilterChange={handleOnFilterChange} showPriorities={true} showFlags={true} />
			</div>
			<Row gutter={8}>
				<Col span={12}>
					<OverallHealth filters={filters} />
				</Col>
				<Col span={12}>
					<WhoOwns filters={filters} />
				</Col>

			</Row>
			<br />
			<Row gutter={8}>
				<Col span={12}>
					<WhereIsDelay filters={filters} />
				</Col>
				<Col span={12}>
					<PieChartReport filters={filters} />
				</Col>
			</Row>
		</div>

	);
};
function mapStateToProps(state) {
	const { filters_applied } = state.reports;
	return {
		filters_applied
	}
}

export default connect(mapStateToProps)(ReportsPage);
