import React from "react";
import { Container } from "reactstrap";


const PermissionDenied = (props) => {

    return (
        <Container fluid={true}>
            <h3 style={{ textAlign: 'center', marginTop: '20%' }}>Please wait...</h3>
        </Container>
    );
};


export default PermissionDenied;


