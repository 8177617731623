import { SPC, RESET_SPC } from "../types";
import { PURGE } from 'redux-persist';
  
const initialState = {
    spc_list: []
}
  
function spcList(state = initialState, action) {
    const { type, payload } = action;

switch (type) {
    case PURGE:
        return initialState
    case RESET_SPC:
        return initialState
    case SPC:
        return {
            ...state,
            spc_list: payload
        }
    default:
        return state;
}
}

export default spcList;
