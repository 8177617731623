import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_DETAIL,
} from "../types";
import { PURGE } from 'redux-persist';

const initialState = {
  token: null,
  refreshToken: null,
  user: {}
}
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PURGE:
      return initialState   
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        refreshToken: payload.refreshToken,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        token: null,
        refreshToken: null,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        refreshToken: null,
      };
    case USER_DETAIL:
      return {
        ...state,
        user: payload.user
      }
    default:
      return state;
  }
}