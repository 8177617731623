import { Col, Row } from 'antd';
import React, { useState } from "react";
import { connect } from "react-redux";



import CompletetionAdherence from '../../components/cyclic-job/reports/completetion_adherence';
import MissingItemSpentByCategory from '../../components/cyclic-job/reports/missing_items_spent_by_category';


import IMTEReport from "../../components/cyclic-job/reports/imte_report";
import MissingItemDaysByCategory from "../../components/cyclic-job/reports/missing_items_days_by_category";
import MissingItemSpentByOwner from '../../components/cyclic-job/reports/missing_items_spent_by_owner';
import OverdueTaskByWeek from "../../components/cyclic-job/reports/overdue_task_by_week";
import PersonalPerformace from '../../components/cyclic-job/reports/personal_performace';
import JobReportFilter from '../../components/filters/jobs_report_filter';

const JobsReportsPage = (props) => {
    const [filters, setFilters] = useState(props.filters_applied);



    const handleOnFilterChange = (_filters) => {
        setFilters(_filters);
    }

    return (
        <div>
            <div className="filter-container">
                <JobReportFilter onFilterChange={handleOnFilterChange} showPriorities={true} showFlags={true} />
            </div>
            <Row gutter={8}>
                <Col span={12}>
                    <CompletetionAdherence filters={filters} />
                </Col>
                <Col span={12}>
                    <OverdueTaskByWeek filters={filters} />

                </Col>

            </Row>
            <br />
            <Row gutter={8}>
                <Col span={12}>
                    <MissingItemSpentByOwner filters={filters} />
                </Col>
                <Col span={12}>
                    <MissingItemSpentByCategory filters={filters} />

                </Col>
            </Row>
            <br />
            <Row gutter={8}>
                <Col span={12}>
                    <MissingItemDaysByCategory filters={filters} />
                </Col>
                <Col span={12}>
                    <IMTEReport filters={filters} />
                </Col>
            </Row>

            <br />
            <Row gutter={8}>
                <Col span={24}>
                    <PersonalPerformace filters={filters} />
                </Col>

            </Row>

        </div>

    );
};
function mapStateToProps(state) {
    const { filters_applied } = state.reports;
    return {
        filters_applied
    }
}

export default connect(mapStateToProps)(JobsReportsPage);
