import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import { AllCoOwners, AllPriority, GetFlags } from "../../redux/actions/boards";
import { AllMembers } from '../../redux/actions/reports';


const AfterLogin = (props) => {
    const history = useHistory();

    useEffect(() => {
        props.dispatch(AllMembers('participients'));
        props.dispatch(AllMembers('responsible_person'));
        props.dispatch(AllMembers('board_owners'));
        props.dispatch(AllMembers('board_co_owners'));
        props.dispatch(AllPriority());
        props.dispatch(GetFlags());
        props.dispatch(AllCoOwners());

    }, []);

    useEffect(() => {
        setTimeout(() => {

            if (props?.user != null && props?.user != undefined) {
                if (props.user?.permissions?.can_access_spc) {
                    history.push('/spc');
                }
                else if (props.user?.permissions?.can_access_jobs) {
                    history.push('/jobs');
                }
                else if (props.user?.permissions?.can_access_forms) {
                    history.push('/forms');
                }
                else if (props.user?.permissions?.can_access_boards) {
                    history.push('/boards');
                }
                else {
                    history.push('/permission-denied');
                }
            }
        }, 2000);
    }, [props.user]);


    return (
        <Container fluid={true}>
            <h3 style={{ textAlign: 'center', marginTop: '20%' }}>Please wait...</h3>
        </Container>
    );
};

function mapStateToProps(state) {
    const { token, user } = state.auth;
    return {
        token, user
    };
}

export default connect(mapStateToProps)(withRouter(AfterLogin));


