
import ActionButton from "./ActionButton";

import AddElement from "./actions/AddElement";
import ViewPages from "./actions/ViewPages";
import ViewVars from "./actions/ViewVars";
import Settings from "./actions/Settings";
import Externals from "./actions/Externals";

import { useState, useEffect } from 'react';
import { ButtonGroup, Button } from "reactstrap";
import { PlusCircleTwoTone, ContainerTwoTone, ApiTwoTone, SnippetsTwoTone, SettingTwoTone } from '@ant-design/icons';

export default function ActionPanel({
    comps,
    setComps,
    page,
    setPage,
    pages,
    setPages,
    vars,
    setVars,
    externals,
    setExternals,
    parent,
    setParent,
    name,
    setName,
    dbId,
    setDbId,
    setSelectedEl,
    pageId,
    onFormSaveCallback,
    layout,
    setLayout
}) {

    const [view, setView] = useState('');
    const [showSaveModal, setShowSaveModal] = useState('');
    // '' => default, 'add' => add component, 'states' => state machine
    // 'externals' => externals, 'settings' => settings

    let view_render = <></>;
    if (view === 'add') {
        // debugger;
        view_render = <AddElement setSelectedEl={setSelectedEl} comps={comps} setComps={setComps} setView={setView} page={page} pageId={pageId} />;
    } else if (view === 'page') {
        view_render = <ViewPages comps={comps} setComps={setComps} setView={setView}
            page={page} setPage={setPage} pages={pages} setPages={setPages} />;
    } else if (view === 'vars') {
        view_render = <ViewVars comps={comps} setComps={setComps} setView={setView}
            vars={vars} setVars={setVars} />;
    } else if (view === 'settings') {
        view_render = <Settings comps={comps} setComps={setComps} setView={setView}
            pages={pages} setPages={setPages} vars={vars}
            externals={externals} parent={parent} name={name} setName={setName}
            dbId={dbId} setDbId={setDbId} onFormSaveCallback={onFormSaveCallback}
            layout={layout} setLayout={setLayout} />;
    } else if (view === 'externals') {
        view_render = <Externals comps={comps} setComps={setComps} setView={setView}
            pages={pages} setPages={setPages} vars={vars} setVars={setVars}
            externals={externals} setExternals={setExternals} />;
    }

    return (
        <div
            style={{ textAlign: 'center' }}
        >
            <ButtonGroup size="md">
                <Button outline onClick={() => {
                    if (view === '') {
                        setView('add');
                    }
                }}>
                    <PlusCircleTwoTone />
                </Button>

                <Button outline onClick={() => {
                    if (view === '') {
                        setView('vars');
                    }
                }}>
                    <ContainerTwoTone />
                </Button>
                <Button outline onClick={() => {
                    if (view === '') {
                        setView('externals');
                    }
                }}>
                    <ApiTwoTone />
                </Button>
                <Button outline onClick={() => {
                    if (view === '') {
                        setView('page');
                    }
                }}>
                    <SnippetsTwoTone />
                </Button>
                <Button outline onClick={() => {
                    if (view === '') {
                        setView('settings');
                    }
                }}>
                    <SettingTwoTone />
                </Button>
            </ButtonGroup>
            {view_render}
        </div>
    );

}