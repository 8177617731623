import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import {
    UserAddOutlined, DeleteOutlined, ShareAltOutlined
} from '@ant-design/icons';

import { Select, Spin, Modal, Button, List, Tag, Avatar, message } from 'antd';
import debounce from 'lodash/debounce';
import BoardService from '../../redux/services/boards';

const { Option } = Select;


function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions('member', value).then((resp) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                let newOptions = [];
                resp.results.map(user => {
                    newOptions.push(
                        {
                            label: `${user.first_name} ${user.last_name} (${user.id})`,
                            value: user.id,
                        }
                    )
                })


                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            showSearch={true}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
} // Usage of DebounceSelect



const UserAutoComplete = (props) => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [boardMembers, setBoardMembers] = useState([]);
    const [value, setValue] = React.useState(null);
    const [memberType, setMemberType] = React.useState("observer");

    useEffect(() => {
        BoardService.getBoardMembers({ 'board_id': props.board_id }).then(data => {
            setBoardMembers(data);
        });
    }, []);


    const handleOk = () => {
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const onShare = () => {
        const data = {
            'user_id': value.value,
            'member_type': memberType,
            'board_id': props.board_id
        };
        BoardService.manageBoardMember(data).then(res => {
            message.success({ content: "User added", duration: 2 });
            setBoardMembers(res);
            setValue(null);
            setMemberType('observer');
        });
    }

    const handleDelete = (member) => {
        if (window.confirm('Are you sure want to remove?')) {
            const data = {
                'user_id': member.user.id,
                'member_type': member.member_type,
                'board_id': member.board
            };

            BoardService.manageBoardMember(data).then(res => {
                message.success({ content: "User removed", duration: 2 });
                setBoardMembers(res);
            });
        }
        else {
            alert('Cancelled');
        }
    }

    return (
        <div>
            <Button shape="default" type="primary" onClick={() => setShowModal(!showModal)}><UserAddOutlined /> Share</Button>
            <Modal visible={showModal} title={<div><ShareAltOutlined /> Share</div>} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <DebounceSelect
                        value={value}
                        placeholder="Enter username or name"
                        fetchOptions={BoardService.allMembers}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        style={{
                            width: '60%',
                        }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <div>
                        <Select defaultValue={"participant"} style={{ width: 100 }} onChange={(v) => { setMemberType(v) }} allowClear={true}>

                            {
                                props.board.owner == props.user.id ? <Option value="co-owner">Co-Owner</Option> : null
                            }
                            <Option value="participant">Participant</Option>
                            <Option value="observer">Observer</Option>
                        </Select>
                        &nbsp;&nbsp;
                        <Button type="primary" onClick={onShare}>Share</Button>
                    </div>
                </div>
                <div>
                    <List itemLayout="horizontal" dataSource={boardMembers} renderItem={item => {
                        return (
                            <List.Item>
                                <List.Item.Meta avatar={<Avatar children={`${item.user.first_name[0].toUpperCase()}${item.user.last_name[0].toUpperCase()}`} />} title={`${item.user.first_name} ${item.user.last_name}`} description={item.user.username} />
                                <Tag color="orange" style={{ textTransform: 'capitalize' }}>{item.member_type}</Tag>
                                {
                                    item.user.id != props.user.id ?
                                        <Button icon={<DeleteOutlined />} onClick={() => {
                                            handleDelete(item)
                                        }} danger></Button> : null
                                }
                            </List.Item>
                        )
                    }}>
                    </List>
                </div>
            </Modal >
        </div >
    )
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    }
}

export default connect(mapStateToProps)(UserAutoComplete);
