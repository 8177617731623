import { BuildFilled, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Menu, Modal, Typography, message } from 'antd';
import axios from "axios";
import { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { ArchiveListItem, DeleteList, UpdateList } from '../../../redux/actions/boards';
import authHeader from "../../../redux/services/auth-header";
import { managementUrl } from "../../../redux/services/base";
import CommonModal from '../../common-modal';
import Task from './task';
const { Text } = Typography;
const { confirm } = Modal;
const key = "updatable"

const TaskList = (props) => {
    const [editBoardList, setEditBoardList] = useState(false);
    const [isAttachmentExportVisible, setIsAttachmentExportVisible] = useState(false);

    const handleOnItemEdit = (item, list) => {
        props.onItemEdit(item, list);
    }

    const handleOnAddItem = () => {
        props.onItemEdit(null, props.list);
    }

    const updateBoardList = (value) => {
        let update_data = {
            title: value.title
        }
        props.dispatch(UpdateList(props.list.id, update_data)).then(res => setEditBoardList(false))
    }

    const showDeleteConfirm = (listId) => {
        confirm({
            title: `Are you sure delete this list?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                message.loading({ content: 'Loading...', key });
                props.dispatch(DeleteList(listId))
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const archiveCards = (listId) => {
        props.dispatch(ArchiveListItem(listId));
    }

    const exportAttachments = async (item) => {
        return await axios.get(`${managementUrl}/board/export/files?item_id=${item.id}`, { headers: authHeader(), responseType: 'blob' }).then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${item.title}_${item.id}_${new Date().toLocaleDateString()}.zip`); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }

    const openAttachmentDialog = (list) => {
        setIsAttachmentExportVisible(true);
    }

    const handleAttachmentOk = () => {
        setIsAttachmentExportVisible(!isAttachmentExportVisible);
    }
    const [selectedItems, setSelectedItems] = useState([]);

    const onItemSelectedChange = (id) => {
        if (selectedItems.indexOf(id) < 0) {
            let newArr = [...selectedItems];
            newArr.push(id);
            setSelectedItems(newArr);
        }
        else {
            let newArr = [...selectedItems];
            newArr.splice(newArr.indexOf(id), 1);
            setSelectedItems(newArr);
        }
    }
    const onAttachmentDialogClose = () => {
        setSelectedItems([]);
        setDownloading(false);
    }
    const [downloading, setDownloading] = useState(false);
    const handleDownload = async () => {
        setDownloading(true);
        var items = [];

        props?.list?.items?.map(item => {
            if (selectedItems.indexOf(item.id) > -1) {
                items.push(item);
            }
        });

        for (var i = 0; i < items.length; i++) {
            exportAttachments(items[i]);
        }
    }

    return (
        <div className="task-list">
            <Modal title="Export All Attachments" destroyOnClose={true} afterClose={onAttachmentDialogClose}
                visible={isAttachmentExportVisible} onOk={handleAttachmentOk} onCancel={handleAttachmentOk} footer={[
                    <Button key="download" onClick={handleDownload} disabled={downloading}>
                        Download
                    </Button>
                ]}>
                {
                    props?.list?.items?.map(i => {
                        return (
                            <div>
                                <Checkbox key={`item_${i.id}`} onChange={() => { onItemSelectedChange(i.id) }}>{i.title}  {i.id}</Checkbox>
                            </div>
                        )
                    })
                }
            </Modal>

            <div className="list-title">
                {editBoardList ?
                    <CommonModal
                        open={editBoardList}
                        toggle={() => setEditBoardList(!editBoardList)}
                        what_for="board list"
                        title={props.list.title}
                        onOk={updateBoardList}
                    />
                    :
                    null
                }
                <Text>{props.list.title}</Text>
                {
                    (props.board.owner === props.user.id || props.user.groups.indexOf('Branch Admin') > -1) ? (
                        <Dropdown
                            overlay={
                                <Menu className="item_menu">
                                    <Menu.Item key={`edit${props.list.id}`} icon={<EditOutlined />} onClick={() => { setEditBoardList(!editBoardList); }}>
                                        Edit
                                    </Menu.Item>

                                    {
                                        props?.list?.items?.length > 0 && props?.user?.groups?.indexOf('Branch Admin') > -1 ?
                                            <Menu.Item key={`archive${props.list.id}`} icon={<BuildFilled />} onClick={() => { archiveCards(props.list.id) }}>
                                                Archive all cards in this list
                                            </Menu.Item> : null
                                    }


                                    {
                                        (props.list.closed == false) ? (
                                            <Menu.Item
                                                icon={<DeleteOutlined
                                                    style={{ color: 'red' }} />}
                                                key={`delete${props.list.id}`}
                                                onClick={props.list.closed ? null : () => showDeleteConfirm(props.list.id)}
                                            >
                                                Delete
                                            </Menu.Item>
                                        ) : null
                                    }
                                    <Menu.Item key={`export${props.list.id}`} icon={<BuildFilled />} onClick={() => { openAttachmentDialog(props.list) }}>
                                        Export Attachements
                                    </Menu.Item>

                                </Menu>
                            }>
                            <MoreOutlined style={{ fontSize: '20px', fontWeight: 'bold', color: '#important', float: 'right' }} />
                        </Dropdown>
                    ) : null
                }
            </div>
            <Droppable droppableId={`col_${props.list.id}`}>
                {(provided) => (
                    <div className="list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {
                            props.list?.items?.map((item, index) => <Task onEdit={handleOnItemEdit} list_id={props.list.id} list={props.list} board_id={props.board_id} task={item} index={index} key={`task_${item.id}`} />)
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            {
                (props.list.closed == false && (props.list.created_by == props.user.id || props.board.is_co_owner || props.board.owner == props.user.id || props.user.groups.indexOf('Branch Admin') > -1)) ? (
                    <div className="list-footer">
                        <Button type="primary" block onClick={handleOnAddItem} style={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5, fontWeight: 'bold' }}>Add New Item</Button>
                    </div>
                ) : null
            }
        </div>
    )
}

function mapStateToProps(state) {
    const { board } = state.boards;
    const { user } = state.auth;
    return {
        board,
        user
    }
}
export default connect(mapStateToProps)(TaskList);
