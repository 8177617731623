
import { useEffect, useState } from "react";
import AddCommand from "../AddCommand";

const commandColor = '#55efc4';

// Has params: props.id, props.comps, props.setComps, props.indices, props.setIsScriptRendered, props.childrenArray
export default function IfConditionalCommand(props) {

    const [evaluatedErrors, setEvaluatedErrors] = useState(false);
    const [color, setColor] = useState(commandColor);

    const [isAddingCommand, setIsAddingCommand] = useState(false);

    if (!evaluatedErrors) {
        if (props.childrenArray[0].length === 1) {
            setColor(commandColor);
            setEvaluatedErrors(true);
        } else {
            setColor('#FC427B');
            setEvaluatedErrors(true);
        }
    }

    let comment = -1;
    let thisObj = props.comps[props.id].script[props.indices[0]];
    for (let k = 0; k < props.indices.length - 1; ++k) {
        thisObj = thisObj.children[props.indices[k + 1]];
    }

    try {
        comment = thisObj.comment;
    } catch (e) {

    }

    useEffect(() => {
        if (props.childrenArray[0].length === 1) {
            setColor(commandColor);
            setEvaluatedErrors(true);
        } else {
            setColor('#FC427B');
            setEvaluatedErrors(true);
        }
    });

    return <>
        <div style={{
            marginBottom: '2vmax',
            display: 'inline-block',
            marginRight: '4vmax',
            verticalAlign: 'top'
        }}>
            <div style={{
                padding: '1vmax',
                backgroundColor: color,
                color: '#000',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '500'
            }}>
                CONDITIONAL
            </div>
            <a className='panel-button' style={{
                padding: '1vmax',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '300',
                border: '0pt',
                borderLeft: `1pt solid #faf3dd`,
                marginLeft: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                let new_comps = [...props.comps];
                let thisObj = new_comps[props.id].script[props.indices[0]];
                for (let k = 0; k < props.indices.length - 2; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                } thisObj.children.splice(props.indices[props.indices.length - 1], 1);
                props.setComps(new_comps);
                props.setIsScriptRendered(false);
            }}>
                Delete
            </a>
            <i className='fa-solid fa-comment-dots pointy comp' style={{
                padding: '1vmax',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontWeight: '300',
                border: '0pt',
                borderLeft: `1pt solid #faf3dd`,
                marginLeft: '1vmax',
                backgroundColor: comment === -1 ? "#000" : "#faf3dd",
                color: comment === -1 ? "#faf3dd" : "#000"
            }} onClick={(e) => {
                e.preventDefault();
                let new_comps = [...props.comps];
                let thisObj = new_comps[props.id].script[props.indices[0]];
                for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
                if (thisObj.comment !== -1) {
                    thisObj.comment = -1;
                } else {
                    thisObj.comment = "Comments";
                }
                props.setComps(new_comps);
                props.setIsScriptRendered(false);
            }}></i>

            {/* Copy Command Icon */}
            <i className='fa-solid fa-copy pointy comp panel-button' style={{
                padding: '1vmax',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontWeight: '300',
                border: '0pt',
                borderLeft: `1pt solid #faf3dd`,
                marginLeft: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                let thisObj = props.comps[props.id].script[props.indices[0]];
                for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
                navigator.clipboard.writeText(JSON.stringify(thisObj));
            }}></i>

            <div style={{
                padding: '2vmax 0',
                borderLeft: `3pt solid ${color}`,
                borderBottom: `1pt dashed ${color}`,
                paddingLeft: '2vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '500',
                paddingRight: '2vmax'
            }} className='code-block'>
                <div style={{
                    display: 'inline-block',
                    fontSize: '1.15vmax',
                    marginBottom: props.childrenArray[0].length === 1 ? '1vmax' : '0vmax'
                }}>IF</div>
                {props.childrenArray[0].length === 1 ? <div style={{
                    marginLeft: '0vmax'
                }}>{props.childrenArray[0]}</div> : <i className='fa-solid fa-plus panel-icon' style={{
                    fontSize: '1vmax',
                    padding: '0.5vmax',
                    borderRadius: '100px',
                    marginLeft: '3.5vmax',
                    marginBottom: '2vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setIsAddingCommand('0');
                }} />}
                <br />
                <div style={{
                    display: 'inline-block',
                    fontSize: '1.15vmax'
                }}>THEN</div>
                <i className='fa-solid fa-plus panel-icon' style={{
                    fontSize: '1vmax',
                    padding: '0.5vmax',
                    borderRadius: '100px',
                    marginLeft: '1.5vmax',
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setIsAddingCommand('1');
                }} />
                <i className='fa-solid fa-paste pointy comp panel-button' style={{
                    padding: '1vmax',
                    display: 'inline-block',
                    fontSize: '1.15vmax',
                    fontWeight: '300',
                    border: '0pt',
                    borderLeft: `1pt solid #faf3dd`,
                    marginLeft: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_comps = [...props.comps];
                    let thisObj = props.comps[props.id].script[props.indices[0]];
                    for (let k = 0; k < props.indices.length - 1; ++k) {
                        thisObj = thisObj.children[props.indices[k + 1]];
                    }
                    navigator.clipboard.readText().then(text => {
                        try {
                            let parsedText = JSON.parse(text);
                            thisObj.children[1].children.push(parsedText);
                            props.setComps(new_comps);
                            props.setIsScriptRendered(false);
                        } catch (err) {

                        }
                    });
                }}></i>
                <div id='ifblocks' style={{
                    marginTop: '1vmax',
                    whiteSpace: 'nowrap'
                }}>
                    {props.childrenArray[1]}
                </div>
                {/* <br/> */}
                <div style={{
                    display: 'inline-block',
                    fontSize: '1.15vmax'
                }}>ELSE</div>
                <i className='fa-solid fa-plus panel-icon' style={{
                    fontSize: '1vmax',
                    padding: '0.5vmax',
                    borderRadius: '100px',
                    marginLeft: '2.1vmax',
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setIsAddingCommand('2');
                }} />
                <i className='fa-solid fa-paste pointy comp panel-button' style={{
                    padding: '1vmax',
                    display: 'inline-block',
                    fontSize: '1.15vmax',
                    fontWeight: '300',
                    border: '0pt',
                    borderLeft: `1pt solid #faf3dd`,
                    marginLeft: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_comps = [...props.comps];
                    let thisObj = props.comps[props.id].script[props.indices[0]];
                    for (let k = 0; k < props.indices.length - 1; ++k) {
                        thisObj = thisObj.children[props.indices[k + 1]];
                    }
                    navigator.clipboard.readText().then(text => {
                        try {
                            let parsedText = JSON.parse(text);
                            thisObj.children[2].children.push(parsedText);
                            props.setComps(new_comps);
                            props.setIsScriptRendered(false);
                        } catch (err) {

                        }
                    });
                }}></i>
                <div id='ifblocks' style={{
                    marginTop: '1vmax',
                    whiteSpace: 'nowrap'
                }}>
                    {props.childrenArray[2]}
                </div>
                {
                    comment === -1 ? <></> : <>
                        <input className='panel-input-reverse' style={{
                            width: '100%',
                            height: '2vmax',
                            marginTop: '1vmax'
                        }} value={comment} onChange={(e) => {
                            let new_comps = [...props.comps];
                            let thisObj = new_comps[props.id].script[props.indices[0]];
                            for (let k = 0; k < props.indices.length - 1; ++k) {
                                thisObj = thisObj.children[props.indices[k + 1]];
                            }
                            thisObj.comment = e.target.value;
                            props.setComps(new_comps);
                            props.setIsScriptRendered(false);
                        }}></input>
                    </>
                }
            </div>
        </div>
        {/* <br/> */}
        {
            isAddingCommand === '0' ? <AddCommand id={props.id} comps={props.comps}
                setComps={props.setComps} indices={[...props.indices].concat([0])}
                setIsScriptRendered={props.setIsScriptRendered}
                setIsAddingCommand={setIsAddingCommand}
                availableCommands={[
                    "expression/boolean",
                    "operator/logical/and",
                    "operator/logical/or",
                    "operator/logical/not",
                    "operator/ordinal/g",
                    "operator/ordinal/geq",
                    "operator/ordinal/l",
                    "operator/ordinal/leq",
                    "operator/ordinal/eq",
                    "operator/type/isNum",
                    "operator/type/isStr",
                    "operator/type/isEmail",
                    "operator/type/isDate",
                    "operator/type/isBlank",
                    "operator/type/isTime",
                    "quant/between",
                    "operator/type/isUndecorated",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "operator/array/includes" : "",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "operator/array/isEmpty" : "",
                    props.comps[props.id].type === "intermediate/InputTable" ? "self/table/value" : "",
                    "operator/qual/digits"
                ]} /> : <></>
        }
        {
            isAddingCommand === '1' ? <AddCommand id={props.id} comps={props.comps}
                setComps={props.setComps} indices={[...props.indices].concat([1])}
                setIsScriptRendered={props.setIsScriptRendered}
                setIsAddingCommand={setIsAddingCommand}
                availableCommands={[
                    "conditional/if",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "variable/set" : "variable/table/set",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "signal/error" : "signal/table/error",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "flags/set" : "",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "flags/setToChild" : "",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "" : "signal/table/disableRow",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "signal/disable" : "signal/table/disable",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "signal/enable" : "signal/table/enable",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "signal/warning" : "signal/table/warning",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "signal/disableSelf" : "",
                ]} /> : <></>
        }
        {
            isAddingCommand === '2' ? <AddCommand id={props.id} comps={props.comps}
                setComps={props.setComps} indices={[...props.indices].concat([2])}
                setIsScriptRendered={props.setIsScriptRendered}
                setIsAddingCommand={setIsAddingCommand}
                availableCommands={[
                    "conditional/if",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "variable/set" : "variable/table/set",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "signal/error" : "signal/table/error",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "flags/set" : "",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "flags/setToChild" : "",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "" : "signal/table/disableRow",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "signal/disable" : "signal/table/disable",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "signal/enable" : "signal/table/enable",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "signal/warning" : "signal/table/warning",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "signal/disableSelf" : "",
                ]} /> : <></>
        }
    </>;

}