export let layouts = ['A3', 'A4'];
export let layouts_obj = {
	"A3": 420/297,
  	"A4": 297/210
};

export function layoutToHeight(layout) {
    switch (layout) { 
        // case 'A3':
        //     return 420/297
        case 'A4':
            return 297/210
        default:
            return 0
    }
}

export function getHeight(layout, value) {
    if (layouts.includes(layout)) {
        return layoutToHeight(layout);
    } else {
        return value;
    }
}

export function whichLayout(height) {
	let epsilon = 0.00001;
  	// if (Math.abs((420/297) - height) < epsilon) {
	// 	return "A3";
    // } else
    
    if (Math.abs((297/210) - height) < epsilon) {
		return "A4";
    } else {
		return "None";
    }
}

export function whichLayoutForPages(pgs) {
	let layouts = [];
  	for (let k = 0; k < pgs.length; ++k) {
		layouts.push(whichLayout(pgs[k].height));
    }
  
  	// if (JSON.stringify(layouts) == JSON.stringify(Array(layouts.length).fill("A3"))) {
	// 	return "A3";
    // } else
    
    if (JSON.stringify(layouts) == JSON.stringify(Array(layouts.length).fill("A4"))) {
		return "A4";
    } else {
		return "None";
    }
}