import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import boards from "./boards";
import spcList from "./spc";
import reports from "./reports";
import cyclicJob from "./cyclic-job";
import forms from "./forms";

export default combineReducers({
  auth,
  message,
  boards,
  spcList,
  reports,
  cyclicJob,
  forms
});