import { PURGE } from 'redux-persist';
import {
    BOARD_CO_OWNERS,
    BOARD_OWNERS,
    FILTERS_APPLIED,
    OVERALL_REPORTS,
    PARTICIPIENTS,
    REPORTS,
    RESET_REPORTS,
    RESPONSIBLE_PERSON
} from "../types";

const initialState = {
    checklist_items: [],
    overall_health: [],
    filters_applied: null,
    jobs_filters_applied: null,
    participients: [],
    responsible_persons: [],
    board_owners: [],
    board_co_owners: []
}

function checklistItems(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PURGE:
            return initialState
        case RESET_REPORTS:
            return initialState
        case REPORTS:
            return {
                ...state,
                checklist_items: payload
            }
        case OVERALL_REPORTS:
            return {
                ...state,
                overall_health: payload
            }
        case FILTERS_APPLIED:
            return {
                ...state,
                filters_applied: payload
            }
        case PARTICIPIENTS:
            return {
                ...state,
                participients: payload
            }
        case RESPONSIBLE_PERSON:
            return {
                ...state,
                responsible_persons: payload
            }
        case BOARD_OWNERS:
            return {
                ...state,
                board_owners: payload
            }
        case BOARD_CO_OWNERS:
            return {
                ...state,
                board_co_owners: payload
            }
        default:
            return state;
    }
}

export default checklistItems;
