
import { Rnd } from 'react-rnd';

import process_vars from '../../intermediate/process_vars';

const boxShadowRadius = 15;

export default function Image({ id, comps, setter, vw, vars, setVars, editable, externals, setExternals }) {

    let boxShadow = "0px 0px 0px #ff4f42";

    if (comps[id].error) {
        boxShadow = `0px 0px ${boxShadowRadius}px #ff4f42`;
    }
    else if (comps[id].hasOwnProperty('warning')) {
        if (comps[id].warning) {
            boxShadow = `0px 0px ${boxShadowRadius}px #d35400`;
        }
    }
    else if (comps[id].disable) {
        boxShadow = `0px 0px ${boxShadowRadius}px #4e6df5`;
    }

    return <>
        <div>
            <Rnd className='comp' style={{
                backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
                textAlign: 'center',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: 'hidden',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                    `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                position: 'absolute',
                boxShadow: editable ? boxShadow : 'none',
                transition: 'box-shadow 0.5s'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} enableResizing={false} disableDragging={true}>
                <img src={comps[id].data} draggable="false" style={{
                    width: '100%',
                    height: '100%'
                }} onClick={(e) => {
                    e.preventDefault();
                }}></img>
            </Rnd>
        </div>
    </>;


}