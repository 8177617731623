import { Avatar, Col, Modal, Row, Typography, message } from 'antd';
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";


import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import { GetChecklists, GetComments, GetListItem, UpdateItem } from "../../redux/actions/boards";
import CommonModal from "../common-modal";

import { CalendarOutlined, CommentOutlined, FileAddOutlined, UnorderedListOutlined, UsergroupDeleteOutlined } from "@ant-design/icons";
import { LIST, LIST_ITEM } from "../../redux/types";
import CheckList from '../checklist/checklist';
import Comments from '../comment/comments';
import Attachment from "./side-card/Attachment";
import Urls from "./side-card/Urls";

import Members from "./side-card/Members";

const { Paragraph } = Typography
const key = "updatable"
const queryString = require('query-string');

const TaskDetailPage = (props) => {
    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(true);
    const { board_id, item_id, list_id } = useParams()
    const [descShow, setDescShow] = useState(false)
    const [titleOpen, setTitleOpen] = useState(false)


    useEffect(() => {
        props.dispatch(GetListItem(item_id)).then(() => {
            message.success({ content: "Loading completed", key, duration: 0.5 });
            // setBoardDesc(props?.item?.description);
        });
        props.dispatch(GetComments(item_id))
        props.dispatch(GetChecklists(item_id))
    }, [item_id])

    const afterClose = () => {
        props.dispatch({
            type: LIST_ITEM,
            payload: null
        });
        props.dispatch({
            type: LIST,
            payload: null
        });
        const parsed = queryString.parse(window.location.search);
        if (parsed.next == null || parsed.next == undefined) {
            history.push(`/board/${board_id}`)
        }
        else {
            history.push(parsed.next)
        }
    }
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleItemUpdate = (data, field_name) => {
        let item_payload = {
            'id': props.item.id,
            'title': props.item.title,
        }
        if (field_name === 'description') {
            item_payload["description"] = data.title;
        }
        else if (field_name === 'list title') {
            item_payload["title"] = data.title
        }
        props.dispatch(UpdateItem(parseInt(list_id), props.item.id, item_payload)).then(res => {
            if (field_name === 'description') {
                setDescShow(false);
                // setBoardDesc(data.title)
            } else {
                setTitleOpen(false);
                // setTitleVal(data.title)
            }

        });
    }

    const handleTitleEdit = () => {
        if (props.item.created_by.id != props.user.id) {
            return;
        }
        setTitleOpen(true)
    }

    const handleDescriptionEdit = () => {
        if (props.item.created_by.id != props.user.id) {
            return;
        }
        setDescShow(true)
    }

    return (
        <div>
            {isModalVisible === false ? <h1>Loading...</h1> : null}
            <Modal
                width="60%"
                visible={isModalVisible}
                footer={null}
                onCancel={handleCancel}
                afterClose={afterClose}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={1}>
                                <FileAddOutlined />
                            </Col>
                            <Col span={23}>
                                <Typography onClick={handleTitleEdit}>
                                    <b style={{ fontSize: 15, border: '1px solid #1990ff', borderRadius: 3, paddingLeft: 3, paddingRight: 3 }}>#{props.item?.id}</b> <b>{props.item?.title} in List <i>"{props?.item?.list?.title}"</i> and Board <i>"{props?.item?.list?.board?.title}"</i></b>
                                </Typography>

                                <CommonModal
                                    open={titleOpen}
                                    toggle={() => setTitleOpen(!titleOpen)}
                                    what_for="list title"
                                    title={props.item?.title}
                                    onOk={handleItemUpdate}
                                />
                            </Col>
                            <Col span={24}>
                                <br />
                            </Col>
                            <Col span={1}>
                                <UnorderedListOutlined />
                            </Col>
                            <Col span={23} className="card-heading">
                                <Label for="exampleEmail">
                                    Description
                                </Label>
                            </Col>
                            <Col span={23} offset={1}>
                                <Paragraph onClick={handleDescriptionEdit}>
                                    <div>
                                        {
                                            (props.item?.description == "" || props.item?.description == null) ? "Click here to add a more detailed description" : props.item?.description
                                        }
                                    </div>
                                </Paragraph>
                                <CommonModal
                                    open={descShow}
                                    toggle={() => setDescShow(!descShow)}
                                    what_for="description"
                                    title={props.item?.description}
                                    onOk={handleItemUpdate}
                                />
                            </Col>


                            <Col span={1}>
                                <UnorderedListOutlined />
                            </Col>
                            <Col span={23} className="card-heading">
                                <Label for="exampleEmail">
                                    Owner Details
                                </Label>
                            </Col>
                            <Col span={23} offset={1}>
                                <div>
                                    <div>{props.item?.created_by?.first_name} {props.item?.created_by?.last_name}</div>
                                    <div>{props.item?.created_by?.email}</div>
                                    <div>at {new Date(props.item?.created_at).toLocaleDateString()} {new Date(props.item?.created_at).toLocaleTimeString()}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={1}>
                                <CalendarOutlined />
                            </Col>
                            <Col span={23} className="card-heading">
                                <Label for="dates">
                                    Dates
                                </Label>
                            </Col>
                            <Col span={23} offset={1}>
                                {
                                    props?.item?.start_date && new Date(props?.item?.start_date).toLocaleDateString()
                                } &nbsp;&nbsp;-&nbsp;&nbsp;
                                {
                                    props?.item?.due_date && new Date(props?.item?.due_date).toLocaleDateString()
                                }
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb-3">
                            <Col span={24}>
                                <Urls title={props.item?.title} item={props.item} />
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb-3">
                            <Col span={24}>
                                <Attachment title={props.item?.title} item={props.item} />
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb-4">
                            <Col span={24}>
                                <div className="item_detail_title">
                                    <UsergroupDeleteOutlined />
                                    <span style={{ fontWeight: 'bold', fontSize: 16, marginRight: 10, marginLeft: 20 }}>Members</span> {"   "}
                                    <Members />
                                </div>
                            </Col>
                            <Col span={23} offset={1}>
                                <Avatar.Group
                                    maxCount={5}
                                    size="large"
                                    maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                    {
                                        props?.item?.assigned_to?.map((member, index) => {
                                            return (
                                                <Avatar style={{ backgroundColor: '#f56a00' }} key={`selected_member_avatar_${index}`}>
                                                    {member.first_name[0]}{member.last_name[0]}
                                                </Avatar>
                                            )
                                        })
                                    }
                                </Avatar.Group>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb-4">
                            <Col span={24}>
                                <CheckList board={props?.board} list={props?.item?.list} item={props?.item} />
                            </Col>
                        </Row>
                        {
                            (props?.board?.board_type != 'template' || props?.board?.board_type == undefined) && <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb1-4">
                                <Col span={1}>
                                    <CommentOutlined />
                                </Col>
                                <Col span={23} className="card-heading">
                                    <Label for="exampleEmail">
                                        COMMENTS
                                    </Label>
                                </Col>
                                <Col span={23} offset={1}>
                                    <Comments item={props?.item} />
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

function mapStateToProps(state) {
    const { board, comments, checklists, members, item } = state.boards;
    const { user } = state.auth;
    return {
        board,
        comments,
        checklists,
        members,
        user,
        item
    }
}

export default connect(mapStateToProps)(TaskDetailPage);

// export default TaskDetailPage;