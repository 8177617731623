
import { Rnd } from 'react-rnd';

import process_vars from '../../intermediate/process_vars';

const boxShadowRadius = 15;

export default function Drop({ id, comps, setter, vw, vars, setVars, editable, externals, setExternals }) {

    let boxShadow = "0px 0px 0px #ff4f42";

    if (comps[id].error) {
        boxShadow = `0px 0px ${boxShadowRadius}px #ff4f42`;
    }
    else if (comps[id].hasOwnProperty('warning')) {
        if (comps[id].warning) {
            boxShadow = `0px 0px ${boxShadowRadius}px #d35400`;
        }
    }
    else if (comps[id].disable) {
        boxShadow = `0px 0px ${boxShadowRadius}px #4e6df5`;
    }

    // const [ inner_content, setInnerContent ] = useState([]);

    // useEffect(() => {
    //     let innerContent = [];
    //     for (let k = 0; k < comps[id].props.content.length; ++k) {
    //         innerContent.push(
    //             <div value={k} style={{
    //                 // width: '100%',
    //                 // padding: '0.5vmax',
    //                 // backgroundColor: comps[id].props.selected === k.toString() ? '#ffbaec' : process_vars(comps[id].props.backgroundColor, vars),
    //                 // color: comps[id].props.selected === k.toString() ? '#c512a7' :
    //                 //         process_vars(comps[id].props.color, vars)
    //             }}
    //             // className='color-pointy-light'
    //             // onClick={(e) => {
    //             //     if (editable) {/
    //             //         if (!comps[id].disable) {
    //             //             let new_comps = [...comps];
    //             //             new_comps[id].props.selected = k.toString();
    //             //             setter(new_comps);
    //             //             execute_script(1, id, comps, setter, vars, setVars);
    //             //         }
    //             //     }
    //             // }}
    //             >{ process_vars(comps[id].props.content[k], vars) }</div>
    //         );
    //     } setInnerContent(innerContent);
    // }, []);

    // return (
    //     <div
    // onClick={(e) => {
    //     e.preventDefault();
    //     let innerContent = [];
    //     for (let k = 0; k < comps[id].props.content.length; ++k) {
    //         innerContent.push(
    //             <option value={k} style={{
    //                 width: '100%',
    //                 padding: '0.5vmax',
    //                 backgroundColor: comps[id].props.selected === k.toString() ? '#ffbaec' : process_vars(comps[id].props.backgroundColor, vars),
    //                 color: comps[id].props.selected === k.toString() ? '#c512a7' :
    //                         process_vars(comps[id].props.color, vars)
    //             }}>{ process_vars(comps[id].props.content[k], vars) }</option>
    //         );
    //     } setInnerContent(innerContent);
    // }}
    //     >
    //         <Rnd className='comp' style={{
    //             color: process_vars(comps[id].props.color, vars),
    //             backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
    //             overflow: 'hidden',
    //             fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
    //             fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
    //             fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
    //             textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
    //             zIndex: '0',
    //             border: process_vars(comps[id].props.borderEnabled, vars) ?
    //                         `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
    //             position: 'absolute',
    //             boxShadow: boxShadow,
    //             transition: 'box-shadow 0.5s'
    //         }} position={{
    //             x: process_vars(comps[id].position[0], vars) * vw,
    //             y: process_vars(comps[id].position[1], vars) * vw
    //         }} size={{
    //             width: process_vars(comps[id].size[0], vars) * vw,
    //             height: process_vars(comps[id].size[1], vars) * vw
    //         }} enableResizing={false} disableDragging={true}>
    //             <select style={{
    //                 width: '100%',
    //                 height: '100%',
    //                 overflow: 'scroll',
    //                 overflowX: 'hidden',
    //                 textAlign: process_vars(comps[id].props.alignment, vars)
    //             }} value={parseInt(comps[id].selected)} onChange={(e) => {
    //                 if (editable) {
    //                     if (!comps[id].disable) {
    //                         let new_comps = [...comps];
    //                         new_comps[id].props.selected = e.target.value.toString();
    //                         setter(new_comps);
    //                         execute_script(1, id, comps, setter, vars, setVars);
    //                     }
    //                 }
    //             }}>
    //                 { inner_content }
    //             </select>
    //         </Rnd>
    //     </div>
    // );

    // let background_color = '';
    // if (hov === id) {
    //     background_color = '#FAF3DD';
    // } else {
    //     background_color = process_vars(comps[id].props.backgroundColor, vars);
    // }

    let innerContent = [];
    for (let k = 0; k < comps[id].props.content.length; ++k) {
        innerContent.push(
            <option value={k.toString()} style={{
                width: '100%',
                padding: '0.5vmax'
            }} className='color-pointy-light'>{process_vars(comps[id].props.content[k], vars)}</option>
        );
    }

    return (
        <div>
            <Rnd className='comp' style={{
                color: process_vars(comps[id].props.color, vars),
                backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
                overflow: 'hidden',
                fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
                fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
                fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
                textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                    `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                position: 'absolute',
                boxShadow: editable ? boxShadow : 'none',
                transition: 'box-shadow 0.5s'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} enableResizing={false} disableDragging={true}>
                <select style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'scroll',
                    overflowX: 'hidden',
                    textAlign: process_vars(comps[id].props.alignment, vars)
                }} value={comps[id].props.selected} onChange={(e) => {
                    if (!comps[id].disable && editable) {
                        let new_comps = [...comps];
                        new_comps[id].props.selected = e.target.value.toString();
                        setter(new_comps);
                    }
                }}>
                    {innerContent}
                </select>
            </Rnd>
        </div>
    );

}