import React from "react";
import { Container } from "reactstrap";
import JobSetupList from '../../components/cyclic-job';
import Jobs from '../../components/cyclic-job/jobs';
import { Tabs } from 'antd';

import { Card, CardBody, CardText } from 'reactstrap';

const JobSetupPage = () => {


	return (
		<Container fluid={true}>
			<Card>
				<CardBody>
					<CardText tag={"div"}>
						<Tabs defaultActiveKey="1" destroyInactiveTabPane={true}>

							<Tabs.TabPane tab="Job List" key="item-1">
								<JobSetupList />
							</Tabs.TabPane>

							<Tabs.TabPane tab="My Jobs" key="item-2">
								<Jobs jobType={"my_jobs"} />
							</Tabs.TabPane>
							<Tabs.TabPane tab="Open Jobs" key="item-3">
								<Jobs jobType={"open_jobs"} />
							</Tabs.TabPane>
						</Tabs>
					</CardText>
				</CardBody>
			</Card>
		</Container>
	);
};

export default JobSetupPage;
