import { FileAddOutlined, ImportOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { GetForms } from "../../redux/actions/forms";
import FormService from "../../redux/services/forms";
import FormIcon from "./form-icon";


const Forms = (props) => {
    const history = useHistory();
    const [current, setCurrent] = useState(-1);
    const [del, setDel] = useState(false);
    const [isCopying, setIsCopying] = useState(false);
    const [newFormName, setNewFormName] = useState('');

    const [importModal, setImportModal] = useState(false);
    const [restoreMode, setRestoreMode] = useState(false);
    const [restoreFormId, setRestoreFormId] = useState(0);

    const [copyModal, setCopyModal] = useState(false);
    const [addChildFormModal, setAddChildFormModal] = useState(false);
    const [currentForm, setCurrentForm] = useState(null);


    const [idOn, setIdOn] = useState(false);

    const [search, setSearch] = useState('');
    const [place, setPlace] = useState(['Root']);
    const [placeId, setPlaceId] = useState(['']);

    const [selectedFormId, setSelectedFormId] = useState('');
    const [formType, setFormType] = useState('');

    const [loading, setLoading] = useState(false);

    const loadForms = () => {
        setLoading(true);
        props.dispatch(GetForms({ "parent": selectedFormId, "formType": props?.formType, "name__icontains": props?.formQuery }, null)).then(res => {
            setLoading(false);
            // This will refresh records
            props?.setRefreshRecords(new Date());
        });
    };

    useEffect(() => {
        loadForms();
    }, [selectedFormId, props?.formType, props?.formQuery])

    const onAddNewForm = (e) => {
        history.push('/forms/create');
    }


    const onDoubleClick = (form) => {
        setSelectedFormId(form.form_id);

        let new_place = [...place];
        new_place.push(form.name);
        setPlace(new_place);
        let new_placeId = [...placeId];
        new_placeId.push(form.form_id);
        setPlaceId(new_placeId);
    }


    const deleteForm = (form, is_delete) => {
        setLoading(true);
        FormService.deleteForm(form, is_delete).then(res => {
            setLoading(false);
            loadForms();
        }).catch(err => {
            setLoading(false);
        });
    }

    const archiveForm = (form, is_archived) => {
        setLoading(true);
        FormService.archiveForm(form.form_id, is_archived).then(res => {
            setLoading(false);
            loadForms();
        }).catch(err => {
            setLoading(false);
        });
    }

    const exportForm = async (form) => {
        setLoading(true);
        FormService.exportForm(form.form_id).then(res => {
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                JSON.stringify(res.data)
            )}`;
            const link = document.createElement("a");
            link.href = jsonString;
            // link.download = "data.json";
            link.download = form.name + "_" + form.form_id + ".json";

            link.click();
            setLoading(false);
        }).catch(res => {
            setLoading(false);
        });
    }


    const onMenuClick = (e, eventType, form) => {
        if (eventType == 'addRecord') {
            history.push({
                pathname: `/forms/${form.form_id}/records/create`,
                state: form
            })
        }
        else if (eventType == 'searchRecord') {
            history.push({
                pathname: `/forms/${form.form_id}/records`,
                state: form
            });
        }
        else if (eventType == 'Delete') {
            if (window.confirm(`Are you sure want to delete this form ${form.name}?`)) {
                deleteForm(form, 1);
            }
        }
        else if (eventType == 'Edit') {
            history.push({
                pathname: `/forms/${form.form_id}/create`,
                state: form
            });
        }
        else if (eventType == 'Copy') {
            setCopyModal(true);
            setCurrentForm(form);
        }

        else if (eventType == 'addChildForm') {
            setAddChildFormModal(true);
            setCurrentForm(form);
        }
        else if (eventType == 'archive') {
            if (window.confirm(`Are you sure want to archive this form ${form.name}?`)) {
                archiveForm(form, 1);
            }
        }
        else if (eventType == 'unarchive') {
            if (window.confirm(`Are you sure want to unarchive this form ${form.name}?`)) {
                archiveForm(form, 0);
            }
        }

        else if (eventType === 'restore') {
            if (window.confirm(`Are you sure want to restore this form ${form.name}?`)) {
                deleteForm(form, 0);
            }
        }
        else if (eventType == 'permanent_delete') {
            if (window.confirm(`Are you sure want to permanent delete this form ${form.name}? You won't be able to restore.`)) {
                deleteForm(form, 2);
            }
        }
        else if (eventType == 'Export') {
            exportForm(form)
        }
        else if (eventType === 'Restore') {
            setRestoreMode(true);
            setImportModal(true);
            setRestoreFormId(form.form_id);
        }
        else if (eventType == 'setEmailAlert') {
            history.push({
                pathname: `/forms/${form.form_id}/create/alert`,
                state: form
            });
        }


        // This will refresh records
        // props?.setRefreshRecords(new Date());
    }

    const [templateFile, setTemplateFile] = useState(null);

    const importForm = (e) => {
        setLoading(true);
        setImportModal(false);
        const data = new FormData();
        data.append('file', templateFile);

        FormService.importForm(data, restoreFormId, restoreMode).then(res => {
            setLoading(false);
            if (restoreFormId != null && restoreFormId > 0) {
                alert('Form Restored Successfully.');
            }
            else {
                alert('Form Imported Successfully.')
            }
            loadForms();
            setRestoreFormId(0);
            setRestoreMode(false);
        }).catch(res => {
            setLoading(false);
            alert(res.response.data.message);
            setRestoreFormId(0);
            setRestoreMode(false);
        });
    }

    const copyForm = (e) => {
        const form = currentForm;
        if (newFormName != "") {
            const payload = {
                name: newFormName,
                content: form.content,
                pages: form.pages,
                vars: form.vars,
                externals: form.externals,
                parent: form.parent
            }

            // payload = {
            //     name: newFormName,
            //     formId: form.id,
            //     is_copy: true
            // }

            setLoading(true);
            FormService.copyForm(payload).then(res => {
                setCopyModal(false);
                setCurrentForm(null);
                setNewFormName('');
                setLoading(false);
                setTimeout(() => {
                    loadForms();
                }, 500);
            }).catch(err => {
                setLoading(false);
            });
        }
    }

    const onAddChildForm = (e) => {
        const form = currentForm;
        if (newFormName != "") {
            const payload = {
                name: newFormName,
                content: form.content,
                pages: form.pages,
                vars: form.vars,
                externals: form.externals,
                parent: form.form_id
            }
            setLoading(true);
            FormService.createForm(payload).then(res => {
                setAddChildFormModal(false);
                setCurrentForm(null);
                setSelectedFormId(form.form_id);
                setNewFormName('');

                let new_place = [...place];
                new_place.push(form.name);
                setPlace(new_place);
                let new_placeId = [...placeId];
                new_placeId.push(form.form_id);
                setPlaceId(new_placeId);
                setLoading(false);
            });
        }
    }

    const onEmailNotification = () => {

    }

    return (
        <div>
            {
                loading ? (
                    <div className="blockUI">
                        <div className="loading-icon-container">
                            <LoadingOutlined />
                            <br />
                            <br />
                            <strong>Loading...</strong>
                        </div>
                    </div>
                ) : null
            }



            <Modal title={"Import Form"} visible={importModal} destroyOnClose={true} onCancel={(e) => {
                setImportModal(false);
                setRestoreFormId(0);
                setRestoreMode(false);
            }} onOk={importForm}>
                <label>Choose Template</label>
                <br />
                <input type="file" onChange={(e) => {
                    setTemplateFile(e.target.files[0]);
                }} />
            </Modal>

            <h3 style={{ padding: '5px 15px', marginTop: '20px', fontSize: '15px' }}>
                <span>
                    {
                        place?.length > 1 ?
                            (<Button type="default" shape="circle" icon={<LeftOutlined />} onClick={() => {
                                if (place.length > 1) {
                                    let new_place = [...place];
                                    new_place.splice(new_place.length - 1, 1);
                                    setPlace(new_place);
                                    let new_placeId = [...placeId];
                                    new_placeId.splice(new_placeId.length - 1, 1);
                                    setPlaceId(new_placeId);
                                    setCurrent(-1);

                                    setSelectedFormId(new_placeId[new_placeId.length - 1]);
                                }
                            }} style={{ marginRight: 3, fontWeight: 'bold' }} />) : null
                    }
                </span>

                {
                    place?.map((item, index) => {
                        return (
                            <span key={index}>
                                <span className="folder-navigation">{item}</span>
                                {
                                    index != place.length - 1 ? <RightOutlined /> : null
                                }
                            </span>
                        )
                    })
                }
            </h3>
            <div className="folder-container">
                {
                    props?.forms?.results?.length > 0 ? (
                        props?.forms?.results?.map((form, index) => {
                            return (
                                <FormIcon key={index} form={form} onDoubleClick={onDoubleClick} onMenuClick={onMenuClick} showArchive={props?.user?.groups.includes('Branch Admin')} />
                            )
                        })
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '20%' }}>No Record Available.</div>
                    )
                }
            </div>

            {
                props?.user?.groups.includes('Branch Admin') && props?.formType == 'all' ? (
                    <>
                        <Button style={{ position: 'absolute', right: 10, bottom: 70 }} onClick={(e) => {
                            setImportModal(true);
                        }} type="primary" shape="round" icon={<ImportOutlined />} size={"medium"}>Import</Button>
                        <Button style={{ position: 'absolute', right: 10, bottom: 20 }} onClick={onAddNewForm} type="primary" shape="round" icon={<FileAddOutlined />} size={"medium"}>Create</Button>
                    </>
                ) : null
            }

            <Modal title="Copy Form" destroyOnClose={true} visible={copyModal} onOk={copyForm} onCancel={() => {
                setCopyModal(false);
                setCurrentForm(null);
            }} >
                <Input placeholder="Enter form name here..." onChange={(e) => {
                    setNewFormName(e.target.value);
                }} />
            </Modal>

            <Modal title="Create Child Form" visible={addChildFormModal} onOk={onAddChildForm} destroyOnClose={true} onCancel={() => {
                setAddChildFormModal(false);
            }}>
                <Input placeholder="Enter form name here..." onChange={(e) => {
                    setNewFormName(e.target.value);
                }} />
            </Modal>
        </div>
    );
}


function mapStateToProps(state) {
    const { forms } = state.forms;
    const { user } = state.auth;
    return {
        forms,
        user
    }
}
export default connect(mapStateToProps)(Forms);
