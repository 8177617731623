import {
  DeleteOutlined,
  LinkOutlined,
  PlusCircleFilled
} from '@ant-design/icons';
import { Button, Col, List, Row } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DeleteUrl, addUrl } from '../../../redux/actions/boards';
import { LIST_ITEM } from "../../../redux/types";
import CommonModal from '../../common-modal';



const Urls = (props) => {
  const [fileName, setFileName] = useState(null);
  const [updateAttachModal, setUpdateAttachModal] = useState(false);
  const [addLinkModal, setAddLinkModal] = useState(false);
  const [attachment, setAttachment] = useState(null)
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [selectedUrlId, setSelectedUrlId] = useState(null);

  const handleChange = info => {
    if (info.file?.status === 'done') {
      let item = JSON.parse(JSON.stringify(props.item));
      let attachments = [...props.item.attachments];
      attachments.splice(0, 0, info.file?.response);
      item.attachments = attachments;
      item.timestamp = new Date().getTime();

      props.dispatch({
        type: LIST_ITEM,
        payload: item
      });
    }
  };

  const onDeleteAttachment = (e, id) => {
    e.stopPropagation();

    props.dispatch(DeleteUrl(id))
  }

  const onAddUrl = (url) => {

    var payload = {
      url: url.title,
      item: props.item.id
    };

    console.log('payload >', payload);
    props.dispatch(addUrl(payload, props.item.id)).then(res => {
      setAddLinkModal(false);
    })
  }



  return (
    <div>
      <div className="item_detail_title attachement_upload">
        <LinkOutlined />
        <span style={{ fontWeight: 'bold', fontSize: 16, marginRight: 10, marginLeft: 20 }}>Links</span> {"   "}
        {
          (props?.item?.created_by?.id == props.user.id || props?.board?.is_co_owner || props?.board?.owner == props.user.id || props.user.groups.indexOf('Branch Admin') > -1) ?
            <div style={{ marginTop: 6, cursor: 'pointer' }} onClick={() => {
              setAddLinkModal(true);
            }}>
              <PlusCircleFilled />
            </div> : null
        }

      </div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb-3">
        <Col span={23} offset={1}>
          <List
            bordered
            dataSource={props?.item?.urls}
            renderItem={url => (
              <List.Item actions={
                url?.created_by?.id == props.user.id || props.user.groups.indexOf('Branch Admin') > -1 || props.item?.created_by == props.user.id || props?.board?.is_co_owner || props?.board?.owner == props.user.id ? [

                  <Button shape="circle" danger type='text' icon={<DeleteOutlined />} onClick={(e) => onDeleteAttachment(e, url.id)} />
                ] : []}>
                <List.Item.Meta key={`url_${url.id}`} title={<a target={"_blank"} rel="noreferrer" href={url.url}>{url.url}</a>} />
              </List.Item>
            )}
          >

          </List>
        </Col>
      </Row>
      {
        updateAttachModal ?
          <CommonModal
            open={updateAttachModal}
            toggle={() => setUpdateAttachModal(!updateAttachModal)}
            what_for="Enter Link"
            title={selectedUrl}
            onOk={(val) => {
              onAddUrl(val)
            }}
          />
          :
          null
      }

      {
        addLinkModal ?
          <CommonModal
            open={addLinkModal}
            toggle={() => setAddLinkModal(!addLinkModal)}
            what_for="Enter Link"
            title={selectedUrl}
            onOk={(val) => {
              onAddUrl(val)
            }} />
          :
          null
      }
    </div>
  )
};

function mapStateToProps(state) {
  const { board } = state.boards;
  const { user } = state.auth;
  return {
    board,
    user
  }
}
export default connect(mapStateToProps)(Urls);
