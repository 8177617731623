import { FilterOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { JOBS_FILTERS_APPLIED } from '../../redux/types';


const { RangePicker } = DatePicker;

const JobReportFilter = (props) => {
    const [visible, setVisible] = useState(false);



    useEffect(() => {
        form.setFieldsValue({ dates: props.filters_applied?.dates });
    }, []);

    const [form] = Form.useForm();

    const clearFilter = () => {
        form.resetFields();
        props.dispatch({
            type: JOBS_FILTERS_APPLIED,
            payload: null
        });
        props.onFilterChange(null);
        setVisible(!visible);
    }
    const onApplyFilter = (values) => {

        setVisible(false);
        let filter_data = {};

        if (values.dates != null && values.dates != undefined) {
            filter_data["from_date"] = values.dates[0].format("YYYY-MM-DD");
            filter_data["to_date"] = values.dates[1].format("YYYY-MM-DD");
        }
        filter_data["dates"] = values.dates;

        props.dispatch({
            type: JOBS_FILTERS_APPLIED,
            payload: filter_data
        });

        setTimeout(() => {
            delete filter_data["dates"];
            props.onFilterChange(filter_data);
        }, 500);
    }



    const content = (
        <Form form={form} layout="vertical" autoComplete="off" onFinish={onApplyFilter}>
            <Form.Item label="Dates" name="dates">
                <RangePicker style={{ width: '100%' }} />
            </Form.Item>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button type="default" onClick={clearFilter}>
                    Clear Filter
                </Button>
                <Button type="primary" htmlType="submit">
                    Apply Filter
                </Button>
            </div>
        </Form>
    );


    const showDrawer = () => {
        setVisible(!visible);
    };

    return (
        <div>
            <Button type="primary" icon={<FilterOutlined />} onClick={showDrawer}>
                Filter
            </Button>

            <Drawer
                title="Filters"
                bodyStyle={{ paddingTop: 5 }}
                placement={'right'}
                closable={false}
                onClose={showDrawer}
                visible={visible}>
                {
                    content
                }
            </Drawer>

        </div>
    );
};

function mapStateToProps(state) {
    const { jobs_filters_applied } = state.reports;
    return {
        jobs_filters_applied
    }
}

export default connect(mapStateToProps)(JobReportFilter);
