import { Column } from '@ant-design/plots';
import { Card } from 'antd';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import ReportService from "../../redux/services/reports";




ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const WhereIsDelay = (props) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const payload = {
      'filters': props.filters
    };
    ReportService.whereIsDelay(payload).then(res => {
      let x = [];
      res.data.map(el => {
        if (x.indexOf(el.color) <= -1) {
          x.push(el.color);
        }
      });
      console.log('Colors > ', x);
      setColors(x);
      let lists = [];

      let index = 1;
      res.data.map(el => {
        el["code"] = index;
        lists.push(el);
        index++;
      })
      setData(lists);
    });
  }, [props.filters]);



  const config = {
    data,
    isStack: false,
    xField: 'item',
    yField: 'total',
    seriesField: 'priority',
    colorField: 'color',
    color: colors,
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-show-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  return (
    <Card title="Where is Delay">
      <Column {...config} onReady={(plot) => {
        plot.on('plot:click', (evt) => {
          const { x, y } = evt;
          const tooltipData = plot.chart.getTooltipItems({ x, y });
          let board = tooltipData[0].data.board;
          let list = tooltipData[0].data.list_id;
          let item_id = tooltipData[0].data.item_id;
          sessionStorage.setItem('nextPage', '/reports');
          history.push({
            pathname: `/task-detail/${board}/${list}/${item_id}`,
            search: "?next=/reports"
          });
        });
      }} />
    </Card>
  );
};

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user
  }
}

export default connect(mapStateToProps)(WhereIsDelay);